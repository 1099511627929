// eslint-disable-next-line import/no-anonymous-default-export
const worker = () => {
    /* eslint-disable-next-line no-restricted-globals */
    self.addEventListener("message", e => {
        if (!e) return;

        console.log("event in worker: ", e);
        try {
            const fetchData = (url, method, isJSON = true) => {
                return new Promise((resolve, reject) => {
                    function reqListener() {
                        if (this.status !== 200) {
                        return reject();
                        }
                        const response = isJSON
                        ? JSON.parse(this.responseText)
                        : this.responseText;
                        resolve(response);
                    }
                    const oReq = new XMLHttpRequest();
                    oReq.addEventListener('load', reqListener);
                    var params = { "id_scales": e.id_scales, "startWeek": 123 };
                    // xhr = new XMLHttpRequest();
                    console.log("method: "+method);
                    if (method == 'GET') {
                        oReq.open('GET', url);
                    } else {
                        oReq.open('POST', url, false);
                    }
                    oReq.setRequestHeader('Authorization', e.data.token); //'Basic ' + token);
                    oReq.setRequestHeader('Content-Type', 'application/json');
                    // oReq.onreadystatechange = function () {
                    //     if (xhr.readyState == 4 && xhr.status == 200) {
                    //         var result = JSON.parse(xhr.responseText);
                    //         postMessage(result.d);
                    //     }
                    // }; 
                    oReq.send(JSON.stringify(params));
                });
            };

            const baseUrl = 'http://localhost:10001/v3';
            // const baseUrl = 'https://api.modbee.io/v3/';

            const jsonUrl = baseUrl + e.data.url;
            console.log("jsonUrl: ", jsonUrl);
            const tasks = [fetchData(jsonUrl,'GET')]; //, fetchData(htmlUrl, false)];
            Promise.all(tasks)
                .then(data => {
                    console.log("data from fetch: ", data);
                    postMessage(data); //{ json: data[0], html: data[1] });
                })
                .catch(error => {
                    console.log("error 1 worker: ", error);
                    postMessage({ isError: true, error });
                });
        } catch (error) {
            console.log("error 2 worker: ", error);
            postMessage({ isError: true });
        }
        // const users = [];
        // const userDetails = {
        //     name: "Jane Doe",
        //     email: "jane.doe@gmail.com",
        //     id: 1
        // };
        // for (let i = 0; i < 100; i++) {
        //     userDetails.id = i++;
        //     userDetails.dateJoined = Date.now();
        //     users.push(userDetails);
        // }
        // postMessage("users");
    });
};


export default worker;