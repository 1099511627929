import React, { Suspense } from 'react';
import 'typeface-roboto';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
// import * as serviceWorker from './serviceWorker';
import { Provider } from 'react-redux'
import {BrowserRouter} from "react-router-dom";
import CssBaseline from "@material-ui/core/es/CssBaseline/CssBaseline";
import Firebase, { FirebaseContext } from "./components/firebase";
import store from "./store/store";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import registerServiceWorker from './registerServiceWorker';
import { updateServiceworker } from './store/actions/index';

// import history from './store/history';

const onUpdate = () => {
  console.log('onUpdate in index.js')
  store.dispatch(updateServiceworker());


  setTimeout(function () {    
    console.log('reloadPage - automatisch starten...')
    if ('caches' in window) {
      console.log('reloadPage - delete cache')
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        })
        window.location.reload();
      });
    } else {
      console.log('reloadPage cache not in windows...')
    }
  }, 10000);
}

const app = (
  <Suspense fallback={null}>    
    <Provider store={store}>
      <FirebaseContext.Provider value={new Firebase(store)}>
            <BrowserRouter>
            {/* <BrowserRouter history={ history }> */}
                <div>
                  <CssBaseline />
                  {/* <ScaleContextProvider> */}
                    <App />
                  {/* </ScaleContextProvider> */}
                </div>
            </BrowserRouter>
      </FirebaseContext.Provider>
    </Provider>
  </Suspense>
);

ReactDOM.render( app, document.getElementById( 'root' ) );
// initializeFirebase();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
// serviceWorkerRegistration.register(onUpdate);
registerServiceWorker(onUpdate);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
