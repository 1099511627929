import moment from 'moment';

export const updateObject = (oldObject, updatedProperties) => {
    return {
        ...oldObject,
        ...updatedProperties
    };
};


export function precise_round(num,decimals) {
  return Math.round(num*Math.pow(10, decimals)) / Math.pow(10, decimals);
}

export const mapScales = (scales) => {
  const timeFormat = 'DD.MM.YYYY HH:mm';

  let day_diff = 0;
  let day_start = 0;
  let last_weight = 0;
  let last_hum = 50;
  let diff = 0;
  let ertrag = 0;
  let event = 0;
  let hum = 0;
  let temp = 0;

  const fetchScales =  scales.map((dataPoint, index) => {
    day_diff = 0;
    day_start = 0;
    last_weight = 0;
    diff = 0;
    ertrag = 0;
    hum = 0;
    temp = 0;
              // eslint-disable-next-line
    if (dataPoint.weights != undefined) {
      const startOfDay = moment().startOf('day').valueOf();
      //TODO Tagesdifferenz ausrechnen!
      const firstDayEl = dataPoint.weights.filter(weight => {
          //Nur heutige Werte zurückliefern!
          return startOfDay < moment.utc(weight.change_time).valueOf();
        }).slice(0, 1);

      if (firstDayEl.length > 0) {
        // console.log("firstDayEl: ", firstDayEl[0].weight);
        day_diff = (dataPoint.last_weight - firstDayEl[0].weight).toFixed(2);
        // day_diff = (dataPoint.weight - firstDayEl[0].weight).toFixed(2);
        day_start =  firstDayEl[0].weight;
      }


      // hum = dataPoint.weights[dataPoint.weights.length-1].humidity;
      // temp = dataPoint.weights[dataPoint.weights.length-1].temperature;          
      return {
        ...dataPoint,
        humidity: hum,
        temperature: temp,
        day_start: day_start,
        key: dataPoint.id_scales,
        weights: dataPoint.weights.map((weight, index2)=> {
          event = weight.event;          
          // eslint-disable-next-line
          if (index2 == 0) {
            diff = 0;
          } else {
            diff = weight.weight - last_weight;
          }
          if (event > 0) {
            // eslint-disable-next-line
            if (event == 11) {
              //Zarge aufgesetzt?!?
              day_diff = day_diff - (weight.weight - last_weight);
              diff = 0;
              // eslint-disable-next-line
            } else if (event == 12) {
              //schwarm
              day_diff = day_diff - (weight.weight - last_weight);
              diff = 0;
              // eslint-disable-next-line
            } else if (event == 13) {
              //Zarge abgenommen
              day_diff = day_diff - (weight.weight - last_weight);
              diff = 0;
            }
          }

          ertrag += diff;
          last_weight = weight.weight;

          if (weight.humidity > 0) {
            last_hum = weight.humidity;
          }

          //TODO - min/max/avg pro Tag bei Temp und Gewicht
          // console.log("weight: ", weight);
          //TODO Zu/Abnahme je Tag!
          // change_time: "2021-03-08T14:04:21.861Z"
          // event: "0"
          // humidity: 70.4
          // id_scale: "1034"
          // id_weight: "233563"
          // pressure: 9000
          // scale: 17672206
          // temperature: 5.69
          // temperature2: 5.69
          // vc: 4.16
          // vc_p: 96.71
          // weight: 30.26

          return {
            ...weight,
            weight: precise_round(weight.weight, 2),
            diff: diff,
            hum: last_hum,
            last: last_weight,
            // day_diff: day_diff,
            ertrag: precise_round(ertrag, 2),
            time: weight.change_time,
            date: moment.utc(weight.change_time).format(timeFormat), //moment(dataPoint.time).valueOf()/1000,
            signal_time: moment.utc(weight.change_time).valueOf(), //= milliseconds
            time_string: moment.utc(weight.change_time).format(timeFormat)
          }
        }),          
        //day_diff wird im Map abgeändert falls events gefunden werden!!
        day_diff: day_diff
      };
    } else {
      return {
        ...dataPoint,
        humidity: hum,
        temperature: temp,
        key: dataPoint.id_scales,
        day_diff: day_diff,
        day_start: 0
      };
    }
  });

  return fetchScales;
}

export const getRSSIString = (rssi) => {
  switch (rssi) {
    case 0: return " kleiner -110 dBm";
    case 1: return "-110 dBm";
    case 2: return "-109 dBm";
    case 3: return "-108 dBm";
    case 4: return "-107 dBm";
    case 5: return "-106 dBm";
    case 6: return "-105 dBm";
    case 7: return "-104 dBm";
    case 8: return "-103 dBm";
    case 9: return "-102 dBm";
    case 10: return "-101 dBm";
    case 11: return "-100 dBm";
    case 12: return "-99 dBm";
    case 13: return "-98 dBm";
    case 14: return "-97 dBm";
    case 15: return "-96 dBm";
    case 16: return "-95 dBm";
    case 17: return "-94 dBm";
    case 18: return "-93 dBm";
    case 19: return "-92 dBm";
    case 20: return "-91 dBm";
    case 21: return "-90 dBm";
    case 22: return "-89 dBm";
    case 23: return "-88 dBm";
    case 24: return "-87 dBm";
    case 25: return "-86 dBm";
    case 26: return "-85 dBm";
    case 27: return "-84 dBm";
    case 28: return "-83 dBm";
    case 29: return "-82 dBm";
    case 30: return "-81 dBm";
    case 31: return "-80 dBm";
    case 32: return "-79 dBm";
    case 33: return "-78 dBm";
    case 34: return "-77 dBm";
    case 35: return "-76 dBm";
    case 36: return "-75 dBm";
    case 37: return "-74 dBm";
    case 38: return "-73 dBm";
    case 39: return "-72 dBm";
    case 40: return "-71 dBm";
    case 41: return "-70 dBm";
    case 42: return "-69 dBm";
    case 43: return "-68 dBm";
    case 44: return "-67 dBm";
    case 45: return "-66 dBm";
    case 46: return "-65 dBm";
    case 47: return "-64 dBm";
    case 48: return "-63 dBm";
    case 49: return "-62 dBm";
    case 50: return "-61 dBm";
    case 51: return "-60 dBm";
    case 52: return "-59 dBm";
    case 53: return "-58 dBm";
    case 54: return "-57 dBm";
    case 55: return "-56 dBm";
    case 56: return "-55 dBm";
    case 57: return "-54 dBm";
    case 58: return "-53 dBm";
    case 59: return "-52 dBm";
    case 60: return "-51 dBm";
    case 61: return "-50 dBm";
    case 62: return "-49 dBm";
    case 63: return " größer -48 dBm";

    default: return "NOT KNOWN";
  }
}