
import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";   
import {Grid, Typography, Divider} from "@material-ui/core";
import { makeStyles } from '@material-ui/core'; 
import Moment from "react-moment"; 
import 'moment-timezone';
import 'moment/locale/de';
// import { classNames } from 'classnames';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';


const styles = theme => ({
    customClass: {
        padding: theme.spacing(4),
        // paddingBottom: theme.spacing(4),
        // cursor: pointer
    },
    scale_border_right: {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      borderRight: '1px solid #DDD',
    },
    scale_border_none: {
      padding: theme.spacing(0),
      margin: theme.spacing(0),
      borderRight: '0px solid #DDD',
    },
    scale_grid: {
      padding: theme.spacing(1),
      margin: theme.spacing(0)
    },
    scale_grid_inner_item: {
      padding: theme.spacing(0),
      margin: theme.spacing(0)
    },
    grid_text: {
      fontSize: '0.7rem',
      fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif'
    },
    bold_text: {
      fontWeight: '700'
    }
});

// font-size: 0.75rem;
// font-family: "Roboto", "Helvetica", "Arial", sans-serif;
// font-weight: 400;
// line-height: 1.66;
// letter-spacing: 0.03333em;

const DayComp = (props) => {
  const {classes, caption, value, bold} = props;
  // const classes = useStyles();
  const dispatch = useDispatch();

  let vorzeichen = "";
  let color_class = "color_black54";

  if (value > 0) {
    vorzeichen = "+";
  }
  if (value > 0.1) {
    color_class = "petrol";
  } else if (Math.abs(value) > 0.1) {
    color_class = "rot";
  }

  let myClasses = "";
  if (caption == "Woche") {
    myClasses = classNames(classes.scale_grid);
  } else {    
    myClasses = classNames(classes.scale_border_right, classes.scale_grid);
  }

  const boldClass = bold ? classes.bold_text : "";
  return (  
    <Grid 
      item 
      className={myClasses} > 

      <Grid 
        container 
        direction="column" 
        alignItems="center"
        justify="center"
        className={classNames(boldClass, classes.scale_grid_inner_item, classes.grid_text )} > 
  
        <Grid item xs border={1}>
          {caption}
          {/* <Typography variant="caption" gutterBottom>
          </Typography> */}
        </Grid>
        <Grid item xs className={classNames(color_class, classes.grid_text)}>
          {vorzeichen+value}
          {/* <Typography variant="caption" gutterBottom className={ color_class}>
          </Typography> */}
        </Grid>
      </Grid>
    </Grid>
                    // onLegendDoubleClick?: (event: Readonly<Plotly.LegendClickEvent>) => boolean;
  );
}

export default withStyles(styles)(DayComp);