import React from 'react';
import 'moment-timezone';
import 'moment/locale/de';
import { Grid, IconButton, LinearProgress, Tooltip, Typography } from '@material-ui/core';
import {withRouter} from "react-router-dom";
import styles from './ChargerStatus.module.css';
import classNames from 'classnames';
import const_vars from "../Static/const_vars";
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import ReplayIcon from '@material-ui/icons/Replay';
import StopIcon from '@material-ui/icons/Stop';


const ScaleStatus = (props) => {
  // const { t } = useTranslation();
  const { scale, sendfunction } = props;

  const getStationStatusText = (station) => {
    let statusText = "";
    switch(station.gw_status) {
      case 32: 
      statusText = "";
      break;
      
      default:
        break;
    }
    return statusText;
  }
  // console.log("charger: "+charger);
  //TODO if charger.disabled then return ();

    // : 511,
    // CHARGER_STATUS_CHARGING_DENIED: 512,
    // : 513,
    let paused = false;

    let pauseAction = 4;
    let continueAction = 5;
    let cancelAction = 6;

    if (scale.status > 1009) {
      //Charger-Download
      pauseAction = 7;
      continueAction = 8;
      cancelAction = 9;
    } else {
      //Gateway actions
    }
    

console.log("pause: "+pauseAction+" con: "+continueAction+" stop: "+cancelAction)

    let statusText = "";
    switch(scale.status) {
      case const_vars.GW_STATUS_SHOULD_DOWNLOAD_FIRMWARE:
        statusText = "Firmeware-Download geplant";
      break;
      case const_vars.GW_STATUS_HEADER_DOWNLOAD_FIRMWARE: 
        statusText = "Gateway hat FW-Info abgeholt";
      break;
      case const_vars.GW_STATUS_IS_DOWNLOADING_FIRMWARE: 
        statusText = "Firmeware-Download läuft";
      break;
      case const_vars.GW_STATUS_FINISHED_DOWNLOADING_FIRMWARE:
        statusText = "Firmeware-Download abegschlossen";
      break;
      case const_vars.GW_STATUS_INSTALLING_DOWNLOADED_FIRMWARE: 
      statusText = "Firmeware Gateway wird installiert";
      break;
      case const_vars.GW_STATUS_SHOULD_PAUSE_DOWNLOAD_FIRMWARE: 
      statusText = "Firmeware-Download soll pausiert werden";
      break;
      case const_vars.GW_STATUS_DOWNLOAD_FIRMWARE_PAUSED: 
      statusText = "Firmeware-Download pausiert";
      break;
      case const_vars.GW_STATUS_SHOULD_CONTINUE_DOWNLOAD_FIRMWARE:
        statusText = "Firmeware-Download soll fortgesetzt werden";
      break;
      case const_vars.GW_STATUS_DOWNLOAD_FIRMWARE_ERROR:
        statusText = "Firmeware-Download - FEHLER";
      break;
      case const_vars.GW_STATUS_SHOULD_CANCEL_DOWNLOAD_FIRMWARE:
        statusText = "Firmeware-Download soll abgebrochen werden";
      break;
   
      
      
      default:
        break;
    }

  return (       
    <Tooltip title={''} enterDelay={10} leaveDelay={10}>   
      <Grid container alignItems="center">    
        <Grid item xs={12} sm={4}>   
          <Typography variant="caption" color="primary">
            {statusText}
          </Typography>         
        </Grid>
        <Grid item xs={12} sm={4} >   
                  
          { (!scale.updating_gw  && !scale.updating ) &&
            <LinearProgress />
          }   
          { (scale.updating_gw )  &&
            <LinearProgress variant="determinate" value={(100/scale.update_gw_total_parts*scale.update_gw_part)} />
          }       
          { (scale.updating) &&
            <LinearProgress variant="determinate" value={(100/scale.update_charger_total_parts*scale.update_charger_part)} />
          }
        </Grid>

        <Grid item xs={12} sm={3}>   
          { (scale.status > 999) &&
          <IconButton 
            // disabled={station.gw_status === 3}
            size="small" 
            onClick={() => sendfunction(scale, const_vars.UPDATE_FIRMWARE, continueAction)}>
              <PlayArrowIcon />
          </IconButton>  
          }
          { (scale.status > 999) &&
          <IconButton 
            // disabled={station.gw_status !== static_vars.GW_STATUS_IS_DOWNLOADING_FIRMWARE3}
            size="small" 
            onClick={() => sendfunction(scale, const_vars.UPDATE_FIRMWARE, pauseAction)}>
              <PauseIcon />
          </IconButton>  
          }
          { (scale.status > 999) &&
          <IconButton 
            // disabled={station.gw_status === 3}
            size="small" 
            onClick={() => sendfunction(scale, const_vars.UPDATE_FIRMWARE, cancelAction)}>
              <StopIcon />
          </IconButton>  
          }
          {/* { (station.gw_status > 999) &&
          <IconButton 
            disabled={station.gw_status === 3}
            size="small" 
            onClick={() => sendfunction(station, static_vars.UPDATE_FIRMWARE, pauseAction)}>
              <ReplayIcon />
          </IconButton>  
          } */}
        </Grid>

        <Grid item xs={12} sm={1}>

        </Grid>     
        {/* <Grid item>
        { station.gw_status }
        </Grid> */}
      

      </Grid>
    </Tooltip>           
  ); 
}


export default  (withRouter(ScaleStatus));