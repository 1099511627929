import * as actionTypes from "../actions/actionTypes";
import {updateObject} from "../utility";


const initialState = {
  loading: false,
  scales: [],
  error: null
};

const scalesStart = ( state, action ) => {
  return updateObject( state, { error: null, loading: true } );
};

const scalesSuccess = (state, action) => {
  // console.log("authSuccess - token: ", action.idToken);
  return updateObject( state, {
    scales: action.scales,
    error: null,
    loading: false
  } );
};

const scalesFail = (state, action) => {
  return updateObject( state, {
    error: action.error,
    loading: false
  });
};


const reducer = ( state = initialState, action ) => {
  switch ( action.type ) {
    case actionTypes.SCALES_START: return scalesStart(state, action);
    case actionTypes.SCALES_SUCCESS: return scalesSuccess(state, action);
    case actionTypes.SCALES_FAIL: return scalesFail(state, action);
    default:
      return state;
  }
};

export default reducer;