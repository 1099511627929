import React from 'react';
import { useDispatch, useSelector} from "react-redux";
import 'moment-timezone';
import 'moment/locale/de';
import clsx from 'clsx';
// import moment from 'moment';
import {  makeStyles, Typography, IconButton, Paper, TablePagination,  Tooltip, Toolbar, TableSortLabel, Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import { useEffect } from 'react';
import * as actions from "../store/actions/accounts";
import * as actions_scales from "../store/actions/scales";
import * as actions_fw from "../store/actions/firmwares";


// import { useTranslation } from 'react-i18next';
import ClientComp from './ClientComp';
import FilterListIcon from '@material-ui/icons/FilterList';
// import OperatorComp from './OperatorComp';
// import { getAccounts } from '../../store/utility';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}
function nameComparator(a, b, orderBy) {
  
  if ( a.name_station < b.name_station ){
    return -1;
  }
  if ( a.name_station > b.name_station ){
    return 1;
  }
  return 0;
}
function statusComparator(a, b, orderBy) {
  
  if ( a.last_update < b.last_update ){
    return -1;
  }
  if ( a.last_update > b.last_update ){
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  // console.log("getComparator orderBy: "+orderBy);

  if (orderBy === 'name') {
    return order === 'desc'
    ? (a, b) => nameComparator(a, b, orderBy)
    : (a, b) => -nameComparator(a, b, orderBy);

  } if (orderBy === 'status') {
    return order === 'desc'
    ? (a, b) => statusComparator(a, b, orderBy)
    : (a, b) => -statusComparator(a, b, orderBy);
  } else {

    return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
  }

}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  // console.log("comparator: "+comparator);
  // console.log("stabilizedThis: "+JSON.stringify(stabilizedThis));

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    console.log("create sort Handler: "+event+ " prop: "+property)
    onRequestSort(event, property);
  };
  // const { t } = useTranslation();

  const headCells = [
    { 
      id: 'name', 
      sm: 5, 
      numeric: false, 
      disablePadding: false, 
      label: "Name" },
    { 
      id: 'status', 
      sm: 5, 
      numeric: true, 
      disablePadding: false, 
      label: "Status" },
    { 
      id: 'scales', 
      sm: 1, 
      numeric: true, 
      disablePadding: false, 
      label: "Waagen" },
  ];

  return (  
    <Grid
      spacing={1}
      container
      direction="row"> 
      <Grid
        spacing={0}
        container
        className={classes.tableHeader}
        direction="row"
        justify="center"
        alignItems="center">

        {headCells.map((headCell) => (
          <Grid 
            item           
            xs={12} 
            sm={headCell.sm}
            key={headCell.id} >

            <TableSortLabel //ist eigentlich ein <span> element
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)} >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </Grid>
        ))}
        <Grid item xs={12} sm={1}>
        </Grid>
      </Grid>
    </Grid>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  // onRequestSort: PropTypes.func.isRequired,
  // onSelectAllClick: PropTypes.func.isRequired,
  // order: PropTypes.oneOf(['asc', 'desc', '']).isRequired,
  // orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(2),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          // backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));


const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">
          KundenVerwaltung
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            {/* <DeleteIcon /> */}
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
    marginBottom: theme.spacing(4),
  },
  tableHeader: {
    padding: theme.spacing(2, 4),
    fontWeight: 500
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

const ClientsComp = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const accounts = useSelector(state => state.main.accounts);
  const all_scales = useSelector(state => state.main.all_scales);
  const token = useSelector(state => state.auth.token);
  const [order, setOrder] = React.useState('desc');
  const [orderBy, setOrderBy] = React.useState('');
  // const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  
  useEffect( () => {    
    dispatch(actions_fw.fetchFirmwares(token));
  }, [dispatch, token]);


  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    console.log("handleRequestSort: "+isAsc+ " event: "+event)
    setOrder(
      isAsc ? 'desc' : 'asc');
      setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  /*
    Waagen dieses Kunden anzeigen
  */
  const getScales = (account, scales) => {
    let my_scales = [];
    if (scales)  {
      scales.forEach((scale, index) => {
        // console.log("operator.id_operator: "+operator.id_operator+" account.id_operator: "+account.id_operator)
        if (account.uid === scale.uid) { 
          // console.log("found scales!!!");
          my_scales.push(scale);
        }
      });
    }
    return my_scales;
  }

  useEffect(() => {
    dispatch(actions.fetchAccounts(token))   
  }, [dispatch, token])

  useEffect(() => {
    dispatch(actions_scales.fetchAllScales(token))   
  }, [dispatch, token])

  // useEffect(() => {
  //   dispatch(actions.fetchOperators(token))   
  // }, [dispatch, token])

  // console.log("accounts all_scales: "+all_scales)

  return (
    <Paper className={classes.paper}> 
      <EnhancedTableToolbar numSelected={0} />

      <Grid
        spacing={1}
        container
        direction="column"
        justify="center"
        alignItems="center">   

        <EnhancedTableHead
          classes={classes}
          numSelected={0}
          order={order}
          orderBy={orderBy}
          onRequestSort={handleRequestSort}
          rowCount={accounts.length} />
          
          { accounts !== undefined && 
            stableSort(accounts, getComparator(order, orderBy))
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map((row, index) => {
              return (
                <ClientComp
                  hover
                  index={index}
                  account={row} 
                  scales={getScales(row, all_scales)}
                  key={"client"+row.uid}
                />
              );
            })
          }
      </Grid>

      <TablePagination
        rowsPerPageOptions={[3, 5, 10, 25, 50]}
        component="div"
        count={accounts.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Paper>
  );  
}


export default withRouter(ClientsComp);