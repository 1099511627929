import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import BeeEvents from './BeeEvents';
// import Websocket from 'react-websocket';


const drawerWidth = 256;

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appContent: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  mainContent: {
    flex: 1,
    background: '#eaeaea', /*'#eaeff1',*/
    padding: '0px 0px 0',

    [theme.breakpoints.up('sm')]: {
      padding: '24px 24px 0',
    },
  },
}));

export default function  BeeTheme() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <CssBaseline />
      <nav className={classes.drawer}>
        <Hidden smUp implementation="js">
          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={mobileOpen}
            onClose={() =>  setMobileOpen(!mobileOpen)}
          />
        </Hidden>
        <Hidden xsDown implementation="css">
          <Navigator PaperProps={{ style: { width: drawerWidth } }} />
        </Hidden>
      </nav>
      <div className={classes.appContent}>
        <Header onDrawerToggle={ () =>  setMobileOpen(!mobileOpen)} />
        <main className={classes.mainContent}>
          <Content />
        </main>

        <BeeEvents />
      </div>
    </div>
  );
}
// class BeeTheme extends React.Component {
//   state = {
//     mobileOpen: false,
//   };
//   handleDrawerToggle = () => {
//     this.setState(state => ({ mobileOpen: !state.mobileOpen }));
//   };

//   render() {
//     const { classes } = this.props;
//     // console.log("props in BeeTh: ", this.props);

//     return (
//       <div className={classes.root}>
//         <CssBaseline />
//         <nav className={classes.drawer}>
//           <Hidden smUp implementation="js">
//             <Navigator
//               PaperProps={{ style: { width: drawerWidth } }}
//               variant="temporary"
//               open={this.state.mobileOpen}
//               onClose={this.handleDrawerToggle}
//             />

//           </Hidden>
//           <Hidden xsDown implementation="css">
//             <Navigator PaperProps={{ style: { width: drawerWidth } }} />
//           </Hidden>

//           <BeeEvents />
//         </nav>
//         <div className={classes.appContent}>
//           <Header onDrawerToggle={this.handleDrawerToggle} />
//           <main className={classes.mainContent}>
//             <Content />
//           </main>
//         </div>
//       </div>
//     );
//   }
// }

// BeeTheme.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

// export default withStyles(styles)(BeeTheme);