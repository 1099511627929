import * as actionTypes from './actionTypes';
// import axios from 'axios';
import firebase from 'firebase/app';
import 'firebase/auth';
// import {firebaseLogOut} from "../../components/firebase/firebase";
import store from "../../store/store";
import moment from 'moment';

export const authStart = () => {
  return {
    type: actionTypes.AUTH_START
  };
};

export const authSuccess = (token, userId) => {
  console.log('authSuccess');
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId
  };
};

export const authFail = (error) => {
  return {
    type: actionTypes.AUTH_FAIL,
    error: error
  };
};
export const changeMail = (email) => {
  return {
    type: actionTypes.AUTH_CHANGE_MAIL,
    email: email
  };
};
export const changePassword = (password) => {
  // console.log("changePassword ", password);
  return {
    type: actionTypes.AUTH_CHANGE_PASSWORD,
    password: password
  };
};
export const changeRemember = (remember) => {
  return {
    type: actionTypes.AUTH_CHANGE_REMEMBER,
    remember: remember
  };
};

export const logout = () => {
   console.log('logout in actions auth');
   localStorage.removeItem('token');
   localStorage.removeItem('expirationDate');
   localStorage.removeItem('userId');
  //  firebaseLogOut();
   return {
      type: actionTypes.AUTH_LOGOUT
   };
};

export const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      // dispatch(logout());
       dispatch(authCheckState());
    }, expirationTime * 1000);
  };
};

export const auth = (email, password, isSignup) => {
  return dispatch => {
    dispatch(authStart());
     const auth = firebase.auth();
     auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL)
        .then(function() {
           auth.signInWithEmailAndPassword(email, password)
              .then(function(user) {
                console.log('signInWithEmailAndPassword then user: ', user);

                if (firebase.auth().currentUser != null) {
                  firebase.auth().currentUser.getIdToken(true)
                    .then((idToken) => {
                      console.log('authRefreshToken currentUser: ', firebase.auth().currentUser);
                      // idToken can be passed back to server.
                      // console.log('idToken: ', idToken);
                      const expirationDate = new Date(new Date().getTime() + 15 * 60 * 1000);
                      localStorage.setItem('token', idToken);
                      localStorage.setItem('expirationDate', expirationDate);
                      localStorage.setItem('userId', firebase.auth().currentUser.uid);
                      dispatch(authSuccess(idToken, firebase.auth().currentUser.uid));
                      checkTokenTimeout(29 * 60); //alle 29 Minuten neuen Token abholen!
                    })
                    .catch((error) => {
                      // Error occurred.
                      console.error('authRefreshToken - error: ', error.message);
                      store.dispatch(authFail(error.message));
                    });
                } else {
                  console.log('authRefreshToken - currentUser is NULL');
                  // console.log('firebase.auth().currentUser is NULL');
                }
              })
              .catch(function(err) {
                 console.error('An error occurred while user login. ', err);
                 // showToken('Error retrieving Instance ID token. ', err);
                 // setTokenSentToServer(false);
                 dispatch(authFail(err.message));
              });
        })
        .catch(function(error) {
           // Handle Errors here.
           console.error("errorCode: "+error.code+" errorMessage: "+error.message);
           dispatch(authFail(error.message));
        });
  };
};


export const checkToken = () => {
  return {
    type: actionTypes.AUTH_REFRESH_TOKEN
  };
};

export const checkTokenTimeout = (expirationTime) => {
  return dispatch => {
    console.log('checkTokenTimeout startet at '+moment().format('HH:mm:ss.SSS'));
    // console.log('checkTokenTimeout - expirationTime: ' + (expirationTime * 1000));
    setTimeout(() => {
      console.log('checkTokenTimeout executed at '+moment().format('HH:mm:ss.SSS'));
      dispatch(authRefreshToken());
    }, expirationTime * 1000);
  }
};

export const authRefreshToken = () => {
  return dispatch => {
    // const token = localStorage.getItem('token');

    // if (!token) {
    //   console.log('authRefreshToken - token null');
    // } else {
    // console.log('authRefreshToken - token ok');
    console.log('authRefreshToken at '+moment().format('HH:mm:ss.SSS'));
    //wenn getIdToken aufgerufen wird, dann wird session verlängert!
    if (firebase.auth().currentUser != null) {
      firebase.auth().currentUser.getIdToken(true)
        .then((idToken) => {
          console.log('authRefreshToken currentUser: ', firebase.auth().currentUser);
          // idToken can be passed back to server.
          // console.log('idToken: ', idToken);
          const expirationDate = new Date(new Date().getTime() + 15 * 60 * 1000);
          localStorage.setItem('token', idToken);
          localStorage.setItem('expirationDate', expirationDate);
          localStorage.setItem('userId', firebase.auth().currentUser.uid);
          dispatch(authSuccess(idToken, firebase.auth().currentUser.uid));
          checkTokenTimeout(29 * 60);
        })
        .catch((error) => {
          // Error occurred.
          console.error('authRefreshToken - error: ', error.message);
          store.dispatch(authFail(error.message));
        });
    } else {
      console.log('authRefreshToken - currentUser is NULL');
      // console.log('firebase.auth().currentUser is NULL');
    }
    // }
  }
};



export const authCheckState = () => {
  return dispatch => {
    const token = localStorage.getItem('token');
    if (!token) {
      console.log('authCheckState / token null -> logout');
      dispatch(logout());
    } else {
      //wenn getIdToken aufgerufen wird, dann wird session verlängert!
       if (firebase.auth().currentUser != null) {

        console.log('authCheckState null -> firebase.auth().currentUser != null');

          firebase.auth().currentUser.getIdToken(true)
             .then((idToken) => {
                console.log('authCheckState currentUser: ', firebase.auth().currentUser);
                // idToken can be passed back to server.
                // console.log('idToken: ', idToken);
                 const expirationDate = new Date(new Date().getTime() + 15 * 60 * 1000);
                localStorage.setItem('token', idToken);
                localStorage.setItem('expirationDate', expirationDate);
                localStorage.setItem('userId', firebase.auth().currentUser.uid);
                // authSuccess(idToken, user.user.uid);
                // dispatch(authSuccess(idToken, firebase.auth().currentUser.user.uid));
                dispatch(checkAuthTimeout(15 * 60));
             })
             .catch((error) => {
                console.error('auth error: ', error);

                // Error occurred.
                dispatch(authFail(error.message));
             });
       } else {
          // console.log('firebase.auth().currentUser is NULL');
       }
      // const expirationDate = new Date(localStorage.getItem('expirationDate'));
      // if (expirationDate <= new Date()) {
      //   // dispatch(logout());
      //
      // } else {
      //   const userId = localStorage.getItem('userId');
      //   dispatch(authSuccess(token, userId));
      //   dispatch(checkAuthTimeout((expirationDate.getTime() - new Date().getTime()) / 1000 ));
      // }
    }
  };
};