import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import * as actions from "../store/actions/main";
import {logout} from "../store/actions";
import AddIcon from '@material-ui/icons/Add';
import Button from "@material-ui/core/es/Button/Button";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import TextField from "@material-ui/core/es/TextField/TextField";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import {saveScaleName} from "../store/actions/scales";
import { Map, GoogleApiWrapper } from 'google-maps-react';
import cssStyles from './Dialogs.module.css';

// AIzaSyBPXRG-ZNRurnojURZiITE6Ur97ZzGun2E

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
});


const mapStyles = {
  width: '100%',
  height: 550,
};

class LocationDialog extends React.Component {
  state = {
    edit_open: false,
    textValue:""
  };

  handleClickOpen = () => {
    this.setState({ edit_open: true });
  };

  handleClose = () => {
    this.setState({ edit_open: false });
    this.setState({textValue: ""});
  };

  handleSave = (name) => {
    this.props.saveWaagenName(this.props.token, this.state.textValue, this.props.scale.id_scale);
    this.setState({ edit_open: false });
    // this.setState({textValue: this.props.title});
  };

  setTextValue = (event) => {
    this.setState({textValue: event.target.value});
  };

  render() {



    return (
      <div>
        <Tooltip title="Neuer Standort">
          <IconButton
            color="inherit"
            onClick={this.handleClickOpen}>
            <AddIcon />
          </IconButton>
        </Tooltip>

        <Dialog
          open={this.state.edit_open}
          onClose={this.handleClose}
          fullScreen={true}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">Neuer Standort</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              onChange={this.setTextValue}
              margin="dense"
              id="name"
              label="Name"
              value={this.state.textValue}
              placeholder={this.props.title}
              fullWidth
            />
            <div
              className={cssStyles.mapWrapper }
              style={{
                height: 550,
                width: '100%',
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'center',
                padding: 0 }}
            >
            <Map
              google={this.props.google}
              zoom={7}
              style={mapStyles}
              initialCenter={{ lat: 47.444, lng: 12.176}}
            />
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Abbrechen
            </Button>
            <Button onClick={this.handleSave} color="primary">
              Speichern
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    title: state.main.title,
    token: state.auth.token,
    scale: state.main.currentScale,
    menu: state.main.menu,
    helper_element: state.main.helper_element,
    menu_open: state.main.menu_open
  };
};
// this.props.token,
const mapDispatchToProps = dispatch => {
  return {
    setHelperElement: (event) => dispatch(actions.setHelperElement(event.currentTarget)),
    deleteHelperElement: () => dispatch(actions.setHelperElement(null)),
    logout: () => dispatch(logout()),
    saveWaagenName: (token, name, scale_id) => dispatch(saveScaleName(token, name, scale_id))
  };
};

export default GoogleApiWrapper({
  apiKey: 'AIzaSyBPXRG-ZNRurnojURZiITE6Ur97ZzGun2E'
})(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LocationDialog)));
// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LocationDialog));
