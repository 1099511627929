import React from 'react';
// import PropTypes from 'prop-types';
import { useDispatch, useSelector } from "react-redux";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import * as actions from "../store/actions/main";
import {logout} from "../store/actions";
import EditIcon from '@material-ui/icons/Edit';
import Button from "@material-ui/core/es/Button/Button";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import TextField from "@material-ui/core/es/TextField/TextField";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import {saveScaleName} from "../store/actions/scales";
// import * as actions from "../store/actions/main";

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(1),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
});


// const mapStateToProps = state => {
//   return {
//     title: state.main.title,
//     token: state.auth.token,
//     scale: state.main.currentScale,
//     menu: state.main.menu,
//     helper_element: state.main.helper_element,
//     menu_open: state.main.menu_open
//   };
// };
// // this.props.token,
// const mapDispatchToProps = dispatch => {
//   return {
//     setHelperElement: (event) => dispatch(actions.setHelperElement(event.currentTarget)),
//     deleteHelperElement: () => dispatch(actions.setHelperElement(null)),
//     logout: () => dispatch(logout()),
//     saveWaagenName: (token, name, scale_id) => dispatch(saveScaleName(token, name, scale_id))
//   };
// };
const EditDialog = (props) => {  
  const dispatch = useDispatch();
  // const title = useSelector(state => state.main.title);
  const token = useSelector(state => state.auth.token);
  const scale = useSelector(state => state.main.currentScale);
  const [textValue, setTextValue] = React.useState(scale.name);
  const [editOpen, setEditOpen] = React.useState(false);


// class EditDialog extends React.Component {
  // state = {
  //   edit_open: false,
  //   textValue: this.props.title
  // };

  const handleClickOpen = () => {
    setEditOpen(true);
  };

  const handleClose = () => {
    setEditOpen(false);
    setTextValue(scale.name);
  };

  const handleSave = (name) => {
    dispatch(actions.setTitle(textValue));
    dispatch(saveScaleName(token, textValue, scale.id_scales));
    
    setEditOpen(false);
    // this.setState({textValue: this.props.title});
  };

  const setTextValueEvent = (event) => {
    setTextValue(event.target.value);
    // this.setState({textValue: event.target.value});
  };



  // console.log("props: ", this.props)

  return (
    <div>
      <Tooltip title="Waage umbenennen">
        <IconButton
          color="inherit"
          onClick={handleClickOpen}>
          <EditIcon />
        </IconButton>
      </Tooltip>

      <Dialog
        open={editOpen}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Waage umbenennen</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            onChange={setTextValueEvent}
            margin="dense"
            id="name"
            label="Name"
            value={textValue}
            placeholder={scale.name}
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleSave} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );  
}

// const mapStateToProps = state => {
//   return {
//     title: state.main.title,
//     token: state.auth.token,
//     scale: state.main.currentScale,
//     menu: state.main.menu,
//     helper_element: state.main.helper_element,
//     menu_open: state.main.menu_open
//   };
// };
// // this.props.token,
// const mapDispatchToProps = dispatch => {
//   return {
//     setHelperElement: (event) => dispatch(actions.setHelperElement(event.currentTarget)),
//     deleteHelperElement: () => dispatch(actions.setHelperElement(null)),
//     logout: () => dispatch(logout()),
//     saveWaagenName: (token, name, scale_id) => dispatch(saveScaleName(token, name, scale_id))
//   };
// };

export default withStyles(styles)(EditDialog);
