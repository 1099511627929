import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { makeStyles, Grid, Typography } from '@material-ui/core';
import classNames from 'classnames';
import ValueContainer from "../components/valueContainer/ValueContainer";
import * as const_vars from "../Static/const_vars";
import db from '../store/db';
import { useLiveQuery } from "dexie-react-hooks";

const useStyles = makeStyles((theme) => ({
  statScale: {
    padding: theme.spacing(2),
    // margin: theme.spacing(4),
  }
}));

const StatisticScale = (props) => {
  const { scale } = props;
  const classes = useStyles();
  const dispatch = useDispatch();
  const visible = useSelector(state => state.main.themeColors.visible);
  const token = useSelector(state => state.auth.token); //userId
  const scaleWorker = useSelector(state => state.main.scaleWorker);

  // const [scaledata, setScaledata] = useState([]);
  // console.log("scale.id_scale: ", scale.id_scales);
  // // Query friends within a certain range decided by state:
  // const scaledata = useLiveQuery(
  //   () => db.scaledata
  //       // .orderBy("id_weight")
  //       // .limit(1)
  //           .where("id_scale")
  //           // .belowOrEqual(scale.id_scales)
  //           .equals(parseInt(scale.id_scales))  //parseInt ist wichtig!!
  //       .toArray(),
  //                     // .sortBy("id_weight"),
  //   [scale.id_scales] // because maxAge affects query!
  // );
  // console.log("scale: ", scale);
  
  return (
    <Grid 
        container
        spacing={1}
        direction="row"
        justify="flex-start"
        alignItems="center" 
        className={classNames(classes.statScale)}>
        <Grid item xs={12} sm={4}>   
          <Typography variant="subtitle1" className={classes.gridtext}>            
            {scale.name}
          </Typography>
        </Grid>

        <ValueContainer
            title="Gewicht"
            value_colors={true}
            vorzeichen={false}
            value_color_schwelle={0.1}
            color_class="dunkel_gelb"
            icon={const_vars.ICON_WEIGHT}
            value={Number.parseFloat( scale.last_weight ).toFixed( 2 )}
            min={""}
            max={""}
            einheit="kg"
            tool_value="Gewicht"
            tool_min=""
            tool_max="">
        </ValueContainer>
        
        <ValueContainer
            title={scale.day_diff > 0 ? "Ertrag" : "Abnahme"}
            value_colors={true}
            vorzeichen={true}
            value_color_schwelle={0.1}
            color_class="dunkel_gelb"
            icon={const_vars.ICON_WEIGHT}
            value={Number.parseFloat( scale.day_diff ).toFixed( 2 )}
            min={""}
            max={""}
            einheit="kg"
            tool_value="Tagesertrag"
            tool_min=""
            tool_max="">
        </ValueContainer>
        <ValueContainer
            title="Temperatur"
            value_colors={false}
            vorzeichen={false}
            value_color_schwelle={0.1}
            color_class="dunkel_blau"
            icon={const_vars.ICON_WEIGHT}
            value={Number.parseFloat( scale.temp1 ).toFixed( 1 )}
            min={""}
            max={""}
            einheit="°C"
            tool_value="Temperatur"
            tool_min=""
            tool_max="">
        </ValueContainer>
        {/* <ValueContainer
            title="Luftfeuchte"
            value_colors={false}
            vorzeichen={false}
            value_color_schwelle={0.1}
            color_class="petrol"
            icon={const_vars.ICON_WEIGHT}
            value={Number.parseFloat( scale.hum ).toFixed( 1 )}
            min={""}
            max={""}
            einheit="%"
            tool_value="Luftfeuchte"
            tool_min=""
            tool_max="">
        </ValueContainer> */}
        
        {/* Regen? Wetter?
        x / edit / */}
    </Grid>
  );
}


export default StatisticScale;