import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {Redirect, Route, Switch} from "react-router-dom";
import Dashboard from "../Dashboard/Dashboard";
import ScaleDetail from "../Scales/Scale/ScaleDetail";
import Alarms from "../Alarms/Alarms";
import Apiaries from "../Apiaries/Apiaries";
import Firmware from "../Firmware/Firmware";
import ClientsComp from '../Clients/ClientsComp';
import Settings from "../Settings/Settings";
import Statistic from '../Statistic/Statistic';
import Calibration from '../Calibration/Calibration';
import Scales from '../Scales/Scales';



const styles = theme => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '40px 16px',
  },
});

function Content(props) {
  // const { classes } = props;

  return (
    <div>
      <Switch>
        <Route path="/statistic/:scale_id" component={Statistic}/>
        {/* <Route path="/statistic/:scale_id" component={Statistic}/> */}
        <Route path="/statistic" component={Statistic}/>
        <Route path="/calibration/:scale_id" component={Calibration}/>
        <Route path="/apiaries" exact component={Apiaries}/>

        <Route path="/settings" exact component={Settings}/>
        <Route path="/scale" exact component={Statistic}/>
        <Route path="/scales" exact component={Scales}/>
        <Route path="/alarms" exact component={Alarms}/>
        <Route path="/firmware" exact component={Firmware}/>
        <Route path="/wartung" exact component={ClientsComp}/>

         <Route path="/" exact component={Dashboard}/>
         <Redirect to="/"/>
      </Switch>

    </div>
  );
}

Content.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Content);