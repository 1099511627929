import * as actionTypes from './actionTypes';
import axios from '../../axios-modbee';

export const fetchFirmwaresSuccess = ( firmwares ) => {
    return {
        type: actionTypes.FETCH_FIRMWARES_SUCCESS,
        firmwares: firmwares
    };
};

export const fetchFirmwaresFail = ( error ) => {
    return {
        type: actionTypes.FETCH_FIRMWARES_FAIL,
        error: error
    };
};

export const fetchFirmwaresStart = () => {
    return {
        type: actionTypes.FETCH_FIRMWARES_START
    };
};

export const fetchFirmwares = (token) => {
    return dispatch => {
        dispatch(fetchFirmwaresStart());

      const config = {
        headers: {'Authorization': ''+token}
      };

    //   console.log("fetchFirmwares token: ", token);


      axios.get( '/firmware', config)
          .then( res => {
              const fetchfirmwares = [];
              for ( let key in res.data ) {
                // fetchScales.push(res.data[key]);
                // console.log("got firmware: ", res.data[key]);

                fetchfirmwares.push( {
                    ...res.data[key],
                    id: key
                } );
              }
              dispatch(fetchFirmwaresSuccess(fetchfirmwares));
          } )
          .catch( err => {
              dispatch(fetchFirmwaresFail(err));
          } );
    };
};

