import React , {Component} from 'react';
import {connect} from "react-redux";
import Scales from "../Scales/Scales";


class Dashboard extends Component {
  componentDidMount() {
    // console.log("componentDidMount Dashboard");
  }

  render() {
    // console.log("render Dashboard");
    return (
      <div>
        {/*<Typography variant="caption" className={styles.scale_headline}>Aktive Waagen</Typography>*/}
        <Scales typ="2" />
        {/*<Typography variant="caption" className={styles.scale_headline}>Deaktivierte Waagen (ausgeblendet)</Typography>*/}
        {/*<Scales typ="1" />*/}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    // changeTitle: () => dispatch(actions.setMainTitle('Dashboard'))
    // changeTitle: () => dispatch({type: 'CHANGE_TITLE', title: 'Dashboard'})
  };
};

export default connect(null, mapDispatchToProps) (Dashboard);