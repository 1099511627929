import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import Scale from "./Scale/Scale";
import {Paper} from "@material-ui/core";
import Divider from '@material-ui/core/Divider';
import * as actions from "../store/actions/scales";
// import { get, set } from 'idb-keyval';
import { openDB, deleteDB, wrap, unwrap } from 'idb';
import { LeakAddTwoTone } from '@material-ui/icons';


const Scales = (props) => {

  let {typ} = props;
// class Scales extends Component {
  // constructor(props) {
  //   super(props);
  //   // console.log("Scales constructor: ", this.props.scales);
  //   let doIt = true;
  //   // eslint-disable-next-line
  //   if (this.props.scales != undefined) {
  //     if (this.props.scales.length > 0) {
  //       doIt = false;
  //     }
  //   }

  //   if (doIt) {
  //     this.props.onFetchScales(this.props.token, this.props.userId);
  //   } else {
  //     const self = this;
  //     //verzögert neu laden
  //     setTimeout(function(){
  //         self.props.onFetchScales(self.props.token, self.props.userId);
  //       },
  //       250);
  //   }
  // }
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  // const loading = useSelector(state => state.main.loading);
  const scaleWorker = useSelector(state => state.main.scaleWorker);
  const userId = useSelector(state => state.auth.userId);  
  const scales = useSelector(state => state.scale.scales);
  let storedScales = [];
  try {
    storedScales = JSON.parse(localStorage.getItem('scales'));
  } catch(e) {
    storedScales = [];
      // alert(e); // error in the above string (in this case, yes)!
  }

  const returnScale = (scale, index, rowLen) => {

    if (rowLen === index + 1) {
      // last one
      return (
        <div
          key={"div"+scale.id}>
          <Scale
            key={scale.id}
            typ={typ}
            scale={scale}/>
        </div>
      );
    } else {
      return (
        <div
          key={"div" + scale.id_scales}>
          <Scale
            key={scale.id_scales}
            typ={typ}
            scale={scale}/>
          <Divider
            key={"divider" + scale.id_scales}
            light/>
        </div>
      );
    }
  }
  // console.log("render scales scales: ",  storedScales);

  useEffect( () => {    
    if (token != undefined) {
      console.log("lets get scales");

      dispatch( actions.fetchScalesFast(token) );
      
        //Zu Beginn müssen von dieser Waage die aktuellen Daten geholt werden
      // dispatch( actions.fetchScales(token) );
        // dispatch( fetchScaleDataAll(token, scale.id_scales) );
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  if (typ == 2) {
    //Wurde vom Dashboard aufgerufen
  } else {
    //Scale-Menü -> mehr Optionen bei Scales anzeigen
    typ = 1;
  }

  let my_scales = <div />;
  // if ( !loading ) {
    if (scales != undefined) {
      if (scales.length > 0) {

        const rowLen = scales.length;      
        my_scales = scales.map((scale, index) => {
            return returnScale(scale, index, rowLen);
          }
        )
      } else if (storedScales != undefined) {
        if (storedScales.length > 0) {
          const rowLen = storedScales.length;      
          my_scales = storedScales.map((scale, index) => {
              return returnScale(scale, index, rowLen);
            }
          )
        }
      }
    } else {
      console.log("scales undefined!");
    }
  // }

  return (
    <Paper>
      {my_scales}
    </Paper>
  );
}


export default Scales;