import * as actionTypes from './actionTypes';
import axios from '../../axios-modbee';


export const fetchUserAccountSuccess = ( account ) => {
    return {
        type: actionTypes.FETCH_USER_ACCOUNT_SUCCESS,
        account: account
    };
};

export const fetchAccountsSuccess = ( accounts ) => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_SUCCESS,
        accounts: accounts
    };
};

export const fetchAccountsFail = ( error ) => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_FAIL,
        error: error
    };
};

export const fetchAccountsStart = () => {
    return {
        type: actionTypes.FETCH_ACCOUNTS_START
    };
};

export const fetchAccounts = (token) => {
    // console.log("getting accounts with token: "+token);
    return dispatch => {
        dispatch(fetchAccountsStart());
        const config = {
            headers: {'Authorization': ''+token}
        };

        // console.error("getting accounts..");
        axios.get( '/accounts', config)
            .then( res => {
                // console.log("got accounts: ", res);
                const accounts = [];
                for ( let key in res.data ) {
                    // console.log("got account: ", res.data[key]);

                    accounts.push( {
                        ...res.data[key],
                        id: key
                    } );
                }
                dispatch(fetchAccountsSuccess(accounts));
            } )
            .catch( err => {
                dispatch(fetchAccountsFail(err));
            } );
    };
};


export const fetchUserAccount = (token) => {
    return dispatch => {

        if (token != null) {
            // console.log("fetchUserAccount");
            const config = {
                headers: {'Authorization': ''+token}
            };
            // console.log("fetchUserAccount: ", token);    
            // console.error("getting accounts..");

            axios.get( '/account', config)
                .then( res => {
                    // console.log("got my account: ", res.data);
                    dispatch(fetchUserAccountSuccess(res.data));
                }).catch( err => {
                    console.log("err getting account: ", err);
                });
        }
    };
};
