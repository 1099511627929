import React , {Component} from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import * as actions from '../store/actions/index';
import {connect} from "react-redux";
import Typography from "@material-ui/core/es/Typography/Typography";
import beelogo from '../Static/beelogo.png';
import styles from './SignIn.module.css';
import Grid from "@material-ui/core/es/Grid/Grid";
import TextField from "@material-ui/core/es/TextField/TextField";
import * as actionTypes from "../store/actions/actionTypes";
import CircularProgress from "@material-ui/core/es/CircularProgress/CircularProgress";


class SignIn extends Component {

  componentDidMount() {

  }

  submitHandler = (event) => {
    event.preventDefault();
    // console.log("submitHandler mail: ", this.props.email, " password: ", this.props.password);
    this.props.onAuth(this.props.email, this.props.password); //this.props.password);
  };

  switchAuthModeHandler = () => {
    this.setState(prevState => {
      return {isSignup: !prevState.isSignup};
    });
  };

  handleCheckboxChange = name => event => {
    // this.setState({ [name]: event.target.checked });
    console.log("handleCheckboxChange Alarms ", event.target.checked, " name: ", name);
  };

  handleChange = type => event => {
    // console.log("handleChange mail: ", this.props.email, " password: ", this.props.password);

    switch ( type ) {
      case actionTypes.AUTH_CHANGE_MAIL:
        // console.log("AUTH_CHANGE_MAIL ", event.target.value);
        this.props.onChangeMail(event.target.value);
        return;
      case actionTypes.AUTH_CHANGE_PASSWORD:
        // console.log("AUTH_CHANGE_PASSWORD ", event.target.value);
        this.props.onChangePassword(event.target.value);
        return;
      case actionTypes.AUTH_CHANGE_REMEMBER:
        // console.log("AUTH_CHANGE_REMEMBER ", event.target.checked);
        this.props.onChangeRemember(event.target.checked);
        return;

      default:
        console.log("handleChange default ", event.target.value);
        return;
    }
    // this.setState({ [name]: event.target.value });
  };

  render() {
    return (
      <main className={styles.main}>
        <CssBaseline/>
        <Paper className={styles.paper}>
          <Grid item  xs container className={styles.logo_row}  justify="center" spacing={8}>
            <Grid item>
              <img src={beelogo} alt=""  className={styles.logo} />
            </Grid>
            <Grid item>
              <Typography component="h1" variant="h5">
                modbee.io
              </Typography>
            </Grid>

            <Grid item>
              {this.props.loading && <CircularProgress size={24} className={styles.buttonProgress} />}
            </Grid>

          </Grid>

          <form className={styles.form} onSubmit={this.submitHandler}>
            <TextField
              required
              fullWidth
              label="Email Addresse"
              className={styles.textField}
              margin="normal"
              // variant="outlined"
              id="email"
              name="email"
              autoComplete="email"
              // value={this.props.email}
              onChange={this.handleChange(actionTypes.AUTH_CHANGE_MAIL)}
              // onChange={(event, newValue) => this.handleChangeMail(event, newValue)}
              autoFocus
            />

            <TextField
              required
              fullWidth
              label="Passwort"
              className={styles.textField}
              margin="normal"
              name="password"
              type="password"
              id="password"
              // value={this.props.password}
              autoComplete="current-password"
              onChange={this.handleChange(actionTypes.AUTH_CHANGE_PASSWORD)}
              // onChange={(event, newValue) => this.handleChangePassword(event, newValue)}
              // variant="outlined"
            />

            <FormControlLabel
              control={
                <Checkbox
                  checked={this.props.remember}
                  onChange={this.handleChange(actionTypes.AUTH_CHANGE_REMEMBER)}
                  value="checkedB"
                  color="primary"
                />
              }
              label="Automatisch anmelden"
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={styles.submit}
            >
              Einloggen
            </Button>

            <Typography variant="caption"
                        className={styles.errorText}
                        color="error">{ this.props.error }</Typography>
          </form>
        </Paper>
      </main>
    );
  }
}

const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    email: state.auth.email,
    remember: state.auth.remember,
    password: state.auth.password,  //Wird nur temporär gespeichert!
    error: state.auth.error,
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onAuth: (email, password) => dispatch(actions.auth(email,password)),
    onChangePassword: (password) => dispatch(actions.changePassword(password)),
    onChangeMail: (email) => dispatch(actions.changeMail(email)),
    onChangeRemember: (remember) => dispatch(actions.changeRemember(remember)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignIn);