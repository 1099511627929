import React, { useEffect, useState, useContext } from 'react';
import './App.css';
import BeeTheme from './Home/Layout';
import { Route, Switch, withRouter, Redirect } from "react-router-dom";
import {connect, useDispatch, useSelector} from "react-redux";
import * as actions from "./store/actions";
import * as auth_actions from './store/actions/index';
import SignIn from "./Login/SignIn";
// import {MuiThemeProvider} from "@material-ui/core/umd/material-ui.production.min";
import {Button} from "@material-ui/core";
import { createMuiTheme, responsiveFontSizes, ThemeProvider } from '@material-ui/core/styles';
import 'typeface-roboto';
import * as actions_accounts from "./store/actions/accounts";
import * as scale_actions from "./store/actions/scales";
import * as actions_main from "./store/actions/main";
import worker from './Static/webworker';
import * as actions_scale from "./store/actions/scales";
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import db from './store/db';


// import webworker from 'workerize-loader!./webworker'; // eslint-disable-line import/no-webpack-loader-syntax
// import webworker from 'worker-loader!./webworker.js'; // eslint-disable-line import/no-webpack-loader-syntax
import ScaleWorker from 'worker-loader!./scale.worker.js'; // eslint-disable-line import/no-webpack-loader-syntax

const App = (props) => {
  const { isAuthenticated, onTryAutoSignup } = props;
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const userId = useSelector(state => state.auth.userId);

  const primary = useSelector(state => state.main.themeColors.primary);
  const secondary = useSelector(state => state.main.themeColors.secondary);
  const primaryText = useSelector(state => state.main.themeColors.primaryText);
  const secondaryText = useSelector(state => state.main.themeColors.secondaryText);
  const highlight = useSelector(state => state.main.themeColors.highlight);
  const appBar = useSelector(state => state.main.themeColors.appBar);
  const sideBar = useSelector(state => state.main.themeColors.sideBar);
  const ertrag = useSelector(state => state.main.themeColors.ertrag);
  const weight = useSelector(state => state.main.themeColors.weight);
  const temperature = useSelector(state => state.main.themeColors.temperature);
  const voltage = useSelector(state => state.main.themeColors.voltage);
  const humidity = useSelector(state => state.main.themeColors.humidity);
  // Run your calculations
  // workerInstance.calculatePrimes(500, 1000000000)
  const backColorSide =  sideBar+' !important';//'#20B9BA !important';
  const backColorTop =  appBar+' !important';//'#20B9BA !important';

  const scaleWorker = useSelector(state => state.main.scaleWorker);
  const [loadingScales, setLoadingScales] = useState(false);
  const [scalesReadFromDb, setScalesReadFromDb] = useState(false);
  const [infoOpen, setInfoOpen] = React.useState(false);
  const [infoNr, setInfoNr] = React.useState(0);
  const [infoTotal, setInfoTotal] = React.useState(0);
  const updateAvailable = useSelector(state => state.serviceWorker.serviceWorkerUpdated);

  console.log("updateAvailable: ", updateAvailable);
  // console.log("token: ", token);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  };
  
  const handleCloseUpdate = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setInfoOpen(false);
  };
  

  const reloadPage = () => {
    if ('caches' in window) {
      console.log('reloadPage - delete cache')
      caches.keys().then((names) => {
        // Delete all the cache files
        names.forEach(name => {
          caches.delete(name);
        })
        window.location.reload();
      });
    } else {
      console.log('reloadPage cache not in windows...')
    }
  };

  // useEffect(() => {
  //   dispatch(actions_main.fetchState())
  // },[dispatch])

  // const [scaleWorker, setScaleWorker] = useState();
  useEffect(() => {
    const scaleWorkerInstance = new ScaleWorker(worker); //SearchWorker();
    dispatch(scale_actions.setScaleWorker(scaleWorkerInstance));
    
    scaleWorkerInstance.onmessage = (event) => {
      if (event && event.data) {
          //Daten vom Webworker müssen an richtiger Stelle in der indexed DB gespeichert werden!
          //TODO Bearbeitung auslagern          
          if (event.data.scales) {
            // setScalesList(event.data.scales);
            localStorage.setItem("scales", JSON.stringify(event.data.scales));
            if (event.data.new_data == 1) {
              console.log("fetchScalesSuccess.. now dispatch!");
              dispatch(actions_scale.fetchScalesSuccess(event.data.scales));
            }
          } else if (event.data.type) {   
            if (event.data.type === "info") {
              if (event.data.nr === 0) {
                console.log("scaledata successfully updated in worker");
                setInfoTotal(0);
              } else if (event.data.nr === 1) {
                console.log("scaledata is beeing downloaded ...", event.data.count, " to go..");    
                if (event.data.count > 100) {
                  //Nur anzeigen wenn sichs wirklich auszahlt...
                  setInfoTotal(event.data.count);
                } else {
                  setInfoTotal(0);
                } 
              } else {

              }
            } else if (event.data.type === "error") {
              if (event.data.error_type === "scales") {
                //Reload mit neuem TOKEN machen!
                //ACHTUNG - kann endlosschleife werden?!?
                //TODO eventuell delay einbauen
                if (event.data.nr === 401) {
                  console.log("scaledata error loading. need new token");
                  //get new token and fire after new token is ready!
                  dispatch(auth_actions.authRefreshToken());
                  setTimeout(function () {
                      setLoadingScales(false);
                  }, 3000);
                }
              }
            } else if (event.data.type === "update") {
              if (!infoOpen) {
                setInfoOpen(true);
              }
              // setInfoNr(event.data.item);
              // setInfoTotal(event.data.from);
              // setInfoStep(event.data.step);
              // setInfoStepTotal(event.data.step_from);
              
              console.log("update worker: ", event.data.data);
              // console.log("new_data fetchScaleDataAll: ", new_data);
              // const insertWeightData = async (new_data) => {

              //   // const db = await openDB("scale_db"+userId, 1, {});
              //   // const weightData = await db.getAll('weight_data');
              //   // let store;              
              //   // const tx = db.transaction('weight_data', 'readwrite');
              //   // store = tx.store; //.index('date');                

              //   // console.log("weightData from db: ", weightData);

              //   // for ( let index3 in new_data ) {
              //   //   console.log("insert "+(index3)+"/"+new_data.length+" to weight_data in db: ");//, JSON.stringify(new_data[index3]));
              //   //   // await db.add('weight_data', new_data[index3]);
              //   // }   
              // }   
              // insertWeightData(event.data.data);
            } else {
              console.log("unknown msg with type scaleWorkerInstance: ", event.data);
            }           
          } else {
            console.log("unknown msg scaleWorkerInstance: ", event.data);
          }
      }
    };      
    return () => {
      scaleWorkerInstance.terminate();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, infoOpen, userId]);


  // useEffect( () => {
  //   const loadScalesFromDB = async () => { 
  //     try {        
  //       console.time('db.scales')
  //       const myScales = await db.scales
  //         .toArray()
  //         .catch (function (err) {
  //           console.log("error getting scales from db: ",err);
  //         });
  //       console.timeEnd('db.scales')

  //       // console.log("fetched scales from db: ",myScales);
  //       dispatch(actions_scale.fetchScalesSuccess(myScales));
  //     } catch (error) {
  //       console.log("error getting scales from DB: ",error)
  //     }
  //   };

  //   // console.log('isAuthenticated: '+isAuthenticated);
  //   if (( isAuthenticated ) && !scalesReadFromDb) {
  //     // console.log('scales aus DB auslesen');
  //     setScalesReadFromDb(true);
  //     loadScalesFromDB();
  //   }
  // }, [dispatch, isAuthenticated, userId, scalesReadFromDb]);


  // eslint-disable-next-line 
  useEffect( () => {
    onTryAutoSignup();
  }, [isAuthenticated, onTryAutoSignup]);


  useEffect( () => {    
    if (( isAuthenticated ) && (scaleWorker) && (!loadingScales)) {
      setLoadingScales(true);
      try {
        scaleWorker
        .postMessage({
          token: token,
          uid: userId,
          typ: 'scales',
          new_data: 0
          // data: val
        });            
      } catch (error) {            
        console.log('error com scaleworker ', error)
      }      
      //aktuellen User abfragen vom Server
      dispatch(actions_accounts.fetchUserAccount(token))
    } else {
      //TODO User zurücksetzen!
      // dispatch(actions_accounts.fetchUserAccount(token))
      // dispatch(actions_accounts.fetchAccounts(token))
    }
  }, [dispatch, isAuthenticated, token, scaleWorker, userId, loadingScales]);


  useEffect( () => {
    if (isAuthenticated) {
      //aktuellen User abfragen vom Server
      dispatch(actions_accounts.fetchUserAccount(token))
    }
  }, [dispatch, isAuthenticated, token]);


  let theme = createMuiTheme({
    spacing: 4,
    typography: {
      useNextVariants: true,
      marginBottom: 0,
      h5: {
        fontWeight: 500,
        fontSize: 26,
        letterSpacing: 0.5,
      },
    },
    palette: {
      primary: {
        // light: '#20B9BA',
        main: primary, //'#694019', //'#3b190e', //'#1da3a4',
        // dark: '#694019',
      },
      secondary: {
        // light: '#20B9BA',
        main: secondary, //'#694019', //'#3b190e', //'#1da3a4',
        // dark: '#694019',
      },
    },
    shape: {
      borderRadius: 8,
    },
  });
  theme = responsiveFontSizes(theme);
  theme = {
    ...theme,
    overrides: {
      MuiDrawer: {
        paper: {
          backgroundColor: backColorSide, //'#252a2e !important',
        },
      },
      MuiTypography: {
        // marginBottom: 0,
      },
      MuiButton: {
        label: {
          textTransform: 'initial',
        },
        // contained: {
        //   boxShadow: 'none',
        //   '&:active': {
        //     boxShadow: 'none',
        //   },
        // },
      },
      MuiTabs: {
        root: {
          marginLeft: theme.spacing(1),
        },
        indicator: {
          height: 3,
          borderTopLeftRadius: 3,
          borderTopRightRadius: 3,
          backgroundColor: theme.palette.common.white,
        },
      },
      MuiTab: {
        root: {
          textTransform: 'initial',
          margin: '0 16px',
          minWidth: 0,
          [theme.breakpoints.up('md')]: {
            minWidth: 0,
          },
        },
      },
      MuiIconButton: {
        root: {
          padding: theme.spacing(2),
        },
      },
      MuiTooltip: {
        tooltip: {
          borderRadius: 4,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: '#252a2e',
        },
      },
      MuiAppBar: {
        root: {
          backgroundColor: backColorTop,
          color: '#fff !important',
        },
      },
      MuiListItemText: {
        primary: {
          fontWeight: theme.typography.fontWeightMedium,
          color: '#fff'
        },
      },
      MuiListItemIcon: {
        root: {
          color: 'inherit',
          marginRight: 0,
          '& svg': {
            fontSize: 20,
          },
        },
      },
      MuiAvatar: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },
    props: {
      MuiTab: {
        disableRipple: false,
      },
    },
    mixins: {
      ...theme.mixins,
      toolbar: {
        minHeight: 48,
      },
    },
  };
  

  // userId

  // console.log("render App.js");
  let routes = (
    <ThemeProvider theme={theme}>
      <Switch>
        <Route path="/login" component={SignIn} />
        <Redirect to="/login" />
      </Switch>
    </ThemeProvider>
  );

  if ( isAuthenticated ) {
    routes = (
        <ThemeProvider theme={theme}>
          <BeeTheme />
        </ThemeProvider>
    );
  }
  return (
    <div>          
      {/* <Snackbar open={updateAvailable} autoHideDuration={60000} onClose={handleCloseUpdate}>
        <MuiAlert elevation={6} variant="filled" onClose={handleCloseUpdate} severity="warning">
          Update gefunden 
          <Button onClick={reloadPage}>OK - SEITE NEU LADEN</Button>
        </MuiAlert>
      </Snackbar> */}

      {routes}
      {/* <Snackbar open={infoOpen} onClose={handleClose}> */}
      <Snackbar 
      open={infoTotal > 0} 
      message={"Datensynchronisierung für Statistik - noch "+infoTotal+" Datensätze"}
      onClose={handleClose} >
      </Snackbar>
    </div>
  );
}



function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const mapStateToProps = state => {
  // console.log("mapStateToProps App.js: ", state);
  return {
    isAuthenticated: state.auth.token !== null
  };
};

const mapDispatchToProps = dispatch => {
  return {
    onTryAutoSignup: () => dispatch( actions.authCheckState() )
  };
};

export default withRouter( connect( mapStateToProps, mapDispatchToProps )( App ) );