import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import WaagenIcon from '@material-ui/icons/Router';
import GpsIcon from '@material-ui/icons/GpsFixed';
import TimeLineIcon from '@material-ui/icons/ShowChart';
import PublicIcon from '@material-ui/icons/EventNote';
import TimerIcon from '@material-ui/icons/Notifications';
import SettingsIcon from '@material-ui/icons/Settings';
import {connect, useSelector} from "react-redux";
import NavListItem from "./NavListItem";
import Typography from "@material-ui/core/es/Typography/Typography";
import beelogo from '../Static/beelogo_white.png';
import * as const_vars from "../Static/const_vars";
import MemoryIcon from '@material-ui/icons/Memory';
import ColorPicker from 'material-ui-color-picker';
import ColorSelect from '../Settings/ColorSelect';
import pkg from '../../package.json';


const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(255, 255, 255, 0.9)',
  },
  logo: {
    width: 36,
    marginRight: 16,
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  logoContainer: {
    height: 48,
    // paddingLeft: 60
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActiveItem: {
    color: '#adea00', //adea00',
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  user: {
    fontSize: 10,
    color: '#adea00', //adea00',
    marginLeft: 16,
    marginTop: 16
  },
  version_nr: {
    fontSize: 10,
    // color: '#adea00', //adea00',
    marginLeft: 16,
    marginTop: 16
  }
});


const Navigator = (props) => {
  // function Navigator(props) {
  const { classes, ...other } = props;
  const account = useSelector(state => state.main.account);
  // const { t } = useTranslation();
  let manage = { 
      id: 'disabled',
      children: [], 
    };

  if (account.hasOwnProperty('uid')){
    // console.log("account is valid: "+JSON.stringify(account)); 
    // Anzeige von Verwaltung nur für Hersteller oder Reseller
    if (account.user_role === 1) {
      manage = {
        id: "Verwaltung",
        children: [
          // { id: t('menu.customers'), icon: <PeopleIcon />, path: '/clients' },
          // { id: t('menu.config_stations'), icon: <WaagenIcon />, path: '/stations/settings' },
          // { id: "Calibration", icon: <MemoryIcon />, path: '/calibration', menu: const_vars.MENU_CALIBRATION  },
          { id: "Firmware", icon: <MemoryIcon />, path: '/firmware', menu: const_vars.MENU_FIRMWARE  },
          { id: "Wartung", icon: <WaagenIcon />, path: '/wartung', menu: const_vars.MENU_WARTUNG  },
        ],
      };
    }
  }

  const categories = [
    {
      id: 'Allgemein',
      children: [
        { id: 'Dashboard', icon: <DashboardIcon />, path: '/', menu: const_vars.MENU_DASH },
        { id: 'Standorte', icon: <GpsIcon />, path: '/apiaries', menu: const_vars.MENU_APIARIES },
        { id: 'Waagen', icon: <WaagenIcon />, path: '/scales', menu: const_vars.MENU_SCALES },
        { id: 'Statistik', icon: <TimeLineIcon />, path: '/statistic', menu: const_vars.MENU_STATISTIC },
        { id: 'Ereignisse', icon: <PublicIcon />, path: '/events', menu: const_vars.MENU_EVENTS },
      ],
    },
    {
      id: 'Einstellungen',
      children: [
        { id: 'Allgemein', icon: <SettingsIcon />, path: '/settings', menu: const_vars.MENU_SETTINGS },
        { id: 'Alarme', icon: <TimerIcon />, path: '/alarms', menu: const_vars.MENU_ALARMS },
      ],
    }, manage
  ];



  return (
    <Drawer variant="permanent" { ...other }>
      <List disablePadding>        
        <ListItem>
        {/* <ListItem className={classNames(
          classes.firebase, classes.item, classes.itemCategory, classes.logoContainer)}> */}

            <img src={beelogo} alt="" className={classes.logo} />

            <Typography variant="h5" className={classes.categoryHeaderPrimary}>
            {/* <Typography variant="h5" className={classes.categoryHeaderPrimary}> */}
              modbee.io
            </Typography>
        </ListItem>
        {categories.map(({id, children}) => (          
          id != "disabled" &&
            <React.Fragment key={id}>
              <ListItem className={classes.categoryHeader}>
                <ListItemText
                  className={classNames(
                    classes.categoryHeaderPrimary
                  )} >
                  {id}
                </ListItemText>
              </ListItem>
              {children.map(({id: childId, icon, path, menu}) => (

                  <NavListItem
                    key={childId}
                    icon={icon}
                    path={path}
                    childId={childId}
                    menu={menu}
                    >

                  </NavListItem>
              ))}
              <Divider className={classes.divider}/>
            </React.Fragment>          
        ))}
      </List>
      {/* <Typography variant="caption" className={classes.user}>
        User: {account.name}
      </Typography> */}
      <Typography variant="caption" className={classes.version_nr}>
        v{pkg.version}
      </Typography>

      <ColorSelect color="sideBar" name="Hintergrund">
      </ColorSelect>

      <ColorSelect color="primaryText" name="Schriftfarbe">
      </ColorSelect>

      <ColorSelect color="highlight" name="Aktivfarbe">
      </ColorSelect>

      {/* <ColorPicker
        name='color'
        defaultValue='#000'
        // value={this.state.color} - for controlled component
        onChange={color => console.log(color)}      
      /> */}
    </Drawer>
  );
  // }
}

// Navigator.propTypes = {
//   classes: PropTypes.object.isRequired,
// };

const mapStateToProps = state => {
  return {
    // title: state.main.title,
    // helper_element: state.main.helper_element,
    // menu_open: state.main.menu_open
  };
};

const mapDispatchToProps = dispatch => {
  return {
    // handleNavigation: (path) => dispatch(actions.setHelperElement(path))
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Navigator));
