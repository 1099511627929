import React, {useState, useRef, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {Fab, Box, Button, Paper, Typography} from "@material-ui/core";
import { makeStyles } from '@material-ui/core';
import PaletteIcon from '@material-ui/icons/Palette';
import { SketchPicker } from 'react-color';
import * as actions from "../store/actions/main";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Draggable from 'react-draggable';


/*
COLOR PICKER

https://casesandberg.github.io/react-color/

*/


const useStyles = makeStyles((theme) => ({
  customClass: {
    padding: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
  },
  fabClass: {
    backgroundColor: '#FFF',
    margin: theme.spacing(1)
  },
  absoluteClass: {
    // position: absolute,
    position: "absolute",
    color: '#CCC'
  },
  // canvasStyle: {
  //   height:20,
  //   width: 50,
  // }
}));


function PaperComponent(props) {
  return (
    <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
      <Paper {...props} />
    </Draggable>
  );
}

const ColorSelect = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const {color, name} = props;

  const visible = useSelector(state => state.main.themeColors.visible);
  const [open, setOpen] = useState(false);
  const themeColors = useSelector(state => state.main.themeColors);
  const selectorColor = useSelector((state) => {    
    return state.main.themeColors[color]; //res; //.filter(stateColor => color == stateColor)
  });

  const [localColor, setLocalColor] = useState(selectorColor);
  // const themeColors = state => state.main.themeColors
 
  const style = {
    backgroundColor: '#ccc', //D0021B',
  };

  const iconstyle = {
    marginRight: '4px',
    color: selectorColor,
  };


  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = () => {
    localStorage.setItem(color, localColor);
    setOpen(false);
  };

  const handleReset = () => {
    // setOpen(false);
  };

  const handleChange = (new_color) => {
    // setColor(color);
    // console.log("color change: ", new_color.hex)
    setLocalColor(new_color.hex);
    themeColors[color] = new_color.hex;
    dispatch(actions.setThemeColors(themeColors));
  }

// setThemeColors
  const show = visible ? "block" : "none";

  return (
    <Box zIndex="tooltip" display={show}>
      {/* <canvas ref={canvas} className={classes.canvasStyle}></canvas> */}

      <Fab variant="extended" size="small" className={classes.fabClass} style={style} onClick={() => setOpen(!open)}>
        <PaletteIcon style={iconstyle}>

        </PaletteIcon>
        <Typography >{name}</Typography>
      </Fab>    
      {/* <Box display={show} zIndex="tooltip" className={classes.absoluteClass} >
      </Box> */}
      <Dialog
        open={open}
        hideBackdrop
        onClose={handleClose}
        PaperComponent={PaperComponent}
        aria-labelledby="draggable-dialog-title" >
        <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
          Farbe ändern
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
            
          </DialogContentText> */}

          <SketchPicker color={localColor} onChangeComplete={handleChange} />
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button autoFocus onClick={handleReset} color="primary">
            Zurücksetzen
          </Button>
          <Button onClick={handleSave} color="primary">
            Speichern
          </Button>
        </DialogActions>
      </Dialog>

    </Box>
  );
}

export default ColorSelect;