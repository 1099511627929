import * as axios from "axios";


let instance;
if (process.env.NODE_ENV !== 'production') {
    // console.error("DEV MODE - "+config.url.API_URL);
    instance = axios.create({
        baseURL: 'http://localhost:10001/v3/'
    });
} else {
    console.error("PROD MODE - https://api.modbee.io/v3/");
    //Production ist immer automatisch eingestellt, wenn via npm build die App erstellt wird für den Server
    instance = axios.create({
      baseURL: 'https://api.modbee.io/v3/'
    });
}

export default instance;