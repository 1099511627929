import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/messaging';
// import {authSuccess} from "../../store/actions";
// import {checkTokenTimeout} from "../../store/actions/auth";
import * as actionTypes from '../../store/actions/actionTypes';

const config = {
   apiKey: "AIzaSyAnyxaFvhtf2N6KLRbWfREHoI_TRVkRXx4",
   authDomain: "project-2042544741081579589.firebaseapp.com",
   databaseURL: "https://project-2042544741081579589.firebaseio.com",
   projectId: "project-2042544741081579589",
   storageBucket: "project-2042544741081579589.appspot.com",
   messagingSenderId: "551443545984",
   appId: "1:551443545984:android:cc7c1e54058227b4"
};

// apiKey: "AIzaSyBPXRG-ZNRurnojURZiITE6Ur97ZzGun2E",
// authDomain: "project-2042544741081579589.firebaseapp.com",
// databaseURL: "https://project-2042544741081579589.firebaseio.com",
// projectId: "project-2042544741081579589",
// storageBucket: "project-2042544741081579589.appspot.com",
// messagingSenderId: "551443545984",
// appId: "1:551443545984:web:c55ca541d5f68ca42c0b65"
class Firebase {
  constructor(store) {
    app.initializeApp(config);
    console.log('Firebase init app...'); //, store);

    this.store = store;
    this.auth = app.auth();
    this.db = app.database();

    this.auth.onAuthStateChanged(function(user) {
      if (user) {
         // User is signed in.
        if (user) {
          app.auth().currentUser.getIdToken(true)
            .then((idToken) => {
              // idToken can be passed back to server.
              console.log('onAuthStateChanged user ...');
              localStorage.setItem('token', idToken);
              localStorage.setItem('userId', app.auth().currentUser.uid);
              localStorage.setItem('mail', app.auth().currentUser.email);
              // store.dispatch(authSuccess(idToken, app.auth().currentUser.uid));
              store.dispatch(helperAuthSuccess(idToken, app.auth().currentUser.uid));
              // store.dispatch(checkTokenTimeout(60*15)); //in 15 Minuten token refreshen!
            })
            .catch((error) => {
              // Error occurred.
              // dispatch(authFail(error.message));
            });
        } else {
          console.log('login error');
          // dispatch(authFail("user null"));
        }
      } else {
         // No user is signed in.
         console.log('onAuthStateChanged NO user signed in');
         // this.props.onLogout();
      }
    });



    // this.messaging = app.messaging();
    // // this.messaging.usePublicVapidKey("BKQlGDPsXs6qSJNtCx67yMhhqQZlcHvpliAYf9jvH-hnZdsdwJKfWdHgWAnZXFKGjcAN8Y63zVVkRXHFl7HF0YI");
    // this.messaging.getToken({vapidKey: "BKQlGDPsXs6qSJNtCx67yMhhqQZlcHvpliAYf9jvH-hnZdsdwJKfWdHgWAnZXFKGjcAN8Y63zVVkRXHFl7HF0YI"})
    //   .then(function(currentToken) {
    //     if (currentToken) {
    //       console.log(' initializeFirebase - has token: ', currentToken);
    //       // sendTokenToServer(currentToken);
    //       // updateUIForPushEnabled(currentToken);
    //     } else {
    //       // Show permission request.
    //       console.error('initializeFirebase - No Instance ID token available. Request permission to generate one.');
    //       // Show permission UI.
    //       // updateUIForPushPermissionRequired();
    //       // setTokenSentToServer(false);
    //     }
    //   }).catch(function(err) {
    //     console.log('An error occurred while retrieving token. ', err);
    //     // showToken('Error retrieving Instance ID token. ', err);
    //     // setTokenSentToServer(false);
    //   });

    // //TODO - listen for token invalidations

    // // this.messaging.onTokenRefresh(function() {
    // this.messaging.getToken().then(function(refreshedToken) {
    //   console.log('Token refreshed.');
    //   // Indicate that the new Instance ID token has not yet been sent to the
    //   // app server.
    //   // setTokenSentToServer(false);
    //   // Send Instance ID token to app server.
    //   // sendTokenToServer(refreshedToken);
    //   // ...
    // }).catch(function(err) {
    //   console.log('Unable to retrieve refreshed token ', err);
    //   // showToken('Unable to retrieve refreshed token ', err);
    // });
    // // });

    // this.messaging.onMessage(function(payload) {
    //   console.log('Message received. ', payload);
    //   // ...
    // });
  }

  // *** Auth API ***
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);
  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  doSignOut = () => this.auth.signOut();
  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);
  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);
}

export default Firebase;

export const helperAuthSuccess = (token, userId) => {
  console.log('authSuccess');
  return {
    type: actionTypes.AUTH_SUCCESS,
    idToken: token,
    userId: userId
  };
};

export const askForPermissioToReceiveNotifications = async () => {
  try {
    const messaging = app.messaging();
    // await messaging.requestPermission();
    // Get Instance ID token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache.

    messaging.getToken({vapidKey: "BKQlGDPsXs6qSJNtCx67yMhhqQZlcHvpliAYf9jvH-hnZdsdwJKfWdHgWAnZXFKGjcAN8Y63zVVkRXHFl7HF0YI"})
      .then(function(currentToken) {
        if (currentToken) {
          console.log('currentToken - have it: ', currentToken);
          // sendTokenToServer(currentToken);
          // updateUIForPushEnabled(currentToken);
        } else {
          // Show permission request.
          console.log('No Instance ID token available. Request permission to generate one.');
          // Show permission UI.
          // updateUIForPushPermissionRequired();
          // setTokenSentToServer(false);
        }
      }).catch(function(err) {
        console.log('An error occurred while retrieving token. ', err);
        // showToken('Error retrieving Instance ID token. ', err);
        // setTokenSentToServer(false);
      });
    // const token = await messaging.getToken();
    // console.log('token: ', token);
    // return token;
  } catch (error) {
    console.error(error);
  }
};

export const firebaseLogOut = async () => {
  try {
    console.log('firebaseLogOut');

    app.auth().signOut().then(function() {
      // Sign-out successful.
      //TODO Weiterleitung auf Login-Page (mit Nachricht - Logout erfolgreich)
    }).catch(function(error) {
      // An error happened.
    });
  } catch (error) {
    console.error(error);
  }
};
