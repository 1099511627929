import React from 'react';
import 'moment-timezone';
import 'moment/locale/de';
import Moment from "react-moment";
import { Grid, makeStyles, Typography, IconButton, Collapse } from '@material-ui/core';
import {withRouter} from "react-router-dom";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import teal from '@material-ui/core/colors/teal';
import lime from '@material-ui/core/colors/lime';
import { fade } from '@material-ui/core/styles/colorManipulator';
// import { getOperator } from '../../store/utility';
// import { useSelector } from 'react-redux';
import ScaleAdminComp from './ScaleAdminComp.js';

const ClientComp = (props) => {
  const { account, index, scales } = props;
  const classes = useStyles();
  // const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  // console.log("getOperator: "+JSON.stringify(account)+" und "+JSON.stringify(operators));
  // const operator = getOperator(account, operators);  

  let rowClass = "";
  if (index % 2 === 0) {
    rowClass = classes.tableRowEven;
  } else {
    rowClass = classes.tableRowOdd;
  }
  // console.log("ClientComp");
  // console.log("Clients  scales: "+scales);

  return (
    <Grid
      className={classes.root}
      container
      spacing={1}
      direction="column">

      <Grid
        className={classNames(classes.root, classes.tableRow, rowClass)}
        container
        spacing={0}
        direction="row"
        justify="center"
        alignItems="center"        
        onClick={() => setOpen(!open)}>

        <Grid item xs={12} sm={5}>   
          <Typography variant="subtitle1" className={classes.gridtext}>
            {account.name}
          </Typography>
          <Typography variant="caption" className={classes.gridtext}>
            {account.uid}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5} >
            <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
              <Moment locale="de" utc fromNow>{ account.last_login }</Moment> 
            </Typography>
        </Grid>
        <Grid item xs={12} sm={1}>   
          { scales &&
            scales.length
          } 
        </Grid>

        <Grid item xs={12} sm={1}>   
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>  
        </Grid>        
      </Grid>
{ 
// VERSTECKTE ROW - mit einzelnen Chargern!
}
      <Grid item container xs={12}>
        <Grid item xs={12} container direction="column">
          <Collapse in={open} timeout="auto" unmountOnExit>  
            <Grid
              container
              spacing={1}
              direction="column">

              <Grid
                className={classNames(classes.root, classes.tableRow, rowClass)}
                container
                spacing={0}
                direction="row"
                justify="center"
                alignItems="center">

                <Grid item xs={12} sm={1}>   
                  <Typography variant="caption" className={classes.gridtext}>
                    ID
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={4}>   
                  <Typography variant="caption" className={classes.gridtext}>
                    Name
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={3} >
                    <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                      Signal
                    </Typography>
                </Grid>    
                <Grid item xs={12} sm={2} >
                    <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                      Firmware
                    </Typography>
                </Grid>       
                <Grid item xs={12} sm={2} >
                    <Typography color="textSecondary" variant="caption" className={classes.gridtext}>
                      
                    </Typography>
                </Grid>       
              </Grid>
            </Grid>


            { scales !== undefined && 
              // stableSort(accounts, getComparator(order, orderBy))
              // scales.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              scales.map((row, index) => {
                return (
                  <ScaleAdminComp
                    hover
                    index={index}
                    scale={row}
                    key={"scale"+row.id_scales}
                  />
                );
              })
            }
          </Collapse>
        </Grid>
      </Grid>
    </Grid>
  );  
}

const useStyles = makeStyles((theme) => ({
  root: {
    // width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
  },
  secondaryHeading: {
    fontSize: theme.typography.pxToRem(15),
    color: theme.palette.text.secondary,
  },
  icon: {
    verticalAlign: 'bottom',
    height: 18,
    width: 18,
    padding: theme.spacing(0, 0),
    lineHeight: 1,
  },
  iconMini: {
    verticalAlign: 'center',
    height: 16,
    width: 16,
    padding: theme.spacing(0, 0),
    lineHeight: 1,
  },
  formControl: {
    margin: theme.spacing(2, 0),
  },
  selectEmpty: {
    marginTop: theme.spacing(2, 0),
  },
  details: {
    alignItems: 'center',
  },
  buttonRow: {
    alignItems: 'center',
  },
  column: {
    flexBasis: '33.33%',
  },
  infoText: {
    padding: theme.spacing(0, 0),
    margin: theme.spacing(0, 0),
    lineHeight: 1,
    fontSize: theme.typography.pxToRem(11),
    color: theme.palette.text.secondary,
  },
  tableRow: {
    borderTop: `1px solid ${theme.palette.divider}`,
    // borderBottom: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 4),
    '&:hover': {
      backgroundColor: fade(teal[50], 0.3),
      cursor: "pointer"
    },
  },
  tableRowLast: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  tableRowEven: {
    // backgroundColor: lime[50],
    backgroundColor: fade(lime[50], 0.3),
  },
  tableRowOdd: {
    // backgroundColor: teal[50],
    // backgroundColor: fade(teal[50], 0.3),
  },
  helper: {
    borderLeft: `2px solid ${theme.palette.divider}`,
    padding: theme.spacing(2, 4),
  },
  gridtext: {
    padding: theme.spacing(0, 0),
    lineHeight: 1,
  },
  ok_color: {
    color: theme.palette.primary.main,
  },
  error_color: {
    color: theme.palette.warning.main,
  },
  image: {
    height: 52,
  },
  img: {
    margin: 'auto',
    display: 'block',
    maxWidth: '100%',
    maxHeight: '100%',
  },
  link: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
}));


export default (withRouter(ClientComp));