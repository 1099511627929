import {connect} from "react-redux";
import {withStyles} from "@material-ui/core/styles/index";
import React, { Component } from 'react';
import classNames from 'classnames';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import * as actions from "../store/actions/main";
import {withRouter} from "react-router-dom";
import {useSelector} from "react-redux";
import {  makeStyles, Typography } from '@material-ui/core';


const styles = theme => ({
   categoryHeader: {
      paddingTop: 16,
      paddingBottom: 16,
   },
   categoryHeaderPrimary: {
      color: theme.palette.common.white,
   },
   item: {
      paddingTop: 4,
      paddingBottom: 4,
      color: 'rgba(255, 255, 255, 0.9)',
   },
   itemCategory: {
      backgroundColor: '#232f3e',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: 16,
      paddingBottom: 16,
   },

   itemActionable: {
      '&:hover': {
         backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
   },
   itemActiveItem: {
      color: '#adea00', //4fc3f7',
   },
   itemPrimary: {
      color: 'inherit',
      fontSize: theme.typography.fontSize,
   },
   divider: {
      marginTop: theme.spacing(2),
   },
});

const useStyles = makeStyles((theme) => ({
   categoryHeader: {
      paddingTop: 16,
      paddingBottom: 16,
   },
   categoryHeaderPrimary: {
      color: theme.palette.common.white,
   },
   item: {
      // paddingTop: 4,
      // paddingBottom: 4,
      color: 'rgba(255, 255, 255, 0.9)',
   },
   itemCategory: {
      backgroundColor: '#232f3e',
      boxShadow: '0 -1px 0 #404854 inset',
      paddingTop: 16,
      paddingBottom: 16,
   },

   itemActionable: {
      '&:hover': {
         backgroundColor: 'rgba(255, 255, 255, 0.08)',
      },
   },
   itemActiveItem: {
      // paddingTop: 0,
      // paddingBottom: 0,
      color: '#adea00 !important', //4fc3f7',
   },
   itemPrimary: {
      // color: 'inherit',
      fontSize: theme.typography.fontSize,
   },
   divider: {
      marginTop: theme.spacing(2),
   },
 }));
// class NavListItem extends Component {
   //TODO - functional component 

const NavListItem = (props) => {
   const classes = useStyles();
   const { logout, onDrawerToggle} = props;
 
   const title = useSelector(state => state.main.title);
   const menu = useSelector(state => state.main.menu);

   const handleNavigation = () => {
      // console.log("NavListItem path: ");
      props.changeTitle(props.childId, props.menu);
      props.history.push(props.path);
   };

// render() {
   // const { classes } = this.props;
   // const { classes } = this.props;
   // console.log("NavListItem props: ", this.props);

   // console.log("classes: ", classes);

   return (
      <div onClick={handleNavigation}
            key={props.childId}>
         <ListItem
            button
            dense
            key={props.childId}
            className={classNames(
               classes.item,
               classes.itemActionable,
               (props.childId === title) && classes.itemActiveItem,
            )} >
            <ListItemIcon>{props.icon}</ListItemIcon>      
            {/* <Typography           
               gutterBottom variant="body2" 
               className={classNames(
                  classes.itemPrimary,
                  (props.childId === title) && classes.itemActiveItem
               )}>
               {props.childId}
            </Typography> */}

            { (props.childId === title) &&             
               <ListItemText
                  classes={{ primary: classes.itemActiveItem }} >
                  {props.childId}
               </ListItemText>
            }
            { (props.childId !== title) &&             
               <ListItemText
                  classes={{ primary: classes.item }} >
                  {props.childId}
               </ListItemText>
            }
         </ListItem>
      </div>

   );
   // }
}

const mapStateToProps = state => {
   return {
      title: state.main.title
   };
};

const mapDispatchToProps = dispatch => {
   return {
      changeTitle: (title, menu) => dispatch(actions.setTitleAndMenu(title, menu))
      // handleNavigation: (path) => dispatch(actions.setHelperElement(path))
   };
};


export default connect(mapStateToProps, mapDispatchToProps)(withRouter(withStyles(styles)(NavListItem)));
