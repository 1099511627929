import React, { Component } from 'react';
import {useSelector} from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import NotificationsIcon from '@material-ui/icons/Notifications';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PeopleIcon from '@material-ui/icons/People';
import {connect} from "react-redux";
import { Badge, makeStyles, fade, MenuItem, Menu } from '@material-ui/core';
import * as actions from "../store/actions/main";
import {logout} from "../store/actions";
import * as const_vars from "../Static/const_vars";
import EditDialog from "../Dialogs/EditDialog";
import LocationDialog from "../Dialogs/LocationDialog";
import MoreIcon from '@material-ui/icons/MoreVert';
import AccountCircle from '@material-ui/icons/AccountCircle';
import ColorSelect from '../Settings/ColorSelect';

const lightColor = 'rgba(255, 255, 255, 0.7)';

const options = [
  'Benutzerdaten',
  'Logout',
];

const ITEM_HEIGHT = 48;

const styles = theme => ({
  secondaryBar: {
    zIndex: 0,
  },
  menuButton: {
    marginLeft: -theme.spacing(2),
  },
  iconButtonAvatar: {
    padding: 4,
  },
  link: {
    textDecoration: 'none',
    color: lightColor,
    '&:hover': {
      color: theme.palette.common.white,
    },
  },
  button: {
    borderColor: lightColor,
  },
});

const useStyles = makeStyles((theme) => ({
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(4),
  },
  title: {
    // display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(4),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(6),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 4),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(2, 2, 2, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(8)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
}));


const Header = (props) => {
  const classes = useStyles();
  const { logout, onDrawerToggle} = props;

  const title = useSelector(state => state.main.title);
  const menu = useSelector(state => state.main.menu);
  const [open, setOpen] = React.useState(false);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleLanguageMenuOpen = (event) => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuLogout = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
    logout();
  };

  const handleClose = () => {
    setAnchorEl(null);
    console.log("close name dialog");
    // handleMobileMenuClose();
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };
  const handleMenuLanguageClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const isMenuOpen = Boolean(anchorEl);
  const isMenuLanguageOpen = Boolean(anchorElLanguage);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);

  const menuId = 'primary-search-account-menu';
  const menuIdLanguage = 'language-menu';


  //TODO alarme online beziehen
  const alarme = 0;

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={menuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>Profil</MenuItem>
      <MenuItem onClick={handleMenuLogout}>Ausloggen</MenuItem>
    </Menu>
  );

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose} >
       
      <MenuItem>
        <IconButton aria-label={"show "+alarme+" new notifications"} color="inherit">
          <Badge badgeContent={alarme} color="secondary">
            <NotificationsIcon />
          </Badge>
        </IconButton>
        <p>Nachrichten</p>
      </MenuItem>
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit" >
          <AccountCircle />
        </IconButton>
        <p>Profil</p>
      </MenuItem>
    </Menu>
  );

  return (
    <React.Fragment>
      <div >
        <AppBar 
          color="primary" 
          position="static" >
          <Toolbar>
            <Hidden smUp>
              <IconButton
                edge="start"
                className={classes.menuButton}
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Hidden>
          <Typography className={classes.title} variant="h6" noWrap>
            {title}
          </Typography>
          
          <ColorSelect color="appBar" name="Hintergrund">


          </ColorSelect>
          <div className={classes.grow} />
          <div className={classes.sectionDesktop}>
              { menu === const_vars.MENU_STATISTIC &&
              <Grid item>
                <EditDialog
                  open={open}
                  onClose={handleClose}
                />
              </Grid>
                // <IconButton 
                //   aria-label="show new notifications" 
                //   color="inherit"
                //   onClick={handleProfileMenuOpen}>
                //   <Badge badgeContent={alarme} color="secondary">
                //     <NotificationsIcon />
                //   </Badge>
                // </IconButton>
              }


            <IconButton aria-label="show new notifications" color="inherit">
              {/* <Badge badgeContent={warnungen} color="secondary"> */}
              <Badge badgeContent={alarme} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
      
            <IconButton
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              onClick={handleProfileMenuOpen}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
          </div>
          <div className={classes.sectionMobile}>
            { menu === const_vars.MENU_STATISTIC &&
            <Grid item>
              <EditDialog
                open={open}
                onClose={handleClose}
              />
            </Grid>
            }
            <IconButton
              aria-label="show more"
              aria-controls={mobileMenuId}
              aria-haspopup="true"
              onClick={handleMobileMenuOpen}
              color="inherit"
            >
              <MoreIcon />
            </IconButton>
          </div>
          </Toolbar>
        </AppBar>
              {renderMobileMenu}
              {renderMenu}
      </div>
    </React.Fragment>  
  )
}

// function Header(props) {
// class Header extends Component {

//   handleStandortClick = event => {
//     // this.props.setHelperElement(event);
//     console.log("Standort hinzu...");
//     this.setState({
//       open: true,
//     });
//   };

//   handleAvatarClick = event => {
//     this.props.setHelperElement(event);
//   };

//   handleAvatarClose = () => {
//     this.props.deleteHelperElement();
//   };

//   handleMenuClick = () => {
//     this.props.deleteHelperElement();
//     //UNd jetzt Logout durchführen
//     this.props.logout();
//   };

//   handleClose = () => {
//     // this.props.onClose(this.props.selectedValue);
//   };

//   render() {
//     const { classes } = this.props;
//     const anchorEl = this.props.helper_element;
//     const open = Boolean(anchorEl);

//     return (
//       <React.Fragment>
//         <AppBar
//           color="primary"
//           position="sticky"
//           elevation={2}>

//           <Toolbar>
//             <Grid container spacing={8} alignItems="center">
//               <Hidden smUp>
//                 <Grid item>
//                   <IconButton
//                     color="inherit"
//                     aria-label="Open drawer"
//                     onClick={this.props.onDrawerToggle}
//                     className={classes.menuButton}
//                   >
//                     <MenuIcon />
//                   </IconButton>
//                 </Grid>
//               </Hidden>

//               <Grid item xs>
//                 <Typography color="inherit" variant="h5">
//                   {this.props.title} {/*{this.props.menu}*/}
//                 </Typography>
//               </Grid>
//               <Grid item />

//               { this.props.menu === const_vars.MENU_APIARIES &&
//                 <Grid item>
//                   <LocationDialog
//                     open={this.open}
//                     onClose={this.handleClose}
//                   />
//                 </Grid>
//               }

//               { this.props.menu === const_vars.MENU_SCALE &&
//               <Grid item>
//                 <EditDialog
//                   open={this.open}
//                   onClose={this.handleClose}
//                 />
//               </Grid>
//               }


//               <Grid item>
//                 <Tooltip title="Alerts • No alters">
//                   <IconButton color="inherit">
//                     <NotificationsIcon />
//                   </IconButton>
//                 </Tooltip>
//               </Grid>

//               <Grid item>
//                 <IconButton
//                   color="inherit"
//                   className={classes.iconButtonAvatar}
//                   aria-label="More"
//                   aria-owns={open ? 'long-menu' : undefined}
//                   aria-haspopup="true"
//                   onClick={this.handleAvatarClick}
//                 >
//                   <Avatar className={classes.avatar}>
//                     <PeopleIcon />
//                   </Avatar>
//                 </IconButton>

//                 <Menu
//                   id="long-menu"
//                   anchorEl={anchorEl}
//                   open={open}
//                   onClose={this.handleAvatarClose}
//                   PaperProps={{
//                     style: {
//                       maxHeight: ITEM_HEIGHT * 4.5,
//                       width: 200,
//                     },
//                   }}
//                 >
//                   {options.map(option => (
//                     <MenuItem key={option} selected={option === 'asdfasdf'} onClick={this.handleMenuClick}>
//                       {option}
//                     </MenuItem>
//                   ))}
//                 </Menu>
//               </Grid>
//             </Grid>
//           </Toolbar>
//         </AppBar>

//       </React.Fragment>
//     )

//   };
// }

// Header.propTypes = {
//   classes: PropTypes.object.isRequired,
//   onDrawerToggle: PropTypes.func.isRequired,
// };

const mapStateToProps = state => {
  return {
    title: state.main.title,
    menu: state.main.menu,
    helper_element: state.main.helper_element,
    menu_open: state.main.menu_open
  };
};

const mapDispatchToProps = dispatch => {
  return {
    setHelperElement: (event) => dispatch(actions.setHelperElement(event.currentTarget)),
    deleteHelperElement: () => dispatch(actions.setHelperElement(null)),
    logout: () => dispatch(logout())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Header));


//
// class FormDialog extends React.Component {
//   state = {
//     open: false,
//   };
//
//   handleClickOpen = () => {
//     this.setState({ open: true });
//   };
//
//   handleClose = () => {
//     this.setState({ open: false });
//   };
//
//   render() {
//     return (
//       <div>
//         <Tooltip title="Standort hinzufügen">
//           <IconButton
//             color="inherit"
//             onClick={this.handleClickOpen}>
//             <AddIcon />
//           </IconButton>
//         </Tooltip>
//
//         <Dialog
//           open={this.state.open}
//           onClose={this.handleClose}
//           aria-labelledby="form-dialog-title"
//         >
//           <DialogTitle id="form-dialog-title">Neuer Standort</DialogTitle>
//           <DialogContent>
//             {/*<DialogContentText>*/}
//               {/*Neuen Standort erstellen.. nur dummy*/}
//             {/*</DialogContentText>*/}
//             <TextField
//               autoFocus
//               margin="dense"
//               id="name"
//               label="Name"
//               fullWidth
//             />
//           </DialogContent>
//           <DialogActions>
//             <Button onClick={this.handleClose} color="primary">
//               Abbrechen
//             </Button>
//             <Button onClick={this.handleClose} color="primary">
//               Erstellen
//             </Button>
//           </DialogActions>
//         </Dialog>
//       </div>
//     );
//   }
// }