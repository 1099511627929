import React,  { useEffect, Component } from 'react';
// import styles from './Scale.module.css';
import * as actions from "../../store/actions/main";
import {connect, useSelector, useDispatch} from "react-redux";
import Moment from "react-moment"; // Import css modules stylesheet as styles
import 'moment-timezone';
import 'moment/locale/de';
import {Paper, Typography} from "@material-ui/core/umd/material-ui.production.min";
import {withRouter} from "react-router-dom";
import {
  fetchScaleData, fetchScaleDataAll, fetchScaleDataSuccess, saveEvent,
  updateZoomState
} from "../../store/actions/scales";
import * as d3 from "d3";
import moment from "moment";
import {isMobile} from 'react-device-detect';

import {
  Bar,
  CartesianGrid, ComposedChart, LabelList, Line, LineChart,
  ReferenceArea, ReferenceDot, ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from "recharts";
import IconContainer from "../../components/iconContainer/IconContainer";
import * as const_vars from "../../Static/const_vars";
import Grid from "@material-ui/core/es/Grid/Grid";
import Chip from "@material-ui/core/es/Chip/Chip";
import Button from "@material-ui/core/es/Button/Button";
import LinearProgress from "@material-ui/core/es/LinearProgress/LinearProgress";
import { Menu, Item, MenuProvider, Separator } from 'react-contexify';
// import { Menu, Item, Separator, Submenu, MenuProvider } from 'react-contexify';
// import 'react-contexify/dist/ReactContexify.min.css';
import EventDialog from "../../Dialogs/EventDialog";
// const onClick = ({ event, props }) => console.log(event,props);

const styles = theme => ({
  categoryHeader: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  categoryHeaderPrimary: {
    color: theme.palette.common.white,
  },
  
  item: {
    paddingTop: 4,
    paddingBottom: 4,
    color: 'rgba(255, 255, 255, 0.9)',
  },
  logo: {
    width: 36,
    marginRight: 16,
  },
  itemCategory: {
    backgroundColor: '#232f3e',
    boxShadow: '0 -1px 0 #404854 inset',
    paddingTop: 16,
    paddingBottom: 16,
  },
  firebase: {
    fontSize: 24,
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.common.white,
  },
  logoContainer: {
    height: 48,
    // paddingLeft: 60
  },
  itemActionable: {
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  itemActiveItem: {
    color: '#adea00', //adea00',
  },
  itemPrimary: {
    color: 'inherit',
    fontSize: theme.typography.fontSize,
  },
  divider: {
    marginTop: theme.spacing(2),
  },
  info_charge: {
    position: 'relative'
  }
});

//https://github.com/fkhadra/react-contexify
const MyAwesomeMenu = () => (
  <Menu id='menu_id'>
    {/* <Item onClick={() = {
      console.log("menu click");
    }}>Ereignis</Item> */}

    {/*<Item onClick={onClick}>Ipsum</Item>*/}
    <Separator />
    {/*<Item disabled>Dolor</Item>*/}
    {/*<Separator />*/}
    {/*<Submenu label="Foobar">*/}
      {/*<Item onClick={onClick}>Foo</Item>*/}
      {/*<Item onClick={onClick}>Bar</Item>*/}
    {/*</Submenu>*/}
  </Menu>
);


export class CustomizedDot extends React.Component {
  render () {
    const {cx, cy, value} = this.props;
    // console.log(this.props);

    if (value > 2500) {
      return (
        <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="red" viewBox="0 0 1024 1024">
          <path d="M512 1009.984c-274.912 0-497.76-222.848-497.76-497.76s222.848-497.76 497.76-497.76c274.912 0 497.76 222.848 497.76 497.76s-222.848 497.76-497.76 497.76zM340.768 295.936c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM686.176 296.704c-39.488 0-71.52 32.8-71.52 73.248s32.032 73.248 71.52 73.248c39.488 0 71.52-32.8 71.52-73.248s-32.032-73.248-71.52-73.248zM772.928 555.392c-18.752-8.864-40.928-0.576-49.632 18.528-40.224 88.576-120.256 143.552-208.832 143.552-85.952 0-164.864-52.64-205.952-137.376-9.184-18.912-31.648-26.592-50.08-17.28-18.464 9.408-21.216 21.472-15.936 32.64 52.8 111.424 155.232 186.784 269.76 186.784 117.984 0 217.12-70.944 269.76-186.784 8.672-19.136 9.568-31.2-9.12-40.096z"/>
        </svg>
      );
    }

    return (
      <svg x={cx - 10} y={cy - 10} width={20} height={20} fill="green" viewBox="0 0 1024 1024">
        <path d="M517.12 53.248q95.232 0 179.2 36.352t145.92 98.304 98.304 145.92 36.352 179.2-36.352 179.2-98.304 145.92-145.92 98.304-179.2 36.352-179.2-36.352-145.92-98.304-98.304-145.92-36.352-179.2 36.352-179.2 98.304-145.92 145.92-98.304 179.2-36.352zM663.552 261.12q-15.36 0-28.16 6.656t-23.04 18.432-15.872 27.648-5.632 33.28q0 35.84 21.504 61.44t51.2 25.6 51.2-25.6 21.504-61.44q0-17.408-5.632-33.28t-15.872-27.648-23.04-18.432-28.16-6.656zM373.76 261.12q-29.696 0-50.688 25.088t-20.992 60.928 20.992 61.44 50.688 25.6 50.176-25.6 20.48-61.44-20.48-60.928-50.176-25.088zM520.192 602.112q-51.2 0-97.28 9.728t-82.944 27.648-62.464 41.472-35.84 51.2q-1.024 1.024-1.024 2.048-1.024 3.072-1.024 8.704t2.56 11.776 7.168 11.264 12.8 6.144q25.6-27.648 62.464-50.176 31.744-19.456 79.36-35.328t114.176-15.872q67.584 0 116.736 15.872t81.92 35.328q37.888 22.528 63.488 50.176 17.408-5.12 19.968-18.944t0.512-18.944-3.072-7.168-1.024-3.072q-26.624-55.296-100.352-88.576t-176.128-33.28z"/>
      </svg>
    );
  }
}

// eslint-disable-next-line
let isLoaded;
// eslint-disable-next-line
let isLoading;
let hasAllData = false;

const offsetWeight = 0.5;

const ScaleDetail = (props) => {

// class ScaleDetail extends Component {
  const dispatch = useDispatch();
  const token = useSelector(state => state.auth.token);
  const scale = useSelector(state => state.main.currentScale);

  const zoomState = useSelector(state => state.main.zoomState);
  const [loading, setLoading] = React.useState(true);
  const [event_open, setEventOpen] = React.useState(false);
  const [event, setEvent] = React.useState({});

  const scaledata = useSelector(state => state.main.scaledata);



  const showTimes = false;
  let dataArrays = [];
  let sortedArrays = [];
  let gateways = [];
  // let scales = [];
  let weightArrays = [];
  let myDiv1 = null;
  let width = 0;
  const margin={ 
    top: 8, 
    right: 8, 
    left: 8, 
    bottom: 8 }
  let heightZoom = 0;
  // console.log("scale: ", scale);
  // console.log(props.history);

  const colorXAxis = "#fff";
  const colorHumidity = "#9C27B0";
  const colorTemperature = "#0D47A1";
  const colorWeight = "#B71C1C";
  const colorVoltage = "#fff";

  
  const strokeWidthLines = 1;

  // const [eventOpen, setEventOpen] = React.useState(false);
  let time_start = 0;
  let time_last = 0;

  let max_temp = 0;
  let min_temp = 0;
  let max_hum = 0;
  let min_hum = 0;
  let max_time = 0;
  let min_time = 0;
  let max_scale = 0;
  let min_scale = 0;
  let max_diff = 0;
  let min_diff = 0;
  let max_voltage = 0;
  let min_voltage = 0;
  let max_pressure = 0;
  let min_pressure = 0;

  let timeFormat = 'DD.MM.YYYY HH:mm';
  let dateParser = d3.timeParse('%d.%m.%Y %H:%M');
  let heightUpper = 0;
  let heightLower = 0;
  let heightUpperLower = 0;
  let svg;

  let year = 60*1000*60*24*365;
  let month = 60*1000*60*24*31;
  let week = 60*1000*60*24*7;
  let day = 60*1000*60*24;
  let half_day = 60*1000*60*12;
  let hour = 60*1000*60;

  let yWeight;
  let y2Weight;
  let yDiff;
  let xScale;

  // constructor(props) {
  //   super(props);


  //   console.log("id scale: " +scale.id_scale);


  //   isLoaded = false;
  //   isLoading = false;
  //   hasAllData = false;

  //   this.full_width = 0;
  //   this.myChart = null;

  //   this.setMyChartRef = element => {
  //     // console.error("setMyChartRef element ", element);
  //     this.myChart = element;
  //   };

  //   this.myRef = React.createRef();  //Chart div
  //   //TODO - Daten nur löschen falls andere ID
  //   this.props.deleteScaleData();
  //   // console.log("constructor ScaleDetail ", props);
  // }

  let myChart = null;
  let full_width = 0;
  let uuid = scale != undefined ? scale.uuid : "";

  const setMyChartRef = element => {
    // console.error("setMyChartRef element ", element);
    myChart = element;
  };

  useEffect( () => {    


    console.log("id scale: ", scale.id_scales);

    isLoaded = false;
    isLoading = false;
    hasAllData = false;

    // full_width = 0;
    // myRef = React.createRef();  //Chart div
    //TODO - Daten nur löschen falls andere ID
    // this.props.deleteScaleData();

    // this.props.onFetchScaleData(token, scale.id_scale);
    dispatch( fetchScaleData(token, scale.id_scales) );

    //Zoom-Week ausführen, sobald Daten angekommen sind!!
    //this.zoomWeek();
    // console.log("componentDidMount ref1: ",this.myChart);
    // console.log("componentDidMount ref2: ",this.myRef);
    // if (this.myRef.current != null) {
    //   console.log("mount ref2: ", this.myRef);
    //   full_width = this.myRef.current.container.clientWidth - 136;
    // }
  }, [scale.id_scales, token, dispatch]);

  // componentDidUpdate() {
  //   // this.updateChart()
  //   if (this.myRef.current != null) {
  //     // console.log("cupdate ref2: ", this.myRef);
  //     this.full_width = this.myRef.current.container.clientWidth - 136;
  //   }
  // }

  //Zahlenreihe hinzufügen und eingliedern!
  const AddDataPoints = (dataPoints, calculate) => {
    showTime("AddDataPoints");
    dataArrays.push(dataPoints);
    //Achtung -> verschiedene Gateways als eigene Wetteraufzeichnung!
    if(calculate) {
      showTime("prepareData start");
      prepareData();
      // showTime("drawChart start");
      // drawChart();
    }
  }

  //Zahlenreihe hinzufügen und eingliedern!
  const AddWeightPoints = (weightPoints, calculate) => {
    showTime("AddWeightPoints");
    weightArrays.push(weightPoints);
    if(calculate) {
      showTime("prepareData start w");
      prepareData();
      // showTime("drawChart start w");
      // drawChart();
    }
  }
  /*
  ARRAYDATEN VORBEREITEN
   */
  const prepareData = () => {
    showTime("prepareData 0");

    // return;
    // sortedArrays = [];
    // this.sortedWeightArrays = [];
    // this.gateways = [];
    // this.scales = [];
    // {id_weight: 53945, id_scale: 1015, change_time: "2019-04-01 06:39:15", scale: 9223779, weight: 23.342}
    dataArrays = scaledata;
    const self = this;

    console.log("dataArrays: ", dataArrays);
    showTime("prepareData 1");


    // change_time: "2019-04-01 06:39:15"
    // hum: 0
    // id_scale: 1015
    // id_weight: 53945
    // p: 0
    // scale: 9223779
    // temp: 0
    // temp2: 0
    // vc: 0
    // vc_p: 0
    // weight: 23.342


    //Diff muss ausgerechnet werden!
    let last_weight = 0;
    let diff = 0;

    sortedArrays = scaledata.map(function (dataPoint, index) {
      // let d3Array = this.weight_array.map(function (weight) {

      // eslint-disable-next-line
      if (index == 0) {
        diff = 0;
      } else {
        diff = dataPoint.weight - last_weight;
      }
      last_weight = dataPoint.weight;

      return {
        id_gw: dataPoint.id_gw,
        temperature: dataPoint.temp,
        humidity: dataPoint.hum,
        pressure: dataPoint.p,
        scale: dataPoint.weight,
        weight: dataPoint.weight,
        scale_val: dataPoint.scale,
        diff: diff,
        temp_scale: dataPoint.temp2,
        time: dataPoint.change_time,
        voltage: dataPoint.vc,
        date: moment.utc(dataPoint.change_time).format(self.timeFormat), //moment(dataPoint.time).valueOf()/1000,
        voltage_percent: dataPoint.vc_p,
        signal_time: moment.utc(dataPoint.change_time).valueOf(), //= milliseconds
        time_string: moment.utc(dataPoint.change_time).format(self.timeFormat),
      };
    });

    sortedArrays = sortedArrays.sort(function (a, b) {
      return a.signal_time - b.signal_time;
    });

    // console.log('sortedArrays: '+JSON.stringify(sortedArrays));


    // let array_index = 0;
    // for(i = 0; i < this.dataArrays.length; i++){
    //   //Für jedes Gateway ein eigenes Array hinzufügen!
    //   var dataByGwId = d3.nest()
    //     .key(function(dataPoint) { return dataPoint.id_scale; })
    //     .entries(this.dataArrays[i]);
    //
    //   //TODO - Wetterwerte fehlen noch
    //   for(x = 0; x < dataByGwId.length; x++) {
    //     sortedArrays.push([]);
    //
    //     // let gw = new Gateway();
    //     // gw.id_api = dataByGwId[x].values[0].id_api;
    //     // gw.id_gw = dataByGwId[x].values[0].id_gw;
    //     // gw.device = ""
    //     // this.gateways.push(gw);
    //
    //     // console.log('data.desc: ', data.description);
    //
    //     sortedArrays[array_index] = dataByGwId[x].values.map(function (dataPoint) {
    //       // let d3Array = this.weight_array.map(function (weight) {
    //       return {
    //         // id_gw: dataPoint.id_gw,
    //         // temperature: dataPoint.temp,
    //         // humidity: dataPoint.hum,
    //         // pressure: dataPoint.press,
    //         // light: dataPoint.light,
    //         scale: dataPoint.scale,
    //         // temp_scale: dataPoint.temp_scale,
    //         time: dataPoint.change_time,
    //         // voltage: dataPoint.vc,
    //         date: Moment(dataPoint.change_time).format(self.timeFormat), //moment(dataPoint.time).valueOf()/1000,
    //         // voltage_percent: dataPoint.vc_p,
    //         signal_time: Moment(dataPoint.change_time).valueOf(), //= milliseconds
    //         time_string: Moment(dataPoint.change_time).format(self.timeFormat),
    //       };
    //     });
    //
    //     // console.log('d3Array: '+JSON.stringify(this.d3Array));
    //     sortedArrays[array_index] = sortedArrays[array_index].sort(function (a, b) {
    //       return a.signal_time - b.signal_time;
    //     });
    //     array_index++;
    //   }
    // }

    showTime("prepareData 2");

    //TODO - müssten warten bis Gewichtsdaten hier sind, oder nachträglich hinzufügen sobald verfügbar!
    // for(let i = 0; i < this.restService.scales.length; i++) {
    //   if (this.restService.scales[i].enabled == true) {
    //     sortedArrays.push(this.restService.scales[i].calc_data.sorted_weight);
    //   }
    // }

    showTime("prepareData 3");
    calcMaxMin();  //schnell!
    showTime("prepareData fertig");
  }

  const calcMaxMin = () => {
    // this.max_time = d3.max(sortedArrays, function(array) {
    //   return d3.max(array, function (d) {
    //     return d.signal_time;
    //   });
    // });
    max_time = d3.max(sortedArrays, function (d) {
        return d.signal_time;
      });
    min_time = d3.min(sortedArrays, function (d) {
      return d.signal_time;
    });

    // let max_time = d3.max(sortedArrays, function (d) {
    //   return d.signal_time;
    // });

    // let min_time = d3.min(sortedArrays, function (d) {
    //   return d.signal_time;
    // });

    // if (max_time > this.max_time) {
    //   this.max_time = max_time;
    // }

    // if (min_time < this.min_time) {
    //   this.min_time = min_time;
    // }

    max_temp = d3.max(sortedArrays, function (d) {
      return d.temperature;
    });
    min_temp = d3.min(sortedArrays, function (d) {
      return d.temperature;
    });
    max_hum = d3.max(sortedArrays, function (d) {
      return d.humidity;
    });
    min_hum = d3.min(sortedArrays, function (d) {
      return d.humidity;
    });
    max_scale =d3.max(sortedArrays, function (d) {
      return (d.weight);
    });
    min_scale = d3.min(sortedArrays, function (d) {
      return (d.weight);
    });
    max_diff = d3.max(sortedArrays, function (d) {
      // console.log("d.weight: "+d.weight);
      return (d.diff);
    });
    min_diff = d3.min(sortedArrays, function (d) {
      return (d.diff);
    });
    max_pressure = d3.max(sortedArrays, function (d) {
      return d.pressure +100;
    });
    min_pressure = d3.min(sortedArrays, function (d) {
      return d.pressure -100;
    });
    max_voltage = d3.max(sortedArrays, function (d) {
      return d.voltage;
    });
    min_voltage = d3.min(sortedArrays, function (d) {
        return d.voltage;
    });

    max_diff = Math.round(max_diff*100)/100;
    min_diff = Math.round(min_diff*100)/100;
    max_scale = Math.round(max_scale*100)/100;
    min_scale = Math.round(min_scale*100)/100;


    // console.error("min_scale 1: "+this.min_scale+ " max_scale: "+this.max_scale);

    // if (this.max_scale <= 0) {
    //   this.max_scale = Math.abs(this.min_scale/3.0);
    // }
    // if (this.min_scale >= 0) {
    //   this.min_scale = -Math.abs(this.max_scale/3.0);
    // }
    // console.error("min_scale 2: "+this.min_scale+ " max_scale: "+this.max_scale);


    if (max_diff <= 0) {
      max_diff = Math.abs(min_diff/3.0);
    }
    if (min_diff >= 0) {
      min_diff = -Math.abs(max_diff/3.0);
    }
    //TODO - diff und scale müssen aneinander angepasst werden! 0 bei beiden gleich!

    if (Math.abs(max_diff) > Math.abs(min_diff)) {
      // let scaleMinus = this.max_diff / this.min_diff ;
      // console.error("scaleMinus 1: "+scaleMinus+ " max: "+this.max_diff+" min: "+this.min_diff);
    } else {
      // let scaleMinus = Math.abs(this.min_diff / this.max_diff);
      // console.error("scaleMinus 2: "+scaleMinus+ " max: "+this.max_diff+" min: "+this.min_diff);
    }

    max_pressure = Math.round(max_pressure*100)/100;
    min_pressure = Math.round(min_pressure);
    max_voltage = Math.round(max_voltage*100)/100;
    min_voltage = Math.round(min_voltage*100)/100;
  }

  const startTime = () => {
    time_start = performance.now();
    time_last = time_start;
  }


  const showTime = (message) => {
    if (showTimes) {
      console.log(message+": " + (performance.now() - time_last).toFixed(0));
    }
    // + " ms ges: " +
    // ""+ (performance.now() - this.time_start) + " ms");
    time_last = performance.now();
  }


  const updateChart = () => {

    if (scaledata != null) {
      if (scaledata.length === 0) {
        return;
      }
    } else {
      // return;
    }

    //TODO - data nicht immer machen - einmal zu Beginn müsste reichen!
    prepareData();

    // return;
    let full_width = myDiv1.current.offsetWidth -24;//document.getElementById('chart'+this.uuid).clientWidth;
    let full_height =full_width * 0.5;

    width = full_width - margin.left - margin.right;

    if (full_width < 550) {
      //Phone
      full_height = 400;
      heightZoom = 50;
      let tempHeight = (full_height-heightZoom) - margin.top - margin.bottom - this.margin.middle;
      heightUpper = 0; //tempHeight / 4;  //TODO - wieder gleich groß machen
      heightLower = tempHeight - heightUpper;
      heightUpperLower = heightUpper + heightLower +margin.middle;
    } else {
      let tempHeight = (full_height-heightZoom) - margin.top - margin.bottom - this.margin.middle;
      heightUpper = tempHeight / 4;  //TODO - wieder gleich groß machen
      heightLower = tempHeight - heightUpper;
      heightUpperLower = heightUpper + heightLower +margin.middle;
    }


    let scaleDiff = max_scale -min_scale;
    let diffDiff = max_diff -min_diff;

    // console.log("xScale min: "+this.chartData.min_time+" max: "+this.chartData.max_time+" width: "+this.width);
    d3.scaleTime()
      .domain([min_time, max_time])
      .rangeRound([0, width]);

    d3.timeFormatDefaultLocale({
      dateTime: '%A, der %e. %B %Y, %X',
      date: '%d.%m.%Y',
      time: '%H:%M:%S',
      periods: ['AM', 'PM'], // unused
      days: ['Sonntag', 'Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag'],
      shortDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
      months: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
      shortMonths: ['Jan', 'Feb', 'Mrz', 'Apr', 'Mai', 'Jun', 'Jul', 'Aug', 'Sep', 'Okt', 'Nov', 'Dez']
    });

    xScale = d3.scaleTime().range([0, +width]);
    // this.xScaleBand = d3.scaleBand().range([0, +this.width]);

    var xLower = d3.scaleTime().range([0, +width]),
      yUpper = d3.scaleLinear().range([+heightUpper, 0]),
      yLower = d3.scaleLinear().range([+heightLower, 0]);

    yWeight = d3.scaleLinear()
      .domain([min_scale -(scaleDiff/20), max_scale +(scaleDiff/20)])
      .range([heightLower, 0]);

    yDiff = d3.scaleLinear()
      .domain([min_diff-(diffDiff/20), max_diff +(diffDiff/20)])
      .range([heightLower, 0]);

    y2Weight = d3.scaleLinear()
      .domain([min_scale, max_scale])
      .range([heightZoom, 0]);

    let tempDiff = max_temp - min_temp;

    xScale.domain([min_time, max_time]);
    yUpper.domain([min_temp -(tempDiff/10), max_temp +(tempDiff/10)]);
    yLower.domain([min_scale -(scaleDiff/10), max_scale +(scaleDiff/10)]);
    xLower.domain([min_time, max_time]);
  }


  const addLine = (line_class, line_class2, index, position, typ,
          lineColor, dFunction, dFunction2,
          focuslineGroupsLocal, contextlineGroupsLocal, 
          showPoints, addSmall) => {

    // console.log("lineColor: ", lineColor, " dFunction: ", dFunction, " dFunction2: ", dFunction2
    //   , " position: ", position, " typ: ", typ);

    const lineinfo = new LineInfo();
    lineinfo.index = index;
    lineinfo.position = position;
    lineinfo.typ = typ;
    lineinfo.color = lineColor;
    lineinfo.name = "line"+index;
    lineinfo.line = focuslineGroupsLocal.append("path")
      .classed(line_class+" line",true)
      // .attr("class",line_class)
      .attr("d", dFunction)
      .style('fill', 'none')
      .style('stroke', lineColor)
      .style('stroke-width', '1.5px')
      .attr("clip-path", "url(#clip)");

    // lineInfos.push(lineinfo);

    if (addSmall) {
      // console.log("weight_calc_small: "+dFunction2);

      contextlineGroupsLocal.append("path")
        .attr("class",line_class2)
        .attr("d", dFunction2)
        .style('fill', 'none')
        .style('stroke', lineColor)
        .style('stroke-width', '1.5px')
        .attr("clip-path", "url(#clip)");
    }
    //Punkte hum
    if (showPoints) {
      // const circlesSelection =
      //   chart.selectAll('circle')
      //     .data(data);
      // const circles = circlesSelection.enter().append('ellipse');
      // circles.data();
      // circles
      //   .attr('fill', '#333333')
      //   .attr('rx', '1px')
      //   .attr('ry', '1px')
      //   .attr('cx', xFunction)
      //   .attr('cy', yFunction);



      // .attr('cx', function (d) {
      //   return self.xScale(self.dateParser(d.date));
      // })
      // .attr('cy', function (d) {
      //   return self.yScaleHum(d.humidity);
      // });
    }
    return index;
  }

  const zoom = () => {
    // let { refAreaLeft, refAreaRight, animation } = zoomState;

    // if ( refAreaLeft === refAreaRight || refAreaRight === '' ) {
    //   this.setState( () => ({
    //     refAreaLeft : '',
    //     refAreaRight : ''
    //   }) );
    //   return;
    // }

    // // xAxis domain
    // if ( refAreaLeft > refAreaRight )
    //   [ refAreaLeft, refAreaRight ] = [ refAreaRight, refAreaLeft ];

    // this.props.updateZoomState ({
    //   left : refAreaLeft,
    //   right : refAreaRight,
    //   zoomDays: 7,
    //   zoomNumber: 4,
    //   refAreaLeft : '',
    //   refAreaRight : '',
    //   animation : animation
    // }) ;
  };

  const zoomWeek = () => {
    console.log("zoomWeek");
    const now = new Date();
    const left = now.getTime()-week;
    const right = now.getTime();

    const filtered = scaledata.filter(function (dataPoint) {
      return ((dataPoint.signal_time <= right) && (dataPoint.signal_time >= left));
    });
    let topErtrag = Math.round(d3.max(filtered, function (d) {
      return d.ertrag;
    })) + offsetWeight;
    let bottomErtrag = Math.floor(d3.min(filtered, function (d) {
      return d.ertrag;
    })) - offsetWeight;
    let topGewicht = Math.round(d3.max(filtered, function (d) {
      return d.weight;
    })) + offsetWeight;
    let bottomGewicht = Math.floor(d3.min(filtered, function (d) {
      return d.weight;
    })) - offsetWeight;

    //TODO wieder aktivieren 2021
    // this.props.updateZoomState({
    //   refAreaLeft: '',
    //   refAreaRight: '',
    //   zoomDays: 7,
    //   zoomNumber: 4,
    //   left: left,
    //   right: right,
    //   topErtrag: topErtrag,
    //   bottomErtrag: bottomErtrag,
    //   topGewicht: topGewicht,
    //   bottomGewicht: bottomGewicht,
    //   animation: true
    // });
  };

  const zoomOut = () => {
    console.log("zoom out");

    //TODO wieder aktivieren 2021
    // this.props.updateZoomState ({
    //   refAreaLeft : '',
    //   refAreaRight : '',
    //   zoomDays: 7,
    //   zoomNumber: 4,
    //   left : 'dataMin',
    //   right : 'dataMax',
    //   animation : true
    // }) ;
  }

  const handleScaleClick = () => {

    // this.props.history.push('/');
    // history.push('/my-new-location')
    // this.props.dispatch(actions.setMainTitle(scale.name));
  };

  const handleBackClick = () => {
    
    // this.props.history.push('/');
    props.history.push('/')
    // console.log(props.history);
    //TODO wieder aktivieren 2021
    // history.push('/');

    // this.props.dispatch(actions.setMainTitle(scale.name));
  };

  const formatXAxis = (tickItem) => {
    // If using moment.js
    return moment.utc(tickItem.signal_time).format('DD.MM.YYYY HH:mm');
    // return moment(tickItem).format('MMM Do YY')
  }

  // handleClick(number) {

  const handleClick = (number) => {
    // console.log("click props: "+JSON.stringify(this.props));
    isLoading = true;
    const now = new Date();
    let left = now.getTime()-week;
    const right = now.getTime();
    let zoomDays = 7;
    let zoomNumber = number;
    let needAllData = false;

    switch (number) {
      case 1:
        //6 Stunden
        zoomDays = 0;
        left = now.getTime()-hour*6;
        break;
      case 2:
        //24 Stunden
        zoomDays = 1;
        left = now.getTime()-day*1;
        break;
      case 3:
        //3 Tage
        zoomDays = 3;
        left = now.getTime()-day*3;
        break;
      case 4:
        //1 Woche = Standard
        zoomDays = 7;
        left = now.getTime()-week;
        break;
      case 5:
        //2 Wochen
        zoomDays = 14;
        left = now.getTime()-2*week;
        needAllData = true;
        break;
      case 6:
        //4 Wochen
        zoomDays = 28;
        left = now.getTime()-4*week;
        needAllData = true;
        break;
      case 7:
        //2 Monate Wochen ~ 9 Wochen
        zoomDays = 63;
        left = now.getTime()-9*week;
        needAllData = true;
        break;

      case 8:
        //Alles
        zoomDays = 365;

        left = d3.min(scaledata, function (d) {
          return d.signal_time;
        });
        needAllData = true;
        break;

      default:
        zoomDays = 7;
        left = now.getTime()-week;
        break;
    }


    if (needAllData) {

      // eslint-disable-next-line
      if (hasAllData == false) {
        hasAllData = true;

// 
    //TODO wieder aktivieren 2021
        // this.props.onFetchScaleDataAll(token, scale.id_scale);
      }
    }

    //topErtrag, bottomErtrag berechnen
    //topGewicht, bottomGewicht berechnen

    const filtered = scaledata.filter(function (dataPoint) {
      return ((dataPoint.signal_time <= right) && (dataPoint.signal_time >= left));
    });
    // console.log("filtered: "+JSON.stringify(filtered));
    let topErtrag = Math.round(d3.max(filtered, function (d) {
      return d.ertrag;
    })) + offsetWeight;
    let bottomErtrag = Math.floor(d3.min(filtered, function (d) {
      return d.ertrag;
    })) - offsetWeight;
    let topGewicht = Math.round(d3.max(filtered, function (d) {
      return d.weight;
    })) + offsetWeight;
    let bottomGewicht = Math.floor(d3.min(filtered, function (d) {
      return d.weight;
    })) - offsetWeight;


    //TODO - wieder aktivieren 2021
    // this.props.updateZoomState({
    //   refAreaLeft: '',
    //   refAreaRight: '',
    //   zoomDays: zoomDays,
    //   zoomNumber: zoomNumber,
    //   left: left,
    //   right: right,
    //   topErtrag: topErtrag,
    //   bottomErtrag: bottomErtrag,
    //   topGewicht: topGewicht,
    //   bottomGewicht: bottomGewicht,
    //   animation: true
    // });
  }

  const zoom1 = () => {
    console.log("zoom1");
    handleClick(1);
  };


  const customTooltipMain = (e) => {

    if (e.active && e.payload!=null && e.payload[0]!=null) {

      return (
        <Paper className="custom-tooltip">

          <Grid item xs container direction="column" justify="flex-start">

            <Grid item container direction="column" justify="flex-start" alignItems="center">
              <Grid item>
                <Typography variant="body2" align="center">{moment(e.payload[0].payload["signal_time"]).format('DD. MMM HH:mm')}</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" justify="flex-start" alignItems="center">
              <Grid item>
                <IconContainer icon={const_vars.ICON_WEIGHT} />
              </Grid>
              <Grid item>
                <Typography variant="body2" align="center">Ertrag:&nbsp;</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" align="center">{Number.parseFloat( e.payload[0].payload["ertrag"] ).toFixed( 2 )}&nbsp;kg</Typography>
              </Grid>
            </Grid>

            <Grid item container direction="row" justify="flex-start" alignItems="center">
              <Grid item>
                <IconContainer icon={const_vars.ICON_WEIGHT} />
              </Grid>
              <Grid item>
                <Typography variant="body2" align="center">Gewicht:&nbsp;</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" align="center">{Number.parseFloat( e.payload[0].payload["weight"] ).toFixed( 2 )}&nbsp;kg</Typography>
              </Grid>
            </Grid>

            <Grid item container direction="row" justify="center" alignItems="center">
              <Grid item>
                <IconContainer icon={const_vars.ICON_VOLTAGE} />
              </Grid>
              <Grid item>
                <Typography variant="body2" align="center">Spannung:&nbsp;</Typography>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="subtitle1" align="center">{Number.parseFloat( e.payload[0].payload["voltage"] ).toFixed( 2 )}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="caption" align="center">&nbsp;V&nbsp;&nbsp;&nbsp;</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      );
    }
    else{
      return "";
    }
  }


  const customTooltipOnYourLine = (e) => {
    // console.log(e.payload)

    if (e.active && e.payload!=null && e.payload[0]!=null) {

      return (
        <Paper className="custom-tooltip">

          <Grid item xs container direction="column" justify="flex-start">
            <Grid item>
            </Grid>
            <Grid item container direction="row" justify="flex-start" alignItems="center">
              <Grid item>
                <IconContainer icon={const_vars.ICON_TEMPERATURE} />
              </Grid>

              <Grid item>
                <Typography variant="body2" align="center">Temperatur:&nbsp;</Typography>
              </Grid>
              <Grid item>
                <Typography variant="subtitle1" align="center">{Number.parseFloat( e.payload[0].payload["temperature"] ).toFixed( 2 )}&nbsp;°C</Typography>
              </Grid>
            </Grid>
            <Grid item container direction="row" justify="center" alignItems="center">
              <Grid item>
                <IconContainer icon={const_vars.ICON_HUMIDITY} />
              </Grid>
              <Grid item>
                <Typography variant="body2" align="center">Luftfeuchte:&nbsp;</Typography>
              </Grid>
              <Grid item>
                <Grid item>
                  <Typography variant="subtitle1" align="center">{Number.parseFloat( e.payload[0].payload["humidity"] ).toFixed( 2 )}</Typography>
                </Grid>
              </Grid>
              <Grid item>
                <Typography variant="caption" align="center">&nbsp;%&nbsp;&nbsp;&nbsp;</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      );
    }
    else{
      return "";
    }
  }

 const  tickFormatFunction = (msTime) => {
    // if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < self.hour) {
    //   //console.log("1: "+(x.invert(self.width)-x.invert(0))+" h: "+self.hour)
    //   return d3.timeFormat('%H:%M')(d);
    // } else if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < self.half_day) {
    //   //console.log("2: "+(x.invert(self.width)-x.invert(0))+" h: "+self.hour)
    //   return d3.timeFormat('%H:%M')(d);
    // } else if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < (2*self.day)) {
    //   //console.log("3: "+(x.invert(self.width)-x.invert(0))+" h: "+self.hour)
    //   if ((d.getHours() === 0) ) {
    //     return d3.timeFormat('%a %d.%B')(d);
    //   } else {
    //     return d3.timeFormat('%H:%M')(d);
    //   }
    // } else if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < self.week) {
    //   //console.log("4: "+(x.invert(self.width)-x.invert(0))+" h: "+self.hour)
    //   return d3.timeFormat('%a %d.%B')(d);
    // } else if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < self.month) {
    //   //console.log("5: "+(x.invert(self.width)-x.invert(0))+" h: "+self.hour)
    //   return d3.timeFormat('%a %d.%B')(d);
    // } else if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < self.year) {
    //   return d3.timeFormat('%a %d.%B')(d);
    // } else {
    //   return d3.timeFormat('%b %Y')(msTime);
    // }
    // return d3.timeFormat('%a %d.%B')(msTime);
    //TODO - minTime und maxTime die aktuell angezeigt werden
    //verwenden für Bestimmung
    //TODO um 00:00Uhr DD. MM zeigen, sonst h:mm
    // const hours = Math.floor((msTime / (1000 * 60 * 60)) % 24);
    // console.log("msTime: "+msTime.getHours());

    if (msTime instanceof Date) {
      // console.log("msTime: "+msTime);

      // eslint-disable-next-line
      if (msTime.getHours() == 0) {
        return moment(msTime).format('DD. MMM');
      }
      return moment(msTime).format('HH:mm');
    } else {
      return moment(msTime).format('HH:mm');
    }
    // return moment(msTime).format('DD. MMM');
    // return moment(msTime).format('HH:mm');
    // return moment(msTime).format('DD. MMM, h:mm');
  };


  const lineTicksFunction = (number) => {
    if (scaledata && scaledata.length > 0) {
      min_time = d3.min(scaledata, function (d) {
        return d.signal_time;
      });
      // eslint-disable-next-line
      if (min_time == undefined) {
        return [];
      }
      const timeDomain = d3.scaleTime()
        .domain([zoomState.left, zoomState.right])
        .range([0.0, full_width]);

      // eslint-disable-next-line
      if (number == 1) {
        const ticks = timeDomain.ticks(
          d3.timeHour.every(24)).map(d => d.getTime());//(timeDomain(d) + 68));
        // return [10, 100, 400];//ticks;
        return ticks;
        // eslint-disable-next-line
      } else if (number == 3) {
        //Eine Stunde verzögert!
        const ticks = timeDomain.ticks(
          d3.timeHour.every(24)).map(d => d.getTime()+1000*60*60);//(timeDomain(d) + 68));
        // return [10, 100, 400];//ticks;
        return ticks;
      } else {
        const ticks = timeDomain.ticks(
          d3.timeHour.every(24)).map(d => (timeDomain(d)));
        // return [10, 100, 400];//ticks;
        return ticks;
      }
    }
  };

  const ticksFunction = () => {

    if (scaledata && scaledata.length > 0) {

      min_time = d3.min(scaledata, function (d) {
        return d.signal_time;
      });
  
      if (min_time === undefined) {
        return [];
      }
      max_time = d3.max(scaledata, function (d) {
        return d.signal_time;
      });
    }
    //TODO flexible Zeitangaben auf X-Achse
    // console.log("ticksFunctionscaledata: "+scaledata);
    // console.log("ticksFunction max_time: "+this.max_time+" min: "+this.min_time);
    // console.log("left: "+zoomState.left+" right: "+zoomState.right);

    // if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < self.hour) {
    //   //console.log("1: "+(x.invert(self.width)-x.invert(0))+" h: "+self.hour)
    //   return (d.getMinutes() % 10  === 0);
    // } else if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < 2*self.hour) {
    //   //console.log("1: "+(x.invert(self.width)-x.invert(0))+" h: "+self.hour)
    //   return (d.getMinutes() % 10  === 0);
    // } else if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < self.half_day) {
    //   //console.log("2: "+(x.invert(self.width)-x.invert(0))+" h: "+self.hour)
    //   return ((d.getHours() % 1 === 0) && (d.getMinutes() === 0));
    // } else if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < 2*self.day) {
    //   //console.log("3: "+(x.invert(self.width)-x.invert(0))+" h: "+self.hour)
    //   return ((d.getHours() % 3 === 0) && (d.getMinutes() === 0));
    //
    // } else if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < self.week) {
    //   //console.log("4: "+(x.invert(self.width)-x.invert(0))+" h: "+self.hour)
    //   return ((d.getHours() === 0) && (d.getMinutes() === 0)); //d3.timeDay.every(1);
    // } else if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < self.month) {
    //   //console.log("5: "+(x.invert(self.width)-x.invert(0))+" h: "+self.hour)
    //   return ((d.getUTCDay() === 0) && (d.getHours() === 0) && (d.getMinutes() === 0));
    // } else if ((self.xScale.invert(self.width)-self.xScale.invert(0)) < self.year) {
    //   return ((d.getHours() === 0) && (d.getMinutes() === 0));
    // } else {
    //   return ((d.getHours() === 0) && (d.getMinutes() === 0));
    // }

    // const now = new Date();
    // const domainToday = d3.scaleTime().domain([d3.timeDay.floor(now), d3.timeDay.ceil(now)]);

    const timeDomain = d3.scaleTime().domain([zoomState.left, zoomState.right]);
    //TODO - Je nach Domain unterschiedliche Ticks liefern!

    if (isMobile) {
      const ticks = timeDomain.ticks(d3.timeHour.every(24));
      return ticks;
    } else {
      const ticks = timeDomain.ticks(d3.timeHour.every(12));
      return ticks; //[ loc_left, (now.getTime()-this.week/2), loc_right];
    }
    // const ticks = timeDomain.ticks(d3.timeDay.every(1));

  };

  const hightlightme = (ele, item) => {
    // item.style.fill = "blue";
    // item.style.opacity = "0.39"
    // console.log("item: "+item);
  }

  const unhighlightme = (ele, item) => {
    // item.style.fill = "none";
    // item.style.opacity = "0.39"
    // console.log("unhighlightme: "+JSON.stringify(ele));
  }

  const clickOnEvent = (event, item) =>{
    // item.style.fill = "none";
    // item.style.opacity = "0.39"
    console.log("clickOnEvent: "+JSON.stringify(event));
    setEventOpen(true);
    setEvent(event);
  }

  const handleClose = (event, save) => {
    if (save) {
      console.log("save event: "+JSON.stringify(event)+" save: "+save);
      // event.id_scale = scale.id_scale;

      //TODO wieder aktivieren
      // this.props.saveNewEvent(token, event);

    } else {
      //einfach ignorieren - abbrechen oder außerhalb von Modal
    }

    setEventOpen(false);
  };

  const renderCustomizedLabel = (loc_props) => {
    const { x, width, value } = loc_props;
    let { topGewicht, bottomGewicht } = zoomState;

    const radius = 9;
    if (value === 0) {
      return null;
    }

    //const timeDomain = d3.scaleTime().domain([zoomState.left, zoomState.right]);
    // console.log("renderCustomizedLabel: "+JSON.stringify(scaledata));
    // console.log("renderCustomizedLabel: "+JSON.stringify(scaledata[loc_props.index]));
    // console.log("topGewicht: "+topGewicht+ " bottomGewicht: "+bottomGewicht);

    //TODO Berechnung wo das Event angezeigt werden soll!
    let yCalc = 15 + 385 * (topGewicht -scaledata[loc_props.index].weight) / (topGewicht - bottomGewicht);
    // yCalc = 15;

    let textVal = ""+value;

    if (value < 10) {
      yCalc = 375;
      textVal = "?";
    } else {
      if (value === 11) {
        textVal = "+Z";
      } else if (value === 12) {
        textVal = "-S";
      } else if (value === 13) {
        textVal = "-Z";
      }
    }

    //TODO - nur anzeigen wenn innerhalb der sichtbaren Zone (x-Achse)

    return (
      <g
        className={styles.eventPoint}
        onMouseOver={hightlightme.bind(scaledata[loc_props.index])}
        onMouseOut={unhighlightme.bind(scaledata[loc_props.index])}
        onClick={clickOnEvent.bind(scaledata[loc_props.index])}>

        <circle
          cx={x + width / 2}
          cy={yCalc - radius}
          r={radius} fill="#00796BBB" />

        <text
          x={x + width / 2}
          y={yCalc - radius}
          fill="#fff"
          fontSize="10px"
          textAnchor="middle"
          dominantBaseline="middle">
          {textVal}
        </text>
      </g>  );
  };

  // const getState = () => {
  //   console.log(myRef.current.container.clientWidth);
  //   // console.log(this.myChart.current);
  // };

  // render() {
    // console.log("scale_data render: "+scaledata);
    // reference as a js object
    // day1 -0.03  day2 -0.01  day3 -0.01  day4  0.612  day5  0.016  day6 0.003999999999999998 day7 0.61
    // id "3"  id_gw  1015 id_scale 1015 last3 0.01 last6 0.01 last24 -0.01
    // const now = new Date();
    // const domainToday = d3.scaleTime().domain([d3.timeDay.floor(now), d3.timeDay.ceil(now)]);
    // const timeFormatter = (tick) => {return d3.timeFormat('%H:%M:%S')(new Date(tick));};
    // const ticks = domainToday.ticks(d3.timeHour.every(1));
  let { left, right, refAreaLeft, refAreaRight,
    topErtrag, bottomErtrag, topGewicht, bottomGewicht, zoomDays } = zoomState;

  const now = new Date();

  let ticks = ticksFunction();

  const lineTicks = lineTicksFunction(1);
  // const lineTicksCartesian = this.lineTicksFunction(2);
  const lineTicksDay = lineTicksFunction(3);

  if (myChart != null) {
    // let full_width = this.myChart.current.offsetWidth;
    console.log("lineTicksFunction ref: " +myChart.current.offsetWidth);
  }

  // if (this.myRef != null) {
  //   if (this.myRef.current != null) {
  //     // let full_width = this.myRef.current.offsetWidth;
  //     console.log("myRef ref: " + this.myRef.current.offsetWidth);
  //   }
  // }


  if (left === undefined) {
    left = now.getTime()-week;
  }
  if (right === undefined) {
    right = now.getTime();
  }
  if (topErtrag === undefined) {
    topErtrag = 1;
    // return;
  }
  if (bottomErtrag === undefined) {
    bottomErtrag = 0;
    // return;
  }


  let referenceLines = lineTicks != undefined ? lineTicks.map(t=>
    <ReferenceLine
      key={t}
      x={t}
      xAxisId="bottom"
      yAxisId="left"
      stroke="#ccc"
      strokeDasharray="3 3"/>) : ""; 


  //TODO strokeWidth muss an Anzeigetage und Jahreszeit angepasst werden!
  const strokeWidthDay = full_width / (4*zoomDays);

  let referenceLinesDay = lineTicksDay != undefined ? lineTicksDay.map(t=>
    <ReferenceLine
      key={t}
      x={t}
      xAxisId="bottom"
      yAxisId="left"
      stroke="#00796B11"
      strokeWidth={strokeWidthDay} />) : "";

  let heightWeight = 400;
  if (isMobile) {
    heightWeight = 240;
  }

    // if (loading) {
    //   return <Paper>
    //     <LinearProgress />
    //     {/*<Fade*/}
    //       {/*in={loading}*/}
    //       {/*style={{*/}
    //         {/*transitionDelay: loading ? '100ms' : '0ms',*/}
    //       {/*}}*/}
    //       {/*unmountOnExit >*/}
    //       {/*<CircularProgress />*/}
    //     {/*</Fade>*/}
    //   </Paper>;
    // }

    // console.log("render test: "+ticks);
    // // console.log("render now: "+now.getTime());
    // console.log("render left: "+left);

  return (
    <Paper>
      <EventDialog
        selectedEvent={event}
        open={event_open}
        onClose={handleClose} />

      { loading &&
        <LinearProgress />
      }
      {/*<button onClick={this.getState}> get ref info</button>*/}

      <MenuProvider id="menu_id">
        <div
          ref={setMyChartRef}
          className={styles.header_chart}
          onClick={handleScaleClick}>

          <Grid
            item
            container
            direction="row"
            justify="flex-start"
            alignItems="center">

            <Grid item className={styles.chart_back_button}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleBackClick}>
                <IconContainer icon={const_vars.ICON_BACK} />
                Zurück
              </Button>
            </Grid>
            <Grid item>
              <Typography
                align="center"
                variant="subtitle1"
                className={styles.chart_header_typo}
                gutterBottom>Signal&nbsp;
                
                { scale != undefined &&
                  <Moment locale="de" utc fromNow>{ scale.activated }</Moment> | Number.parseFloat( scale.weight ).toFixed( 2 )
                }
                kg
              </Typography>
            </Grid>
          </Grid>
          {/*{isLoading && <CircularProgress size={24} className={styles.progressClass} />}*/}
          &nbsp;
          <Chip
            label="6 h"
            onClick={handleClick.bind(this, 1)}
            clickable
            className={"chipclass"}
          />
          &nbsp;
          <Chip
            label="1 Tag"
            // icon={isLoading && <CircularProgress size={12} className={styles.progressClass} />}
            onClick={handleClick.bind(this, 2)}
            clickable
            className={styles.chipclass}
          />
          &nbsp;
          <Chip
            label="3 Tage"
            onClick={handleClick.bind(this, 3)}
            clickable
            className={"chipclass"}
          />
          &nbsp;
          <Chip
            label="1 Woche"
            onClick={handleClick.bind(this, 4)}
            clickable
            className={"chipclass"}
          />
          &nbsp;
          <Chip
            label="2 Wochen"
            onClick={handleClick.bind(this, 5)}
            clickable
            className={"chipclass"}
          />
          &nbsp;
          <Chip
            label="4 Wochen"
            onClick={handleClick.bind(this, 6)}
            clickable
            className={"chipclass"}
          />
          &nbsp;
          <Chip
            label="2 Monate"
            onClick={handleClick.bind(this, 7)}
            clickable
            className={"chipclass"}
          />
          &nbsp;
          <Chip
            label="Alles"
            onClick={handleClick.bind(this, 8)}
            clickable
            className={"chipclass"}
          />

          <ResponsiveContainer
            width="100%"
            height={180}>
            <LineChart
              // ref={myRef}
              margin={{ top: 8, right: 8, left: 8, bottom: 8 }}
              data={scaledata}
              syncId="syncId">

              <XAxis
                // padding={{ left: 23 }}
                xAxisId="bottom"
                dataKey="signal_time"
                type="number"
                scale="time"
                allowDecimals={false}
                allowDataOverflow={true}
                ticks={ticks}
                tick={{
                  fontSize: 0,
                  fill: colorXAxis}}
                domain={[left, right]}
                tickFormatter = { tickFormatFunction } />
              <YAxis
                yAxisId="left"
                interval="preserveStartEnd"
                unit="%"
                tick={{
                  fontSize: 12,
                  fill: colorHumidity}}
                label={{
                  value: "Luftfeuchte",
                  fontSize: 12,
                  angle: -90,
                  fill: colorHumidity,
                  position:  'insideLeft'}}
                domain={[0, 100]} />
              <YAxis
                yAxisId="right"
                interval="preserveStartEnd"
                unit="°C"
                tick={{
                  fontSize: 12,
                  fill: colorTemperature}}
                label={{
                  value: "Temperatur",
                  fontSize: 12,
                  angle: 90,
                  fill: colorTemperature,
                  position: 'insideBottomRight'}}
                orientation="right"
                domain={['auto', 'auto']}  />
              <CartesianGrid
                vertical={false}
                strokeDasharray="3 3"/>
              <Tooltip content={customTooltipOnYourLine}/>
              {/*<Legend/>*/}
              <Line
                isAnimationActive={false}
                yAxisId="right"
                xAxisId="bottom"
                dot={false}
                type="monotoneX"
                strokeWidth={strokeWidthLines}
                dataKey="temperature"
                // legendType="square"
                stroke={ colorTemperature }/>
              <Line
                yAxisId="left"
                xAxisId="bottom"
                isAnimationActive={false}
                dot={false}
                type="monotoneX"
                dataKey="humidity"
                strokeWidth={strokeWidthLines}
                stroke={ colorHumidity }
                activeDot={{r: 4}}/>

              {referenceLinesDay}
              {referenceLines}
            </LineChart>
          </ResponsiveContainer>

          <ResponsiveContainer
            width="100%" height={heightWeight}>

            <ComposedChart
              margin={{ top: 8, right: 8, left: 8, bottom: 8 }}
              data={scaledata}
              // onMouseDown = { (e) => {
              //   this.props.updateZoomState({
              //     refAreaLeft: e.activeLabel,
              //     refAreaRight: zoomState.refAreaRight,
              //     left: zoomState.left,
              //     right: zoomState.right,
              //     animation: true
              //   })
              //   // this.setState({refAreaLeft:e.activeLabel})
              // }}
              // onMouseMove = { (e) => {
              //   zoomState.refAreaLeft &&
              //   this.props.updateZoomState({
              //     refAreaLeft: zoomState.refAreaLeft,
              //     refAreaRight: e.activeLabel,
              //     left: zoomState.left,
              //     right: zoomState.right,
              //     animation: true
              //   })
              //   // this.setState({refAreaRight:e.activeLabel})
              // }}
              // onMouseUp = { this.zoom.bind( this ) }
              syncId="syncId">

              {referenceLinesDay}
              {referenceLines}
              <XAxis
                dataKey="signal_time"
                type="number"
                scale="time"
                // padding={{ left: 23 }}
                allowDecimals={false}
                allowDataOverflow={true}
                ticks={ticks}
                tick={{
                  fontSize: 12,
                  fill: colorXAxis}}
                // domain = {['dataMin', 'dataMax']}
                domain={[left, right]}
                tickFormatter = { tickFormatFunction }
                xAxisId="bottom"/>
              <YAxis
                yAxisId="left"
                unit="kg"
                type="number"
                allowDataOverflow={true}
                dot={<CustomizedDot />}
                tick={{
                  fontSize: 12,
                  fill: colorWeight}}
                label={{
                  value: "Gewicht (kg)",
                  fontSize: 12,
                  angle: -90,
                  fill: colorWeight,
                  position:  'insideLeft'}}
                domain={[bottomGewicht, topGewicht]} />
              <YAxis
                yAxisId="right"
                unit="kg"
                type="number"
                allowDataOverflow={true}
                tick={{
                  fontSize: 12,
                  fill: colorVoltage}}
                label={{
                  value: "Ertrag (kg)",
                  fontSize: 12,
                  angle: 90,
                  fill: colorVoltage,
                  position: 'insideRight'}}
                domain={[bottomErtrag, topErtrag]}
                // domain={['auto', 'auto']}
                orientation="right" />

              {/*<CartesianGrid*/}
                {/*margin={{ top: 0, right: 0, left: 68, bottom: 0 }}*/}
                {/*// padding={{ left: 23 }}*/}
                {/*verticalPoints={lineTicksCartesian}*/}
                {/*vertical={true}*/}
                {/*yAxisId="left"*/}
                {/*xAxisId="bottom"*/}
                {/*strokeDasharray="3 3"*/}
                {/*verticalFill={['#666', '#BBB']}*/}
                {/*fillOpacity={0.1}/>*/}

              <CartesianGrid
                vertical={false}
                strokeDasharray="3 3"/>

              <Tooltip content={customTooltipMain}/>
              {/*<Legend/>*/}
              <Line
                isAnimationActive={false}
                yAxisId="left"
                xAxisId="bottom"
                dot={false}
                type="monotoneX"
                dataKey="weight"
                strokeWidth={strokeWidthLines}
                stroke={ colorWeight} />
              <Line
                isAnimationActive={false}
                yAxisId="right"
                xAxisId="bottom"
                dot={false}
                type="monotoneX"
                dataKey="ertrag"
                strokeWidth={strokeWidthLines}
                stroke={colorVoltage}
                activeDot={{r: 4}}/>
              { <ReferenceDot  r={20} fill="red" stroke="none" /> }


              <Bar
                isAnimationActive={false}
                yAxisId="left"
                xAxisId="bottom"
                barSize={0}
                fill='#413ea0'
                dataKey='event' >

                <LabelList
                  dataKey="event"
                  content={renderCustomizedLabel} />
              </Bar>
              {
                (refAreaLeft && refAreaRight) ? (
                  <ReferenceArea yAxisId="1"
                                  x1={refAreaLeft}
                                  x2={refAreaRight}
                                  strokeOpacity={0.3} /> ) : null

              }
              {/*<Brush*/}
                {/*syncId="syncId"*/}
                {/*tickFormatter = {*/}
                  {/*(msTime) => moment(msTime).format('DD.MM.')}>*/}

                {/*<BarChart*/}
                  {/*margin={{ top: 0, right: 8, left: 8, bottom: 100 }}*/}
                  {/*data={scaledata}*/}
                  {/*syncId="syncId">*/}
                  {/*<ReferenceLine y={0} stroke='#000'/>*/}

                  {/*<Tooltip/>*/}
                  {/*<Bar*/}
                    {/*dataKey="diff"*/}
                    {/*fill="#8884d8" />*/}
                {/*</BarChart>*/}

              {/*</Brush>*/}
              </ComposedChart>
          </ResponsiveContainer>

        </div>
      </MenuProvider>
      <MyAwesomeMenu />
    </Paper>
  );
  
}

const mapStateToProps = state => {
  return {
    zoomState: state.main.zoomState,
    scale: state.main.currentScale,
    scaledata: state.main.scaledata,
    loading: state.main.loading,
    token: state.auth.token,
    colorHumidity: state.settings.colorHumidity,
    colorTemperature: state.settings.colorTemperature,
    colorWeight: state.settings.colorWeight,
    colorVoltage: state.settings.colorVoltage,
    colorXAxis: state.settings.colorXAxis,
    strokeWidthLines: state.settings.strokeWidthLines
  };
};

const mapDispatchToProps = dispatch => {
  return {
    updateZoomState: (zoomState) => dispatch(updateZoomState(zoomState)),
    changeTitle: (new_title, menu) => dispatch(actions.setTitleAndMenu(new_title, menu)),
    selectScale: (scale) => dispatch(actions.setCurrentScale(scale)),
    onFetchScaleData: (token, scale_id) => dispatch( fetchScaleData(token, scale_id) ),
    onFetchScaleDataAll: (token, scale_id) => dispatch( fetchScaleDataAll(token, scale_id)),
    deleteScaleData: () => dispatch( fetchScaleDataSuccess([]) ),
    saveNewEvent: (token, event) => dispatch(saveEvent(token, event))
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(ScaleDetail));

export class LineInfo {
  index = 0;
  color = "";
  name = "";
  typ = 0;
  position = 0;
  draw = true;
  api = 0;
  gw = 0;
  scale = 0;
  line;
}