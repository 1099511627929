import React, { useEffect, useState } from 'react';
import 'typeface-roboto';
import {  useDispatch, useSelector } from 'react-redux';
import axios from '../axios-modbee';
import * as actions from "../store/actions/scales";
import store from "../store/store";
// import * as actions_statistics from "../../store/actions/statistics";
import static_vars from "../Static/const_vars";
import * as actions_scale from "../store/actions/scales";


const BeeEvents = (props) => {
  const [ listening, setListening ] = useState(0);
  const dispatch = useDispatch();
  // const station = useSelector(state => state.main.stations.find(station => station.id_stations === 215));
  const token = useSelector(state => state.auth.token);
  const old_stations = useSelector(state => state.main.stations);
  const registerServerEvents = (hash, my_id) => {
    // console.log("registerServerEvents "+token);
    const headers = {
      'Content-Type': 'application/json',
      'Authorization': ''+token
    }    
    const config = {
      data: {
        "hash": hash,
        "my_id": parseInt(my_id)
      },
      crossdomain: true
    };
    axios.post( '/eventsource/register/', config, {
          headers: headers
        })
        .then( res => {            
            // console.log("got registerServerEvents answer: ", res);
        } )
        .catch( err => {      
            console.log("got registerServerEvents error: ", err);
        } );
  }
  
  useEffect(() => {
      // console.log("eventsource nr: "+listening);

      let events; 
      if (process.env.NODE_ENV !== 'production') {
        events = new EventSource('http://localhost:10001/v3/eventsource'); 
        // events = new EventSource('https://api.modbee.io/v3/eventsource');
      } else {
        events = new EventSource('https://api.modbee.io/v3/eventsource');
      }     

      
      events.onmessage = (event) => {        
        if (event.data.startsWith('auth-with:')) {
          //Registrierung am Server notwendig - sonst bekommen wir keine Nachrichten!
          var res = event.data.split(":");
          // console.log("auth with: "+res[1]+" and "+res[2]);
          registerServerEvents(res[1], res[2]);
        } else {
          if (event.data !== ".") {
            console.log("event msg: "+event.data);
            
            let jsonData;
            try {
              jsonData = JSON.parse(event.data);
            } catch (e) {
              console.log("error eventmsg parse: ", event.data);
              return;
            }

            // RSSI: 206,
            // IMSI: 207,
            if (jsonData.typ === 1) {
              //Ping von Station an Server - refresh List from Server
              // dispatch(actions.fetchStations(token, old_stations));


            } else if (jsonData.typ === static_vars.SCALE_DATA) { 
              //wird eigentlich dann geladen                 
              console.log("SCALE_DATA empfangen von station "+jsonData.id_scale+" weight "+jsonData.weight); 

              // event.data.typ unbekannt - trotzdem laden: 
              // {"typ":209,SCALE_DATA
              // "id_scale":1034,
              // "weight":10.001,
              // "vc":4.16,
              // "last3":-0.012000000000000455,
              // "last6":-0.006999999999999673,
              // "last24":-0.022999999999999687}

              const state = store.getState();
              const scaleWorker = state.main.scaleWorker;
              if (scaleWorker) {
                // const token = useSelector(state => state.auth.token);
                // const userId = useSelector(state => state.auth.userId);
                console.log('try scaleWorker...')
                try {
                  scaleWorker
                  .postMessage({
                    token: state.auth.token,
                    uid: state.auth.userId,
                    typ: 'scales',
                    new_data: 1
                  });            
                } catch (error) {            
                  console.log('error com scaleworker ', error)
                }    
              }  
              // // console.log("jsonData.typ === 203: ", state.main.stations);
              // let clonedScales = [...state.main.stations];
              // clonedScales.forEach((scale, index) => {
              //   if (scale.id_scale === jsonData.id_scale) {                  
              //     console.log("station updated: "+jsonData.part+"/"+Math.ceil(jsonData.total_parts));
              //     scale.updating = true;
              //     // dispatch(actions.fetchStationsSuccess(clonedStations));   
              //     dispatch(actions_scale.fetchScalesSuccess(clonedScales));               
              //   }
              // });
              
            }  else if (jsonData.typ === static_vars.RSSI) { 
                            //wird eigentlich dann geladen                 
              console.log("Signalstärke empfangen RSSI von station "+jsonData.id_station+" port "+jsonData.port); 
              // dispatch(actions.fetchStations(token, old_stations));
            }  else if (jsonData.typ === static_vars.IMSI) { 
              //wird eigentlich dann geladen                 
              console.log("IMSI von station "+jsonData.id_station+" port "+jsonData.port); 
            }  else if (jsonData.typ === static_vars.GET_FIRMWARE_VERSION) { //GET_FIRMWARE_VERSION
              // dispatch(actions.fetchStations(token, old_stations));
            } else if (jsonData.typ === static_vars.UPDATE_FIRMWARE) {  //Client lädt gerade Firmware herunter

              // const state = store.getState();
              // console.log("jsonData.typ === 208: ", state.main.stations);
              // let clonedStations = [...state.main.stations];
              // clonedStations.forEach((station, index) => {
              //   if (station.id_stations === jsonData.id_station) {                  
              //     console.log("charger on port "+jsonData.port+" is updating: "+jsonData.part+"/"+Math.ceil(jsonData.total_parts));
                  
              //     if (jsonData.part >= jsonData.total_parts) {
              //       //Update abgeschlossen - Charger probiert Update!                
              //     }
              //     // station.chargers.forEach((charger, index) => {
              //     //   // updateStations(clonedStations);
              //     // });
              //     dispatch(actions.fetchStations(token, old_stations));
              //   }
              // });
            } else if (jsonData.typ === static_vars.FIRMWARE_DATA_CHARGER) {  //Client lädt gerade Firmware herunter
              //Ping von Station an Server - refresh List from Server
              // jsonData.id_station
              // jsonData.part
              // jsonData.total_parts
              // let clonedStations = [...stations];

              // const state = store.getState();
              // console.log("jsonData.typ === 203: ", state.main.stations);

              // let clonedStations = [...state.main.stations];

              // clonedStations.forEach((station, index) => {
              //   if (station.id_stations === jsonData.id_station) {                  
              //     console.log("station updated: "+jsonData.part+"/"+Math.ceil(jsonData.total_parts));
              //     station.updating = true;
              //     station.update_charger_part = jsonData.part;
              //     station.update_charger_total_parts = Math.ceil(jsonData.total_parts);

              //     if (station.update_charger_part === station.update_charger_total_parts) {
              //       station.updating = false;
              //     };
              //     dispatch(actions.fetchStationsSuccess(clonedStations));                  
              //   }
              // });
            } else if (jsonData.typ === static_vars.FIRMWARE_DATA) {  //Client lädt gerade Gateway Firmware herunter

              // const state = store.getState();
              // console.log("jsonData.typ === 201: ", state.main.stations);

              // let clonedStations = [...state.main.stations];
              // clonedStations.forEach((station, index) => {
              //   if (station.id_stations === jsonData.id_station) {                  
              //     console.log("station gw updating: "+jsonData.part+"/"+Math.ceil(jsonData.total_parts));
              //     station.updating_gw = true;
              //     station.update_gw_part = jsonData.part;
              //     station.update_gw_total_parts = Math.ceil(jsonData.total_parts);

              //     if (station.update_gw_part === station.update_gw_total_parts) {
              //       station.updating_gw = false;
              //     };
              //     // updateStations(clonedStations);
              //     dispatch(actions.fetchStationsSuccess(clonedStations));
              //   }
              // });
            } else if (jsonData.typ === static_vars.CHARGER_STATUS_UPDATE) {  //Client lädt gerade Firmware herunter

              // const state = store.getState();
              // console.log("CHARGER_STATUS_UPDATE: ", state.main.stations);
              // let clonedStations = [...state.main.stations];
              // clonedStations.forEach((station, index) => {
              //   if (station.id_stations === jsonData.id_station) {                  
              //     console.log("charger on port "+jsonData.port+" is updating: "+jsonData.part+"/"+Math.ceil(jsonData.total_parts));
                  
              //     if (jsonData.part >= jsonData.total_parts) {
              //       //Update abgeschlossen - Charger probiert Update!                
              //     }
              //     // station.chargers.forEach((charger, index) => {
              //     //   // updateStations(clonedStations);
              //     // });
              //     dispatch(actions.fetchStations(token, old_stations));
              //   }
              // });
            } else if (jsonData.typ === static_vars.GW_STATUS_UPDATE) {  //Client lädt gerade Firmware herunter

              // const state = store.getState();
              // // console.log("GW_STATUS_UPDATE: ", state.main.stations);
              // let clonedStations = [...state.main.stations];
              // clonedStations.forEach((station, index) => {
              //   if (station.id_stations === jsonData.id_station) { 
              //     dispatch(actions.fetchStations(token, old_stations));
              //   }
              // });
            } else {
              console.log("event.data.typ unbekannt - trotzdem laden: "+JSON.stringify(jsonData));
              // dispatch(actions.fetchStations(token, old_stations));
            }
          } else {
            //nur Ping vom Server damit Verbindung aufrecht bleibt!
          }
        }
        // console.log("new event message: "+event.data);
        // const parsedData = JSON.parse(event.data);
        // setNests((nests) => nests.concat(parsedData));
      };

      events.onerror = function (err) {
        if (err) {
          if (err.status === 401 || err.status === 403) {
            console.error('not authorized in eventlistener');
          } else {
            console.error('err eventsource: '+JSON.stringify(err));
          }
        }
        events.close();
        //TODO - verzögert neu starten!!
        setTimeout(function(){
          setListening(listening+1);
        }, 1000);
      };
      // setListening(true);
    // }

    return function cleanup() {
      // console.log("cleanup serverevents nr: "+listening);
      // ChatAPI.unsubscribeFromFriendStatus(props.friend.id, handleStatusChange);
      events.close();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, listening, token, old_stations]);

  return (
    <div>
    </div>
  );
}

export default (BeeEvents);
