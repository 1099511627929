import React, { Component } from 'react';
import styles from './Alarms.module.css';
import {connect} from "react-redux";
// import Moment from "react-moment"; // Import css modules stylesheet as styles
import 'moment-timezone';
import 'moment/locale/de';
import {Paper} from "@material-ui/core/umd/material-ui.production.min";
import {withRouter} from "react-router-dom";
import * as actions from "../store/actions/main";
import {askForPermissioToReceiveNotifications} from "../components/firebase/firebase";
// import Button from "@material-ui/core/es/Button/Button";
import Button from "@material-ui/core/Button";
import * as const_vars from "../Static/const_vars";

class Alarms extends Component {

  constructor(props) {
    super(props);
    console.log("constructor Alarms ", props);
  }

  componentDidMount () {
     // console.log("componentDidMount this.props.scale ", this.props.scale);
  }

  handleScaleClick = () => {
    this.props.selectScale(this.props.scale);
    this.props.history.push('/scale');
    // history.push('/my-new-location')
    // this.props.dispatch(actions.setMainTitle(this.props.scale.name));
  }


// {/*<div className={styles.scale} onClick={this.handleScaleClick}>*/}
  render() {
    return (
      <Paper>
         <div onClick={askForPermissioToReceiveNotifications} className={styles.alarm_box} >
            Benachrichtigungen aktivieren!
           <Button variant="contained" color="primary"  className={styles.alarm_test_button} >
             Hello World
           </Button>
         </div>
      </Paper>
    );
  }
}

const mapStateToProps = state => {
  return {
    scale: state.main.currentScale,
    token: state.auth.token
  };
};

const mapDispatchToProps = dispatch => {
  return {
    changeTitle: () => dispatch(actions.setTitleAndMenu('Alarme', const_vars.MENU_ALARMS)),
    selectScale: (scale) => dispatch(actions.setCurrentScale(scale))
  };
};

export default connect(mapStateToProps, mapDispatchToProps) (withRouter(Alarms));