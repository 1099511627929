import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from "react-redux";
import 'moment-timezone';
import 'moment/locale/de';
import {Button, Paper, Divider} from "@material-ui/core";
import { makeStyles } from '@material-ui/core';
import {withRouter} from "react-router-dom";
import { Typography } from '@material-ui/core';
import IconContainer from "../components/iconContainer/IconContainer";
import * as const_vars from "../Static/const_vars";
import Plot from "react-plotly.js";
import moment from "moment";
import * as settings from "./ChartSettings";
// import ScaleClass from "./ScaleClass";
import StatisticScale from "./CalibrationScale";
import { pointer } from 'd3';
import db from '../store/db';
import * as actions_scale from "../store/actions/scales";
import Plotly from 'plotly.js/dist/plotly';
import * as d3 from "d3";
import * as actions from "../store/actions/main";
import axios from '../axios-modbee';
import regression from 'regression';


const useStyles = makeStyles((theme) => ({
  customClass: {
    padding: theme.spacing(4),
    // paddingBottom: theme.spacing(4),
    cursor: pointer
  },
}));

const Calibration = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const visible = useSelector(state => state.main.themeColors.visible);
  const token = useSelector(state => state.auth.token);

  const primary = useSelector(state => state.main.themeColors.primary);
  const secondary = useSelector(state => state.main.themeColors.secondary);
  const primaryText = useSelector(state => state.main.themeColors.primaryText);
  const secondaryText = useSelector(state => state.main.themeColors.secondaryText);
  const highlight = useSelector(state => state.main.themeColors.highlight);
  const appBar = useSelector(state => state.main.themeColors.appBar);
  const sideBar = useSelector(state => state.main.themeColors.sideBar);

  const ertrag = useSelector(state => state.main.themeColors.ertrag);
  const weight = useSelector(state => state.main.themeColors.weight);
  const temperature = useSelector(state => state.main.themeColors.temperature);
  const voltage = useSelector(state => state.main.themeColors.voltage);
  const humidity = useSelector(state => state.main.themeColors.humidity);

  // const scale = useSelector(state => state.main.currentScale);
  const id_scales = props.match.params.scale_id;
  const timeFormat = 'YYYY-MM-DD HH:mm:ss';
  // const timeFormat = 'YYYY-MM-DD HH:mm:ss';

  const [calibrationData, setCalibrationData] = useState([]);

  const [selectedData, setSelectedData] = useState(["2021-03-19 09:04:35.5548", "2021-03-20 09:28:06.1555"]);
  const [zeroData, setZeroData] = useState(["2021-03-19 09:04:35.5548", "2021-03-20 09:28:06.1555"]);
  const [val1Data, setVal1Data] = useState(["2021-03-20 12:16:02.7818", "2021-03-22 06:39:11.6944"]);
  const [val2Data, setVal2Data] = useState(["2021-03-22 09:27:08.3207", "2021-04-02 10:09:49.2654"]);
  const [val1, setVal1] = useState(4824);
  const [val2, setVal2] = useState((4824+4858));


  // const scales = useSelector(state => state.main.scales);
  const scales = useSelector(state => state.scale.scales);
  const plotlyRef = useRef(null);
  const [scaleRange, setScaleRange] = useState({
      start: "2021-03-19 09:04:35.5548", //moment().subtract(1, 'week').format(timeFormat),
      end: "2021-03-20 09:28:06.1555", //moment().format(timeFormat),
      weightStart: 0,
      weightEnd: 100,
      ertragStart: 0,
      ertragEnd: 10 });

  let dragLayer = document.getElementsByClassName("nsewdrag")[0];
  const t_gradient  = 49.63;
  const t_yIntercept = 16808693.69; 

  useEffect( () => {   
    console.log("id_scales ", id_scales);

    const config = {
      headers: {
         'Authorization': ''+token
      }
    };
    console.log("fetchScaleData scale_id: ", id_scales);

    //TODO nur für diese waage abholen!!!
    axios.get( '/scale/calibration/'+id_scales, config)
    // axios.get( '/scale/weights/'+scale_id, config)
    // axios.get( '/weight_data/'+scale_id, config)
      .then( async function (res) {
        // const fetchScales = [];
        console.log("got calibration weight_data: ", res.data);
        let scaleData = res.data;
        let temp_scales;
        // console.log("addStatisticScale add.. scales = ", scales);
  
        if (scales != undefined) {
            if (scales.length < 1) {
            try {        
              temp_scales = await db.scales
              // .where('age').above(75)
                .toArray();
            } catch (error) {
              console.log("error getting scales from DB: ",error)
            }
          } else {
            temp_scales = [...scales];
          };   
        }
        let item = {};

        temp_scales.forEach(async function (loc_item, index) {
          if (loc_item.id_scales == id_scales) {   
            item = loc_item;
          }
        });      

        let lastHum = 0;
        var offset = moment().utcOffset();

        let ertragsArray = [];
        let ertragsArrayPos = [];
        let ertragsArrayNeg = [];
        let ertragsArrayDay = [];
        let ertragsArrayPosDay = [];
        let ertragsArrayNegDay = [];

        let dayArray = [];

        let ticksDayArray = [];
        let ticksWeekArray = [];

        let day = -1;
        let hour = -1;
        let lastWeightValue = 0;
        let lastValue = 0;
        let lastValueHour = 0;
        let humDayMin = 0;
        let humDayMax= 0;
        let humDayAvg = 0;
        let tempDayMin = 0;
        let tempDayMax= 0;
        let tempDayAvg = 0;
        let weightDayMin = 0;
        let weightDayMax= 0;
        let weightDayAvg = 0;
        let dayCount = 0;
        let lastDaySignalTime = 0;
        let lastDayTimeString = '';
        let last_id_weight = 0;
        let last_time = 0;

        let currentEvent = {
          id_start: 0,
          id_before: 0,
          id_end: 0,
          id_after: 0,
          weight_before: 0,
          weight_start: 0,
          weight_end: 0,
          weight_after: 0,
          time_before: 0,
          time_start: 0,
          time_end: 0,
          time_after: 0,
          time_string_start: '',
          time_string_end: '',
          reset_ertrag: false
        }

        let startEvent = false;
        let endEvent = false;
        let diff = 0.0;
        let ertragSum = 0.0;

        let lastTime = 0;


        if (scaleData != undefined) {                
          console.log("scaledata: ", scaleData);

          let last_event = 0;
          let last_event_weight = 0;
          let last_event_weight_before = 0;
          let last_weight = 0;
          let last_weight_save = 0;
          let diff = 0;
          let event_diff = 0;
          let ertrag = 0;
          let event = 0;
          let lastTemp = 0;
          const timeFormat2 = 'DD.MM.YYYY HH:mm:ss';
          const timeFormat = 'YYYY-MM-DD HH:mm:ss';
        
          scaleData = scaleData.map((dataPoint, sc_index) => { 
            if (dataPoint.temp1 == -40) {
              dataPoint.temp1 = lastTemp;
            } else {
              lastTemp = dataPoint.temp1;
            }       
            return {
              // x: moment.utc(dataPoint.change_time).format(timeFormat),
              // y: dataPoint.scale,
              id_weight: parseInt(dataPoint.id_weight),
              id_scale: parseInt(dataPoint.id_scale),
              temperature: lastTemp,
              humidity: dataPoint.humidity,
              pressure: dataPoint.pressure,
              scale: dataPoint.scale,
              weight: dataPoint.weight,
              scale_val: dataPoint.scale,
              last: last_weight_save,
              diff: diff,
              ertrag: ertrag,
              event: parseInt(event),
              temp_scale: lastTemp,
              time: dataPoint.change_time,
              voltage: dataPoint.vc,
              date: moment.utc(dataPoint.change_time).local().format(timeFormat), //moment(dataPoint.time).valueOf()/1000,
              voltage_percent: dataPoint.vc_p,
              signal_time: moment.utc(dataPoint.change_time).valueOf(), //= milliseconds
              time_string: moment.utc(dataPoint.change_time).local().format(timeFormat),
            };
          });


          item.scaledata = scaleData.map((dataPoint, sc_index) => { 
            startEvent = false;
            endEvent = false;

            if (sc_index == 0) {
              diff = 0;
            } else {
              diff = dataPoint.weight - lastWeightValue;
            }

            /* *********************
            Ereignisse suchen:
            *************************/
            if (sc_index > 0) { //erstes Item wird übersprungen                                    
              //Gewicht um mehr als 1kg geändert
              if (currentEvent.id_start == 0) {
                if (Math.abs(diff) > 1) {
                  //Aktuell kein Event im Gange
                  //Event nur starten, falls die Zu- oder Abnahme nicht erklärt werden kann!
                  //Abnahme -> falls innerhalb einer Stunde, sofort Event starten
                  
                  if (diff < 0) { //Abnahme
                    //Falls größerer Zeitpunkt keine Daten - auch Event starten
                    startEvent = true;
                    
                  } else {  //Zunahme Gewicht

                    //Bei Zunahme kann es bei extremen Honigzunahmen große Sprünge geben
                    //allerdings auch nicht innerhalb von kurzer Zeit, sondern stetig über den
                    //Tag verteilt!

                    //Es kann aber auch Regen sein!
                    startEvent = true;
                  }


                  if (startEvent) { //Event starten!
                    currentEvent.id_before = last_id_weight;
                    currentEvent.id_start = dataPoint.id_weight;
                    currentEvent.weight_before = lastWeightValue;
                    currentEvent.weight_start = dataPoint.weight ;
                    currentEvent.time_before = last_time;
                    currentEvent.time_start = dataPoint.signal_time;

                    // console.log("start event at point: ", dataPoint);
                    console.log("start event: ", dataPoint.date);
                    console.log("weight event start: ", dataPoint.weight);
                    console.log("weight before: ", lastWeightValue);

                  }
                }
              } else {
                //Es läuft ein Event, schauen ob wir das Event beenden müssen
                if (currentEvent.time_start+1000*60*60*1.5 < dataPoint.signal_time) {
                  //Nach einer Stunde automatisch Abbruch!
                  
                  endEvent = true;
                } else if (Math.abs(diff) > 1) {
                  endEvent = true;
                }


                if (endEvent) { //Event abschließen und speichern!
                  // console.log("end event at point: ", dataPoint);

                  currentEvent.id_end = last_id_weight;
                  currentEvent.weight_end = lastWeightValue;    
                  currentEvent.time_end = last_time; 

                  currentEvent.id_after = dataPoint.id_weight;
                  currentEvent.weight_after = dataPoint.weight;
                  currentEvent.time_after = dataPoint.signal_time; 
                  console.log("end event: ", dataPoint.date);
                  console.log("weight event end: ", lastWeightValue);
                  console.log("weight after: ", dataPoint.weight);
                  //TODO - Event in Datenbank und Online eintragen!

                  //Werte für Ertragsberechnung zurücksetzen!
                  lastValue = dataPoint.weight;      
                  lastValueHour = dataPoint.weight;   

                  lastWeightValue = dataPoint.weight;  

                  currentEvent.id_end = 0; 
                  currentEvent.id_start = 0; 
                  diff = 0;
                }
              }           
            }

            if (currentEvent.id_start !== 0) {
              dataPoint.ertrag = 0; //Kein Ertrag falls gerade EVENT läuft!
              // ertragSum = 0;
              dataPoint.ertrag = ertragSum;
            } else {
              ertragSum += diff;
              dataPoint.ertrag = ertragSum; //Kein Ertrag falls gerade EVENT läuft!                
            }
            
            last_time = dataPoint.signal_time; 
            last_id_weight = dataPoint.id_weight;
            lastWeightValue = dataPoint.weight;


            if (lastTime != 0) {
              //Falls 1 Tage keine Werte, dann autoamtisch GAP erzeugen
              if (lastTime < moment(dataPoint.time_string).valueOf()-1000*60*60*24*1) {
                // dataPoint.weight = null;
                // dataPoint.humidity = null;
                // dataPoint.temperature = null;
                // lastValueHour = 0;
                // lastHum = null;
                // dataPoint.humidity = null;
              }
            }
            lastTime = moment(dataPoint.time_string).valueOf();
            

            if (moment(dataPoint.time_string).day() != day) {
              day = moment(dataPoint.time_string).day();

              // console.log("Neuer tag: ", moment(dataPoint.time_string));
              if (lastValue != 9999) {                    
                humDayAvg = humDayAvg / dayCount;
                tempDayAvg = tempDayAvg / dayCount;
                weightDayAvg = weightDayAvg / dayCount;

                //TODO eventuell halben Tag dazu rechnen?! für Darstellung!
                const ertragsItem = {
                  ertrag: (currentEvent.id_start !== 0) ? 0 : (dataPoint.weight -lastValue),
                  time_string: lastDayTimeString, //dataPoint.time_string,
                  signal_time: lastDaySignalTime, //dataPoint.signal_time,
                  humAvg: humDayAvg,
                  humMin: humDayMin,
                  humMax: humDayMax,
                  tempAvg: tempDayAvg,
                  tempMin: tempDayMin,
                  tempMax: tempDayMax,
                  weightAvg: weightDayAvg,
                  weightMin: weightDayMin,
                  weightMax: weightDayMax,
                }
                ertragsArrayDay.push(ertragsItem);
                if (ertragsItem.ertrag >= 0) {
                  ertragsArrayPosDay.push(ertragsItem);
                } else {
                  ertragsArrayNegDay.push(ertragsItem);
                }
                dayCount = 0;
                humDayMin = dataPoint.humidity;
                humDayMax= dataPoint.humidity;
                humDayAvg = 0;
                tempDayMin = dataPoint.temperature;
                tempDayMax= dataPoint.temperature;
                tempDayAvg = 0;
                weightDayMin = dataPoint.weight;
                weightDayMax = dataPoint.weight;
                weightDayAvg = 0;
              }
              lastDaySignalTime = dataPoint.signal_time;
              lastDayTimeString = dataPoint.time_string;
              lastValue = dataPoint.weight;
            } 

            //Berechnung der Werte
            dayCount++;

            weightDayAvg += dataPoint.weight;
            tempDayAvg += dataPoint.temperature;
            humDayAvg += dataPoint.humidity;

            humDayMax = (dataPoint.humidity > humDayMax) ? dataPoint.humidity : humDayMax;
            humDayMin = (dataPoint.humidity < humDayMin) ? dataPoint.humidity : humDayMin;
            tempDayMax = (dataPoint.temperature > tempDayMax) ? dataPoint.temperature : tempDayMax;
            tempDayMin = (dataPoint.temperature < tempDayMin) ? dataPoint.temperature : tempDayMin;
            weightDayMax = (dataPoint.weight > weightDayMax) ? dataPoint.weight : weightDayMax;
            weightDayMin = (dataPoint.weight < weightDayMin) ? dataPoint.weight : weightDayMin;

            if (moment(dataPoint.time_string).hour() != hour) {
              hour = moment(dataPoint.time_string).hour();
              // console.log("Neuer tag: ", moment(dataPoint.time_string));
              if (lastValueHour != 9999) {
                const ertragsItem = {
                  ertrag: lastValueHour == null ? 0 : (dataPoint.weight -lastValueHour),
                  time_string: dataPoint.time_string,
                  signal_time: dataPoint.signal_time
                }
                ertragsArray.push(ertragsItem);
              }
              lastValueHour = dataPoint.weight;
            } 


            // last_weight_save = last_weight;
            // ertrag += diff;
            // last_weight = dataPoint.weight;              
        
            if (dataPoint.humidity != 0) {
              lastHum = dataPoint.humidity;
            } else {
              dataPoint.humidity = lastHum;
              // dataPoint.change_time new:  {
              //   "id_weight":122321,
              // "id_scale":1029,
              // "temperature":2.72,
              // "humidity":99.9,
              // "pressure":9000,
              // "scale":49.747,
              // "weight":49.747,
              // "scale_val":18330584,
              // "last":49.753,
              // "diff":-0.006000000000000227,
              // "ertrag":1.0989999999999966,
              // "event":0,
              // "temp_scale":-40,
              // "time": "2020-11-12T17:29:02.000Z",
              // "voltage":4.16,
              // "date":"2020-11-12 17:29:02",
              // "voltage_percent":96.71,
              // "signal_time":1605202142000,
              // "time_string":"2020-11-12 17:29:02"}
              // console.log("dataPoint.change_time new: "+dataPoint.time_string);
            }              
            
            return {
              ...dataPoint,
              // humidity: hum,
              // x: moment.utc(dataPoint.change_time).format(timeFormat),
              // y: dataPoint.scale,
              // id_weight: parseInt(dataPoint.id_weight),
              // id_scale: parseInt(dataPoint.id_scale),
              // temperature: lastTemp,
              // humidity: dataPoint.humidity,
              // pressure: dataPoint.pressure,
              // scale: dataPoint.weight,
              // weight: dataPoint.weight,
              // scale_val: dataPoint.scale,
              // last: last_weight_save,
              // diff: diff,
              // ertrag: ertrag,
              // event: parseInt(event),
              // temp_scale: dataPoint.temperature2,
              // time: dataPoint.change_time,
              // voltage: dataPoint.vc,
              // date: moment.utc(dataPoint.change_time).format(timeFormat), //moment(dataPoint.time).valueOf()/1000,
              // voltage_percent: dataPoint.vc_p,
              // time_string: moment.utc(dataPoint.time).local().format('YYYY-MM-DD HH:mm:ss'), //.format('YYYY-MM-DD HH:mm:ss').valueOf(), //.valueOf(),
              // signal_time: moment(dataPoint.time).local().valueOf(), //= milliseconds
              // time_string: moment.utc(dataPoint.change_time).format(timeFormat),
            };
          });
        }

        // console.log("ertragsArray: ", ertragsArray);
        item.ertragsArray = ertragsArray;
        item.ertragsArrayPos = ertragsArrayPos;
        item.ertragsArrayNeg = ertragsArrayNeg;
        item.ertragsArrayDay = ertragsArrayDay;
        item.ertragsArrayPosDay = ertragsArrayPosDay;
        item.ertragsArrayNegDay = ertragsArrayNegDay;

        // // console.log("scaledata: ", item.scaledata[item.scaledata.length-1].signal_time_local);
        // console.timeEnd('datamapping');
        setCalibrationData(item);
        console.log('calibration after setStatScales  '+moment().format('HH:mm:ss.SSS'));
        // for ( let key in res.data ) {
        //   // fetchScales.push(res.data[key]);
        //   fetchScales.push( {
        //     ...res.data[key],
        //     id: key
        //   } );
        // }
        // dispatch(fetchScaleDataSuccess(res.data));
      } )
      .catch( err => {
        console.log("err get calibration: ", err);
        // dispatch(fetchScaleDataFail(err));
      } );

  }, [token, id_scales,scales,  dispatch]);


  const set0 = async () => {
    console.log("selectedData 0: ", selectedData)

    const startTime = moment.utc(selectedData[0]).valueOf();
    const endTime = moment.utc(selectedData[1]).valueOf();

    let regressionData = [];
    const filtered = await calibrationData.scaledata.filter(function (dataPoint) {
      return ((dataPoint.signal_time <= endTime) && (dataPoint.signal_time >= startTime));
    });
    console.log("filtered: ", filtered)

    await filtered.forEach(function (loc_item, index) {
      let newData = [loc_item.temperature, loc_item.scale];
      regressionData.push(newData);
    });      

    console.log("regressionData: ", regressionData)
    const result = regression.linear(regressionData); //[[1, 0], [3, 1], [5, 2]]);
    // console.log("result ", regression.linear([[1, 0], [3, 1], [5, 2]]).predict(7))
    const gradient = result.equation[0];
    const yIntercept = result.equation[1];
    console.log("gradient ", gradient, " yIntercept: ", yIntercept)
    // const y7 = gradient*7 + yIntercept;
    // console.log("y7 ", y7)

    const newFiltered = filtered.map((dataPoint, sc_index) => { 
      return {
        weight_calc: dataPoint.scale - (gradient * dataPoint.temperature + yIntercept) ,
        ...dataPoint,
      }
    });

    setZeroData(newFiltered);
    console.log("newFiltered: ", newFiltered)
    // setSelectedData([]);
  };
  const set1 = () => {
    console.log("selectedData 1: ", selectedData)
    setVal1Data(selectedData);
    setSelectedData([]);
  };
  const set2 = () => {
    console.log("selectedData 2: ", selectedData)
    setVal2Data(selectedData);
    setSelectedData([]);
  };

  const handleBackClick = () => {
    dispatch(actions.setTitleAndMenu("Waagen", const_vars.MENU_SCALES));
    props.history.push('/scales')
  };


  useEffect( () => {    
    if (calibrationData) {

      console.log("calibrationData: ", calibrationData);
      if (calibrationData != undefined) {
        if (calibrationData.scaledata != undefined) {
            recalcValues(scaleRange.start, scaleRange.end);
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calibrationData]);

  const unpack = (rows, key) => {
    if (rows != undefined) {
      return rows.map(function(row) { return row[key]; });
    } else {
      return [];
    }
  }

  const unpackFilter = (rows, key) => {
    if (rows != undefined) {
      return rows.map(function(row) { return row[key]; });
    } else {
      return [];
    }
  }

  let timeout = null;
  let data = [{}];

  if (calibrationData != undefined) {
    console.log('calibration statScales.forEach '+moment().format('HH:mm:ss.SSS'));
    
  
    const weight_trace = {
      type: "scatter",
      // mode: "lines+markers",
      mode: "lines",
      name: 'Wert',
      x: unpack(calibrationData.scaledata, 'time_string'),
      y: unpack(calibrationData.scaledata, 'scale'), //'weight'),
      xaxis: 'x1',
      yaxis: 'y1',
      line: {
        color: weight, //'#17BECF'
        shape: 'hv',
      },
      hovertemplate: '%{y}',
    }

    const temp_trace = {
      type: "scatter", //scattergl
      // mode: "lines+markers",
      mode: "lines",
      name: 'Temperatur',
      x: unpack(calibrationData.scaledata, 'time_string'),
      y: unpack(calibrationData.scaledata, 'temperature'),
      xaxis: 'x1',
      yaxis: 'y2',
      line: {
        color: temperature,
        width: 1.5, 
        shape: 'spline',
        simplify: true,
        smoothing: 0.5,
        dash: 'solid'
      },
      hovertemplate: '%{y:.2f}°C',
      // hovertemplate: '<i>Temperatur</i>: %{y:.2f}°C' +
                      // '<br><b>X</b>: %{x}',
    }  
    
    data.push(weight_trace);
    data.push(temp_trace);
  }

  console.log('calibration after statScales.forEach '+moment().format('HH:mm:ss.SSS'));
  const timeDomain = d3.scaleTime()
    .domain([moment(scaleRange.start).valueOf(), moment(scaleRange.end).valueOf()]);
  let ticks;
  ticks = timeDomain.ticks(
    d3.timeHour.every(24)).map(d => d.getTime());//(timeDomain(d) + 68));
  if (moment(scaleRange.end).valueOf() - moment(scaleRange.start).valueOf() > 1000*60*60*24*14) {
    // ticks = timeDomain.ticks(
    //   d3.timeDay.every(7)).map(d => d.getTime());//(timeDomain(d) + 68));
    //   // console.log("zeitraum > 14 Tage - ticks: ", ticks);
    //mehr als 14 Tage -> nur Wochen anzeigen!
    //TODO - filtern
    let currentIndex = -1;
    ticks = ticks.filter(tick => {
      // if (currentIndex == -1) {
        if (moment(tick).day() == 0) {
          // console.log(moment(tick).day() );
          return true;
        }
        return false;
      // }
    })
  }

  let dayShapes = [];
  let added = false;
  let last_val = 0;

  ticks.forEach(function (item, index) {
    // console.log("tick: ", item, " added: ", added);
    if (!added) {
      last_val = item;
    } else {
      //Jetzt hinzufügen!
      const new_day =  {
        type: 'rect',
        xref: 'x',
        yref: 'paper',
        x0: moment(last_val).format(timeFormat), //'2021-03-07', //scaleRange.start, //'2015-02-04',
        y0: 0,
        x1: moment(item).format(timeFormat), //'2021-03-05', //scaleRange.end,
        y1: 1,
        fillcolor: '#ccc',
        opacity: 0.2,
        line: {
          width: 0
        }
      }
      dayShapes.push(new_day);
    }
    added = !added;
  });

  if (added) {
    //müssen letzten Tag abschließen!
    const new_day =  {
      type: 'rect',
      xref: 'x',
      yref: 'paper',
      x0: moment(last_val).format(timeFormat), //'2021-03-07', //scaleRange.start, //'2015-02-04',
      y0: 0,
      x1: moment(scaleRange.end).format(timeFormat), //'2021-03-05', //scaleRange.end,
      y1: 1,
      fillcolor: '#ccc',
      opacity: 0.2,
      line: {
        width: 0
      }
    }
    dayShapes.push(new_day);
  }

    // {
    //   type: 'rect',
    //   // x-reference is assigned to the x-values
    //   xref: 'x',
    //   // y-reference is assigned to the plot paper [0,1]
    //   yref: 'paper',
    //   x0: moment(scaleRange.start).format(timeFormat), //'2021-03-07', //scaleRange.start, //'2015-02-04',
    //   y0: 0.40,
    //   x1: moment(scaleRange.end).subtract(2, 'day').format(timeFormat), //'2021-03-05', //scaleRange.end,
    //   y1: 1,
    //   fillcolor: '#ccc',
    //   opacity: 0.2,
    //   line: {
    //       width: 0
    //   }
    // },
  



  var layout = {
    shapes: dayShapes,
    showlegend: false,
    legend: {
      font: 4,
      x: 1.1,
      y: 1.2
    },
    dragmode: 'select',
    font: {
      size: 11,
      family: 'Calibri'
    },
    plot_bgcolor: '#ffffff',
    paper_bgcolor: '#ffffff',
    selectdirection	:	'h',

    hovermode: 'x unified' , //'x unified', //compare',
    height: 650,
    // width: 600,
    margin: {
      l: 50,
      r: 50,
      b: 10,
      t: 50,
      pad: 0
    },
    spikedistance: 200,
    hoverdistance: 100,

    xaxis: {
      showgrid: true,
      showline: true, //Line unter und über achse
      autorange: false,
      linecolor: '#666',
      // side	:	'bottom',
      // showspikes: true,
      mirror: 'ticks',
      // spikemode: "across",
      // spikedash: "solid",
      // spikecolor: "#333",
      // spikethickness: 1,
      // autorange: true,
      // rangemode	:	'normal',
      // showticklabels: true,
      // matches: 'x2',
      // ticks: 'outside',
      // ticklen: 4,
      // tickwidth: 1,
      // tickcolor: '#ccc',
      // matches: 'x2',
      anchor: 'y1', //'y3',
      range: [scaleRange.start, scaleRange.end], //'2021-12-31'],
      rangeselector: settings.getRangeButtons(),
      rangeslider: settings.getRangeSlider(0,0),
      type: 'date',
      domain: [0, 1],
    },
    yaxis: {
      showgrid: true,
      showline: true,
      linecolor: '#ccc',
      autorange: false,
      zeroline: true,
      // ticksuffix: 'kg',
      // title: 'Gewicht',
      range: [scaleRange.weightStart, scaleRange.weightEnd],
      // titlefont: {color: weight},
      tickfont: {color: weight},
      // ticks: 'outside',
      // type: 'hv',
      ticklen: 3,
      tickwidth: 1,
      anchor: 'x1',
      domain: [0, 1],
    },
    yaxis2: {
      showgrid: true,
      showline: true,
      linecolor: '#ccc',
      autorange: true,
      domain: [0, 1],
      tickmode: 'auto',
      anchor: 'x1',
      side: 'right',
      // ticksuffix: 'kg',
      ticksuffix: '°C',
      overlaying: 'y',  //sonst wird es nicht im hover gezeigt
      zeroline: true,
      // ticks: 'outside',
      // tickfont: {color: temperature},
      ticklen: 3,
      tickwidth: 1,
      // type: 'linear',
      // title: 'Ertrag',
      // titlefont: {color: ertrag},
      // range: [scaleRange.ertragStart, scaleRange.ertragEnd],
      // tickfont: {color: ertrag},
      tickfont: {color: temperature},
    },
  };


  /*
  * Start und Ende der Y-Achsen berechnen
  */
  const recalcValues = (start, end) => {
    console.log('recalcValues at '+moment().format('HH:mm:ss'));
    let max;
    let min;
    let maxErtrag;
    let minErtrag;

    // console.log("recalcValues statScales: ", statScales);
    const weightOffset = 0.2;
    const ertragOffset = 0.2;

    if ((start == 0) && (end == 1)) {
      //Auto-Range eingestellt! kompletten Verlauf anzeigen!!

      let newStart = 0;
      // statScales.forEach(function (item, index) {
      if (newStart == 0) {
        if (calibrationData.scaledata.length > 0) {
          newStart = calibrationData.scaledata[0].signal_time;
        }
      }
      //   // console.log("statScales forEach");
      //   // value = unpack(item.fio, 'time_string', start, end)

      //   //TODO Zeitmessung für Variante!
      //   // const now = new Date();
      //   // const left = now.getTime()-this.week;
      //   // const right = now.getTime();

      //   // const filtered = item.scaledata.filter(function (dataPoint) {
      //   //   // return ((dataPoint.signal_time <= endTime) && (dataPoint.signal_time >= startTime));
      //   // });
      // });

      start = newStart;
      end = moment().format(timeFormat);
    }
    
    const startTime = moment.utc(start).valueOf();
    const endTime = moment.utc(end).valueOf();


    // statScales.forEach(function (item, index) {
      // console.log("statScales forEach");
      // value = unpack(item.fio, 'time_string', start, end)

      //TODO Zeitmessung für Variante!
      // const now = new Date();
      // const left = now.getTime()-this.week;
      // const right = now.getTime();

      const filtered = calibrationData.scaledata.filter(function (dataPoint) {
        return ((dataPoint.signal_time <= endTime) && (dataPoint.signal_time >= startTime));
      });

      const filteredErtrag = calibrationData.ertragsArray.filter(function (dataPoint) {
        return ((dataPoint.signal_time <= endTime) && (dataPoint.signal_time >= startTime));
      });
      // const shortenedArray = unpack(item.scaledata, 'time_string', start, end);

      // const dataMax = Math.max(...this.props.scale.weights.map((i) => i.weight));
      // const dataMin = Math.min(...this.props.scale.weights.map((i) => i.weight));

      const valueMin = Math.min.apply(null, unpack(filtered, 'scale')) -weightOffset; 
      const valueMax = Math.max.apply(null,  unpack(filtered, 'scale')) +weightOffset;

      const valueMinErtrag = Math.min.apply(null, unpack(filteredErtrag, 'scale')) -ertragOffset;
      const valueMaxErtrag = Math.max.apply(null,  unpack(filteredErtrag, 'scale')) +ertragOffset; 

      if (min !== undefined) {
        min = (valueMin < min) ? valueMin : min;
      } else {
        min = valueMin;
      }
      if (max !== undefined) {
        max = (valueMax > max) ? valueMax : max;
      } else {
        max = valueMax;
      }
       
      if (minErtrag !== undefined) {
        minErtrag = (valueMinErtrag < minErtrag) ? valueMinErtrag : minErtrag;
      } else {
        minErtrag = valueMinErtrag;
      }
      if (maxErtrag !== undefined) {
        maxErtrag = (valueMaxErtrag > maxErtrag) ? valueMaxErtrag : maxErtrag;
      } else {
        maxErtrag = valueMaxErtrag;
      }

      // let topErtrag = Math.round(d3.max(filtered, function (d) {
      //   return d.ertrag;
      // })) + offsetWeight;
      // let bottomErtrag = Math.floor(d3.min(filtered, function (d) {
      //   return d.ertrag;
      // })) - offsetWeight;
      // let topGewicht = Math.round(d3.max(filtered, function (d) {
      //   return d.weight;
      // })) + offsetWeight;
      // let bottomGewicht = Math.floor(d3.min(filtered, function (d) {
      //   return d.weight;
      // })) - offsetWeight;
    // });    

    // d3.select('g.draglayer').selectAll('rect')
    // .style('cursor', 'help');

    console.log("minErtrag: "+minErtrag+" maxErtrag: "+maxErtrag);
      
    setScaleRange({
      start: start, 
      end: end,
      weightStart: min,
      weightEnd: max,
      ertragStart: minErtrag,
      ertragEnd: maxErtrag});
  }


  const handleAnimateClick = () => {
    
      // Plotly.animate('plot-div', {
      //   layout: {
      //     // xaxis: {range: [start, end]},
      //     yaxis: {range: [0, 20]}
      //   }
      // }, {
      //   transition: {
      //     duration: 500,
      //     easing: 'cubic-in-out'
      //   }
      // })
  }


  return (
    <Paper className={classes.customClass}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleBackClick}>
        <IconContainer icon={const_vars.ICON_BACK} />
        Zurück
      </Button>

      {/* <Button
        variant="contained"
        color="primary"
        onClick={handleAnimateClick}>
        <IconContainer icon={const_vars.ICON_BACK} />
        Animate
      </Button> */}

      { calibrationData != undefined &&
        <StatisticScale 
          scale={calibrationData} />
      }
      {/* <Typography>
      Statistic for Scale {id_scales}     
      </Typography> */}

      <Button
        variant="contained"
        color="primary"
        onClick={set0}>
        {/* <IconContainer icon={const_vars.ICON_BACK} /> */}
        0kg-Bereich setzen
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={set1}>
        {/* <IconContainer icon={const_vars.ICON_BACK} /> */}
        Set 4824
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={set2}>
        {/* <IconContainer icon={const_vars.ICON_BACK} /> */}
        Set 4824 + 4858
      </Button>

      <Divider></Divider>
      <Plot
        style={{width: '100%', cursor: pointer}}
        className={classes.customPlot}
        data={data}
        layout={layout}
        graphDiv="graph"
        config={settings.getChartConfig()}
        divId="plot-div"
        ref={plotlyRef} 
        onRelayout={(event) => {
          // console.log("onRelayout Event: ", event);
          if (event["xaxis.range[0]"] != undefined) {
            recalcValues(moment(event["xaxis.range[0]"]).format(timeFormat),
                      moment(event["xaxis.range[1]"]).format(timeFormat));
          } else if (event["xaxis.range"] != undefined) {
            clearTimeout(timeout);
            //timeout weil das so oft hintereinander gefeuert wird
            timeout = setTimeout(function () {
                // console.log('Input Value:', textInput.value);
              // console.log('ongoing '+moment().format('HH:mm:ss'));
              recalcValues(moment(event["xaxis.range"][0]).format(timeFormat),
                          moment(event["xaxis.range"][1]).format(timeFormat));
            }, 500);
            // console.log("ongoing Event: ", event);
          } else if (event["xaxis.autorange"] != undefined) {
            console.log("xaxis.autorange detected");       
            recalcValues(0,1); 
          } else {
            console.log("onRelayout Event not handled - autorange!! ", event);      
          }
        }} //?: (event: Readonly<Plotly.PlotRelayoutEvent>) => void;

        onHover={(event) => {
          // if (dragLayer != undefined) {
          //   dragLayer.style.cursor = "pointer";
          // }
          // console.log("hover: ", event);
        }} //: (event: Readonly<Plotly.PlotMouseEvent>) => void;
        onUnhover={(event) => {
          // console.log("unhover: ", event);
        }} //: (event: Readonly<Plotly.PlotMouseEvent>) => void;
        onSelected={(event) => {
          console.log("onSelected: ", event);
          if (event.range.x != undefined) {

            setSelectedData(event.range.x);
          }
        }} //: (event: Readonly<Plotly.PlotMouseEvent>) => void;
        // onSelecting={(event) => {
        //   console.log("onSelecting: ", event);
        // }} //: (event: Readonly<Plotly.PlotMouseEvent>) => void;
        onDeselect={(event) => {
          console.log("onDeselect: ", event);

        }} //: (event: Readonly<Plotly.PlotMouseEvent>) => void;

        
        />
    </Paper>
        // onRestyle?: (event: Readonly<Plotly.PlotRestyleEvent>) => void;
        // onRedraw?: () => void;
        // onSelected?: (event: Readonly<Plotly.PlotSelectionEvent>) => void;
        // onSelecting?: (event: Readonly<Plotly.PlotSelectionEvent>) => void;
        // onSliderChange?: (event: Readonly<Plotly.SliderChangeEvent>) => void;
        // onSliderEnd?: (event: Readonly<Plotly.SliderEndEvent>) => void;
        // onSliderStart?: (event: Readonly<Plotly.SliderStartEvent>) => void;
        // onTransitioning?: () => void;
        // onTransitionInterrupted?: () => void;
        // useResizeHandler='true'    
        // onAfterExport?: () => void;
        // onAfterPlot?: () => void;
        // onAnimated?: () => void;
        // onAnimatingFrame?: (event: Readonly<Plotly.FrameAnimationEvent>) => void;
        // onAnimationInterrupted?: () => void;
        // onAutoSize?: () => void;
        // onBeforeExport?: () => void;
        // onButtonClicked?: (event: Readonly<Plotly.ButtonClickEvent>) => void;
        // onClick?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
        // onClickAnnotation?: (event: Readonly<Plotly.ClickAnnotationEvent>) => void;
        // onDeselect?: () => void;
        // onDoubleClick?: () => void;
        // onFramework?: () => void;
        // onHover?: (event: Readonly<Plotly.PlotMouseEvent>) => void;
        // onLegendClick?: (event: Readonly<Plotly.LegendClickEvent>) => boolean;
        // onLegendDoubleClick?: (event: Readonly<Plotly.LegendClickEvent>) => boolean;
  );
}


export default (withRouter(Calibration));
