import React, { Component } from 'react';
import styles from './ValueContainer.module.css';
import * as actions from "../../store/actions/main";
import {connect} from "react-redux";
import 'moment-timezone';
import 'moment/locale/de';
import {withRouter} from "react-router-dom";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from "@material-ui/core/es/Tooltip/Tooltip";

class ValueContainer extends Component {

  render() {
    // const date = new Date();
    let color_class = "color_black54";
    let color24 = "color_black54";
    let vorzeichen = "";

    // eslint-disable-next-line
    if (this.props.vorzeichen == true) {
      if (this.props.value > 0) {
        vorzeichen = "+";
      }
    }

    // color_class="dunkel_gelb"
    // eslint-disable-next-line
    if (this.props.value_colors == true) {
      //Farben je nach Wert von this.props.value und props schwelle
      if (this.props.value > this.props.value_color_schwelle) {
        color_class = "petrol";
      } else if (Math.abs(this.props.value) > this.props.value_color_schwelle) {
        color_class = "rot";
      } else {
        //Wert neutral
        color_class = "color_black54";
      }

      if (this.props.max > this.props.value_color_schwelle) {
        color24 = "petrol";
      } else if (Math.abs(this.props.max) > this.props.value_color_schwelle) {
        color24 = "rot";
      } else {
        //Wert neutral
        color24 = "color_black54";
      }
    } else {
      color_class = this.props.color_class;
      color24 = this.props.color_class;
    }


    return (
      <div className={styles.scale}>
        <Grid item xs container direction="column" justify="center">
          <Grid item>
            <Typography variant="body2" align="center" className={ color_class}>{ this.props.title }</Typography>
          </Grid>
          <Grid item>
            <Tooltip title={ this.props.tool_min } placement="bottom">
              <Typography variant="caption" align="center">{this.props.min}</Typography>
            </Tooltip>
          </Grid>
          <Grid item container direction="row" justify="center" alignItems="center">
            {/*<Grid item>*/}
              {/*<IconContainer icon={this.props.icon} />*/}
              {/*/!*<Thermometer className={styles.value_icon} />*!/*/}
              {/*/!*<Typography variant="caption" align="center">icon&nbsp;</Typography>*!/*/}
            {/*</Grid>*/}
            <Grid item>
              <Tooltip title={ this.props.tool_value } placement="bottom">
              <Typography variant="h5" align="center" className={[styles.sensor_value, color_class].join(' ')}>{vorzeichen+Number.parseFloat( this.props.value ).toFixed( 2 )}</Typography>
            </Tooltip>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" align="center">&nbsp;{ this.props.einheit }</Typography>
            </Grid>
          </Grid>
          <Grid item  style={{textAlign: "center"}} > 
            { (this.props.max || this.props.tool_max) &&            
              <Tooltip title={ this.props.tool_max } placement="bottom">
                <Typography variant="caption" align="center" className={ color24 }>{Number.parseFloat(this.props.max ).toFixed( 2 )+" (24h)" }</Typography>
              </Tooltip>
            }
          </Grid>
        </Grid>


      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    selectScale: (scale) => dispatch(actions.setCurrentScale(scale))
  };
};

export default connect(null, mapDispatchToProps) (withRouter(ValueContainer));