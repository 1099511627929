import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Button from "@material-ui/core/es/Button/Button";
import Dialog from "@material-ui/core/es/Dialog/Dialog";
import DialogTitle from "@material-ui/core/es/DialogTitle/DialogTitle";
import TextField from "@material-ui/core/es/TextField/TextField";
import DialogActions from "@material-ui/core/es/DialogActions/DialogActions";
import DialogContent from "@material-ui/core/es/DialogContent/DialogContent";
import FormControl from "@material-ui/core/es/FormControl/FormControl";
import InputLabel from "@material-ui/core/es/InputLabel/InputLabel";
import Select from "@material-ui/core/es/Select/Select";
import MenuItem from "@material-ui/core/es/MenuItem/MenuItem";
import Input from "@material-ui/core/es/Input/Input";
import cssStyles from './Dialogs.module.css';
import FormControlLabel from "@material-ui/core/es/FormControlLabel/FormControlLabel";
import Checkbox from "@material-ui/core/es/Checkbox/Checkbox";
import * as const_vars from "../Static/const_vars";
import Typography from "@material-ui/core/es/Typography/Typography";
import Moment from "react-moment"; // Import css modules stylesheet as styles


function EventDialog(props) {
  // const classes = useStyles();
  const { onClose, selectedEvent, ...other } = props;
  const [event, setEvent] = useState(0);
  const [skipTime, setSkipTime] = useState(true);

  function handleClose() {
    onClose(selectedEvent, false);
  }

  function handleSave() {
    if (event === 0) {
      //TODO - focus select
      return;
    }
    selectedEvent.event = event;
    selectedEvent.skipTime = skipTime;

    console.log("event speichern: ");
    onClose(selectedEvent, true);
  }

  function handleEventChange(event) {
    // onClose(value);
    console.log("value: ", event.target.value);
    setEvent(Number(event.target.value));
  }

  function handleSkipChange(event) {
    // onClose(value);
    console.log("setSkipTime: ", event.target.checked);
    setSkipTime(event.target.checked);
  }


  return (
    <Dialog onClose={handleClose}
            fullWidth={true}
            // maxWidth={maxWidth}
            aria-labelledby="simple-dialog-title" {...other}>
        <DialogTitle id="form-dialog-title">Ereignis am&nbsp;
          <Moment locale="de" utc format="DD.MM.YYYY, h:mm">
            { selectedEvent.time }
          </Moment>
        </DialogTitle>

        <DialogContent>
            {/*{selectedEvent.toString()}*/}

          <Typography
            align="left"
            variant="body2"
            gutterBottom>
            Gewicht davor: <b>{Number.parseFloat( selectedEvent.last ).toFixed( 2 )}</b>kg
          </Typography>

          <Typography
            align="left"
            variant="body2"
            gutterBottom>
            Gewicht bei Ereignis: <b>{Number.parseFloat( selectedEvent.weight ).toFixed( 2 )}</b>kg
          </Typography>

          <FormControl
            fullWidth
            className={cssStyles.formControl}>
            <InputLabel htmlFor="event-simple">Ereignis</InputLabel>
            <Select
              required={true}
              value={event}
              onChange={handleEventChange}
              input={<Input id="event-simple" />} >
              <MenuItem value={const_vars.EVENT_IGNORE}><em>Ignorieren</em></MenuItem>
              <MenuItem value={const_vars.EVENT_CONTROL}>Kontrolle/Durchsicht</MenuItem>
              <MenuItem value={const_vars.EVENT_BODY_ADD}>Zarge aufgesetzt</MenuItem>
              <MenuItem value={const_vars.EVENT_BODY_REMOVE}>Zarge entfernt</MenuItem>
              <MenuItem value={const_vars.EVENT_SWARM}>Schwarm</MenuItem>
              <MenuItem value={const_vars.EVENT_FRAMES_ADD}>Waaben hinzugefügt</MenuItem>
              <MenuItem value={const_vars.EVENT_FRAMES_REMOVE}>Waaben entnommen</MenuItem>
              <MenuItem value={const_vars.EVENT_HONEY}>Honigernte</MenuItem>
              <MenuItem value={const_vars.EVENT_VARROA}>Varroabehandlung</MenuItem>
              <MenuItem value={const_vars.EVENT_ROBBING}>Räuberei</MenuItem>
              <MenuItem value={const_vars.EVENT_WRONG_MEASUREMENT}>Falscher Messwert</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            control={
              <Checkbox
                checked={skipTime}
                onChange={handleSkipChange}
                // value="checkedB"
                color="primary"
              />
            }
            label="Gewichtswerte auslassen"
          />
          <TextField
            autoFocus
            // onChange={this.setTextValue}
            margin="dense"
            multiline
            rowsMax="4"
            id="name"
            label="Bemerkung"
            // value={this.state.textValue}
            // placeholder={this.props.title}
            fullWidth
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Abbrechen
          </Button>
          <Button onClick={handleSave} color="primary">
            Hinzufügen
          </Button>
        </DialogActions>
      </Dialog>
  );
}

EventDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  selectedValue: PropTypes.string,
};

export default EventDialog;
//
// const mapStateToProps = state => {
//   return {
//     title: state.main.title,
//     token: state.auth.token,
//     scale: state.main.currentScale,
//     menu: state.main.menu,
//     helper_element: state.main.helper_element,
//     menu_open: state.main.menu_open
//   };
// };
// // this.props.token,
// const mapDispatchToProps = dispatch => {
//   return {
//     setHelperElement: (event) => dispatch(actions.setHelperElement(event.currentTarget)),
//     deleteHelperElement: () => dispatch(actions.setHelperElement(null)),
//     logout: () => dispatch(logout()),
//     saveWaagenName: (token, name, scale_id) => dispatch(saveScaleName(token, name, scale_id))
//   };
// };
//
// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(EventDialog));
